import { GET_SHOW_PRODUCTIVITY_RATING } from '../../../actions/constants'; 

const initialState = {
  graphData : null,
}

const showProductivity = (state = initialState , action) => {
  switch (action.type) {
    case GET_SHOW_PRODUCTIVITY_RATING : {
      const newState = Object.assign({},state, {
        show_productivity : { ...state.show_productivity , ...action.payload }
      })
      return newState;
    }

    default:
      return state;
  }
};
  
export default showProductivity;
  