import {
  SET_ENGAGEMENT_INSIGHT_LOADING,
  SET_ENGAGEMENT_INSIGHT_DETAILS,
  SET_ENPS_INSIGHT_LOADING,
  SET_ENPS_INSIGHT_DETAILS,
  SET_MANAGER_INSIGHT_LOADING,
  SET_MANAGER_INSIGHT_DETAILS,
  SET_PARTICIPATION_INSIGHT_LOADING,
  SET_PARTICIPATION_INSIGHT_DETAILS,
} from "./SurveyAnalysis.action";

const INIT_STATE = {
  surveyEngagementInsightLoading: false,
  surveyManagerInsightLoading: false,
  surveyENPSInsightLoading: false,
  surveyParticipationInsightLoading: false,
  surveyEngagementInsightDetails: null,
  surveyManagerInsightDetails: null,
  surveyENPSInsightDetails: null,
  surveyParticipationInsightDetails: null,
};

const surveyAnalysisReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  const value = payload?.value;

  switch (type) {
    case SET_ENGAGEMENT_INSIGHT_LOADING: {
      return {
        ...state,
        surveyEngagementInsightLoading: value,
      };
    }

    case SET_MANAGER_INSIGHT_LOADING: {
      return {
        ...state,
        surveyManagerInsightLoading: value,
      };
    }

    case SET_ENPS_INSIGHT_LOADING: {
      return {
        ...state,
        surveyENPSInsightLoading: value,
      };
    }

    case SET_PARTICIPATION_INSIGHT_LOADING: {
      return {
        ...state,
        surveyParticipationInsightLoading: value,
      };
    }

    case SET_ENGAGEMENT_INSIGHT_DETAILS: {
      return {
        ...state,
        surveyEngagementInsightDetails: { ...value },
        surveyEngagementInsightLoading: false,
      };
    }

    case SET_MANAGER_INSIGHT_DETAILS: {
      return {
        ...state,
        surveyManagerInsightDetails: { ...value },
        surveyManagerInsightLoading: false,
      };
    }

    case SET_ENPS_INSIGHT_DETAILS: {
      return {
        ...state,
        surveyENPSInsightDetails: { ...value },
        surveyENPSInsightLoading: false,
      };
    }

    case SET_PARTICIPATION_INSIGHT_DETAILS: {
      return {
        ...state,
        surveyParticipationInsightDetails: { ...value },
        surveyParticipationInsightLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default surveyAnalysisReducer;
