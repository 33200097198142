import { Transforms } from "slate";
import {
  createNumberedBulletListWrapper,
  createBulletListWrapper,
  createTalkingPointListWrapper,
} from "../create";

export const convertToListItemElement = (editor, depth) => {
  transformElementTo(editor, "list-item", depth);
  wrapChildElements(editor, "bulleted-list");
};

export const convertToNumberedListItemElement = (editor, depth) => {
  transformElementTo(editor, "list-item", depth);
  wrapChildElements(editor, "numbered-list");
};

export const convertToTalkingPointElement = (editor, depth) => {
  transformElementTo(editor, "talking-point", depth);
  wrapChildElements(editor, "talking-point-list");
};

export const transformElementTo = (editor, type, depth) => {
  // let element;
  // if (type === 'list-item') {
  //   element = createListItem(depth)
  // } else if (type === 'check-list-item') {
  //   element = createCheckListItem(depth)
  // } else if (type === 'paragraph') {
  //   element = createParagraph(depth)
  // } else if (type === 'heading-one') {
  //   element = createHeadingOne(depth)
  // } else if (type === 'talking-point') {
  //   element = createTalkingPoint(depth)
  // }
  // instead of creating new nodes using the same existing nodes can just overrriding the existing type
  let element = { type: type };
  if (depth !== 0) {
    element = { type: type, depth: depth }; // when deleteing nested items , we need the depth value to set
  }
  Transforms.setNodes(editor, element);
};

// This function wraps a child element like:
// list-item inside (<ul> - bulleted-list) or
// list-item inside (<ol> - numbered-list) or
// talking-point inside (<ul> - talking-point-list)
export const wrapChildElements = (editor, type) => {
  let element;
  if (type === "bulleted-list") {
    element = createBulletListWrapper();
    Transforms.wrapNodes(editor, element, {
      match: (n) => n.type === "list-item",
    });
  } else if (type === "numbered-list") {
    element = createNumberedBulletListWrapper();
    Transforms.wrapNodes(editor, element, {
      match: (n) => n.type === "list-item",
    });
  } else if (type === "talking-point-list") {
    element = createTalkingPointListWrapper(editor.customProps);
    Transforms.wrapNodes(editor, element, {
      match: (n) => n.type === "talking-point",
    });
  }
};

// This function unwraps an element from the parent element wrappers like:
// (<ul> - bulleted-list) or
// (<ul> - talking-point-list) or
// (<ol> - numbered-list)
export const unwrapChildElements = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      n.type === "bulleted-list" ||
      n.type === "numbered-list" ||
      n.type === "talking-point-list",
    split: true,
  });
};

export const unWrapLinkElement = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => n.type === "link",
    split: true,
  });
};
