import { GET_HEATMAP_FILTERS_REQUEST } from '../../actions/constants';

const heatmapFilters = (state = {} , action) => {
    switch (action.type) {
        case GET_HEATMAP_FILTERS_REQUEST: {
            const newState = Object.assign({},state,{
               filters : action.payload
            })
            return newState;
        }
        default:
            return state;
    }
};

export default heatmapFilters;
