import {
  GET_SURVEY_EMPLOYEE_LIST,
  SET_SURVEY_EMPLOYEE_LIST_LOADING,
} from "actions/constants";

const initialState = {
  surveyEmployeeListLoading: false,
  surveyEmployeeList: null,
};

const surveyEmployeeList = (state = initialState, action) => {
  switch (action.type) {
    case SET_SURVEY_EMPLOYEE_LIST_LOADING: {
      return {
        ...state,
        surveyEmployeeListLoading: action.payload,
      };
    }
    case GET_SURVEY_EMPLOYEE_LIST: {
      const newState = Object.assign({}, state, {
        ...action.payload,
        surveyEmployeeListLoading: false,
      });
      return newState;
    }
    default:
      return state;
  }
};

export default surveyEmployeeList;
