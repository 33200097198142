import { ADD_TO_ONE_ON_ONE_LIST_IDS , ADD_TO_ONE_ON_ONE_SHOW_LIST_IDS , ADD_TO_VISITED_ONE_ON_ONES} from '../../../actions/constants'; 

const initialState = {
  users: null,
  showListIds: [],
  pageNo: 1,
  visitedOneOnOnesCount: {}
}

const oneOnOneUsers = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_ONE_ON_ONE_LIST_IDS : {
      const newState = {
        ...state,
        users: {
          ...state.users,
          ...action.payload.oneOnOneListIdsForUsers
        }
      }
      return newState
    }

    case ADD_TO_ONE_ON_ONE_SHOW_LIST_IDS : {
      // const newState = {
      //   ...state , showListIds : [
      //     ...state.showListIds , ...action.payload.showListIds
      //   ] , pageNo : action.payload.pageNo
      // }
      const newState = Object.assign({},state,{
        showListIds : action.payload.showListIds,
        pageNo : action.payload.pageNo
      })
      return newState;
    }

    case ADD_TO_VISITED_ONE_ON_ONES : {
      const { visitedOneOnOneId } = action.payload;
      const newState = Object.assign({},state,{
        visitedOneOnOnesCount : { ...state.visitedOneOnOnesCount , [visitedOneOnOneId]:  state.visitedOneOnOnesCount[visitedOneOnOneId] ? state.visitedOneOnOnesCount[visitedOneOnOneId] + 1 : 1 },
      })
      return newState;
    }

    default:
      return state;
  }
}

export default oneOnOneUsers;