import { GET_SINGLE_MESSAGE_CONTENT, GET_UPDATED_CONVERSATION } from '../../../actions/constants'; 

const singleMessageContent = (state = null , action) => {
  switch (action.type) {
    case GET_SINGLE_MESSAGE_CONTENT : {
      let newState ; 
      newState = Object.assign({},state,{
        totalFeaturedResponseCount : action.payload.singleMessageContent.totalFeaturedResponseCount,
        totalResponseDueCount : action.payload.singleMessageContent.totalResponseDueCount,
        totalResponseAwaitingCount : action.payload.singleMessageContent.totalResponseAwaitingCount,
        totalResponseClosedCount : action.payload.singleMessageContent.totalResponseClosedCount,
        content : action.payload.singleMessageContent.content,
        messages: action.payload.singleMessageContent.messages,
      })
      return newState;
    }
    case GET_UPDATED_CONVERSATION: {
      let newState;
      newState = Object.assign({},state,{
        messages: action.payload.messages,
      })
      return newState;
    }
    default:
      return state;
  }
};
  
export default singleMessageContent;
  