import { GET_SUGGESTED_ITEMS } from '../../actions/constants'; 

const initialState = {
  suggested_items_list : null
}

const suggestedItems = (state = initialState , action) => {
  switch (action.type) {
    case GET_SUGGESTED_ITEMS : {
      const newState = Object.assign({},state,{
        suggested_items_list : action.payload
      })
      return newState;
    }

    default:
      return state;
  }
};
  
export default suggestedItems;
  