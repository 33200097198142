// loading constants

export const SET_PULSE_LOADING = "SET_PULSE_LOADING";

// data individual constants

export const EDIT_PULSE_QUESTION = "EDIT_PULSE_QUESTION";
export const DELETE_PULSE_QUESTION = "DELETE_PULSE_QUESTION";
export const SET_PULSE_STATUS = "SET_PULSE_STATUS";
export const SET_PULSE_SIZE = "SET_PULSE_SIZE";
export const SET_PULSE_START_DATE = "SET_PULSE_START_DATE";
export const SET_PULSE_END_DATE = "SET_PULSE_END_DATE";
export const SET_PULSE_FREQUENCY = "SET_PULSE_FREQUENCY";
export const SET_PULSE_DEFAULT_SIZE = "SET_PULSE_DEFAULT_SIZE";
export const SET_PULSE_ENPS_STATUS = "SET_PULSE_ENPS_STATUS";
export const SET_PULSE_PARTICIPANTS = "SET_PULSE_PARTICIPANTS";
export const SET_PULSE_ENPS_FREQUENCY = "SET_PULSE_ENPS_FREQUENCY";
export const SET_PULSE_WHEN_TO_RUN = "SET_PULSE_WHEN_TO_RUN";

// bulk data constants

export const SET_PULSE_QUESTION_META = "SET_PULSE_QUESTION_META";
export const SET_PULSE_DATA = "SET_PULSE_DATA";

// error constants
export const SET_PULSE_ERROR = "SET_PULSE_ERROR";

// meta constants
export const SET_BACK_LINK = "SET_BACK_LINK";
