import { DEPARTMENT_DRIVER_OVERVIEW_REQUEST } from '../../actions/constants'; 

const initialState = {
  driver_overview : null 
}

const departmentDriverOverview = (state = initialState , action) => {
  switch (action.type) {
    case DEPARTMENT_DRIVER_OVERVIEW_REQUEST : {
      const newState = Object.assign({},state,{
        driver_overview : action.payload
      })
      return newState;
    }
    default:
      return state;
  }
};
  
export default departmentDriverOverview;
  