import { APP_ENV } from 'config/api-config';

export const log = (props) => {
  if (APP_ENV != 'PRODUCTION') {
    let stack = (new Error().stack).split("\n");
    if (stack.length > 2) {
      console.log("--------" + stack[2] + "--------")
      console.log(props);
      console.log("--------" + stack[2] + "--------")
    } else {
      console.log(props);
    }
  }
}