import {
  SET_PULSE_LOADING,
  SET_PULSE_STATUS,
  SET_PULSE_SIZE,
  SET_PULSE_START_DATE,
  SET_PULSE_END_DATE,
  SET_PULSE_FREQUENCY,
  SET_PULSE_DEFAULT_SIZE,
  SET_PULSE_ENPS_STATUS,
  SET_PULSE_DATA,
  SET_PULSE_ERROR,
  SET_PULSE_QUESTION_META,
  EDIT_PULSE_QUESTION,
  DELETE_PULSE_QUESTION,
  SET_PULSE_PARTICIPANTS,
  SET_PULSE_ENPS_FREQUENCY,
  SET_PULSE_WHEN_TO_RUN,
  SET_BACK_LINK,
} from "actions/PulseSurvey/PulseSurvey.action";

/**
 * Notes:
 * pulseLoading - global pulseLoading variable. Usefull to show laoding animation.
 * It should be "true" while an api call and "false" when data is recieved,
 * Hence every action that is setting data should turn it to false
 * There might be issues if two or more api calls need to be triggered.
 *
 * Simillar for pulseError
 *
 * pulseQuestionList is object of objects
 */

const INIT_STATE = {
  pulseSurveyId: null,
  pulseLoading: false,
  pulseQuestionList: {},
  pulseQuestionIdList: [],
  pulseStatus: null,
  pulseSize: null,
  pulseStartDate: null,
  pulseEndDate: null,
  pulseWhenToRun: null,
  pulseFrequency: null,
  pulseEnpsFrequency: null,
  pulseDefaultSize: null,
  pulseEnpsStatus: null,
  pulseParticipants: [],
  pulseError: null,
  isSurveySent: false,
  backLink: "/dashboard/engagement/survey",
};

const pulseSurveyReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  const value = payload?.value;

  switch (type) {
    case SET_BACK_LINK: {
      return {
        ...state,
        backLink: value || INIT_STATE.backLink,
      };
    }
    case SET_PULSE_LOADING: {
      return {
        ...state,
        pulseLoading: value,
      };
    }

    case EDIT_PULSE_QUESTION: {
      const { id } = value;

      return {
        ...state,
        pulseQuestionList: {
          ...state.pulseQuestionList,
          [id]: value,
        },
      };
    }

    case DELETE_PULSE_QUESTION: {
      const tempPulseQuestionList = { ...state.pulseQuestionList };

      if (tempPulseQuestionList[value]) {
        delete tempPulseQuestionList[value];

        return {
          ...state,
          pulseQuestionList: tempPulseQuestionList,
        };
      }

      return {
        ...state,
      };
    }

    case SET_PULSE_STATUS: {
      return {
        ...state,
        pulseStatus: value,
        pulseLoading: false,
        pulseError: INIT_STATE.pulseError,
      };
    }

    case SET_PULSE_SIZE: {
      return {
        ...state,
        pulseSize: value,
        pulseLoading: false,
        pulseError: INIT_STATE.pulseError,
      };
    }

    case SET_PULSE_START_DATE: {
      return {
        ...state,
        pulseStartDate: value,
        pulseLoading: false,
        pulseError: INIT_STATE.pulseError,
      };
    }

    case SET_PULSE_END_DATE: {
      return {
        ...state,
        pulseEndDate: value,
        pulseLoading: false,
        pulseError: INIT_STATE.pulseError,
      };
    }

    case SET_PULSE_FREQUENCY: {
      return {
        ...state,
        pulseFrequency: value,
        pulseLoading: false,
        pulseError: INIT_STATE.pulseError,
      };
    }

    case SET_PULSE_DEFAULT_SIZE: {
      return {
        ...state,
        pulseDefaultSize: value,
        pulseLoading: false,
        pulseError: INIT_STATE.pulseError,
      };
    }

    case SET_PULSE_ENPS_STATUS: {
      return {
        ...state,
        pulseEnpsStatus: value,
        pulseLoading: false,
        pulseError: INIT_STATE.pulseError,
      };
    }

    case SET_PULSE_QUESTION_META: {
      const { pulseSize, pulseQuestionIdList } = value;

      return {
        ...state,
        pulseSize,
        pulseQuestionIdList,
        pulseLoading: false,
        pulseError: INIT_STATE.pulseError,
      };
    }

    case SET_PULSE_ENPS_FREQUENCY: {
      return {
        ...state,
        pulseEnpsFrequency: value,
      };
    }

    case SET_PULSE_WHEN_TO_RUN: {
      return {
        ...state,
        pulseWhenToRun: value,
      };
    }

    case SET_PULSE_DATA: {
      const {
        pulseSurveyId,
        pulseQuestionList,
        pulseQuestionIdList,
        pulseStatus,
        pulseSize,
        pulseStartDate,
        pulseEndDate,
        pulseWhenToRun,
        pulseFrequency,
        pulseEnpsFrequency,
        pulseDefaultSize,
        pulseEnpsStatus,
        pulseParticipants,
        isSurveySent,
      } = value;

      return {
        ...state,
        pulseSurveyId,
        pulseQuestionList,
        pulseQuestionIdList,
        pulseStatus,
        pulseSize,
        pulseStartDate,
        pulseEndDate,
        pulseWhenToRun,
        pulseFrequency,
        pulseEnpsFrequency,
        pulseDefaultSize,
        pulseEnpsStatus,
        pulseParticipants,
        isSurveySent,
        pulseLoading: false,
        pulseError: INIT_STATE.pulseError,
      };
    }

    case SET_PULSE_PARTICIPANTS: {
      return {
        ...state,
        pulseParticipants: value,
      };
    }

    case SET_PULSE_ERROR: {
      return {
        ...state,
        pulseLoading: false,
        pulseError: value,
      };
    }

    default: {
      return state;
    }
  }
};

export default pulseSurveyReducer;
