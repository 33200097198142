import { GET_CHANNEL_LIST } from "actions/constants";

const initialState = {
  channelListObject: {},
  channelListIds: [],
};

const channelList = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHANNEL_LIST: {
      const { channelListObject, channelListIds } = action.payload;
      const newState = Object.assign({}, state, {
        channelListObject,
        channelListIds,
      });
      return newState;
    }
    default:
      return state;
  }
};

export default channelList;
