import { GET_NOTIFICATION_STATUS } from "actions/constants";

const getNotifications = (state = { notification_status: [] }, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_STATUS:
      return {
        ...state,
        notification_status: action.payload,
        loading: action.loading,
      };

    default:
      return state;
  }
};

export default getNotifications;
