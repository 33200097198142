import { CREATE_SURVEY, UPDATE_CREATE_SURVEY } from "actions/constants";

const initialState = {
  survey: {},
  questions: [],
  surveyQuestionList: {},
};

const surveyCreate = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SURVEY: {
      const newState = Object.assign({}, state, {
        survey: action.payload.survey,
        questions: action.payload.questions,
        surveyQuestionList: action.payload.surveyQuestionList,
      });
      return newState;
    }

    case UPDATE_CREATE_SURVEY: {
      return {
        ...state,
        survey: action.payload.survey,
        surveyQuestionList: action.payload.surveyQuestionList,
        questions: action.payload.questions,
      };
    }
    default:
      return state;
  }
};

export default surveyCreate;
