import {
  GET_GOALS_OBJECT_LIST,
  UPDATE_GOALS_OBJECT_LIST,
  UPDATE_GOAL_PROGRESS,
  DELETE_GOALS_OBJECT,
  REMOVE_ALIGNED_PARENT_FROM_GOAL,
  UPDATE_NEW_GOALS_DATA,
  CLEAN_INITIALLY_VISIBLE_GOALS_OBJECT_LIST,
} from "actions/constants";

const initialState = {};

const goalsObjectList = (state = initialState, action) => {
  switch (action.type) {
    case GET_GOALS_OBJECT_LIST: {
      const newState = { ...state };
      Object.keys(action.payload).forEach((goalId) => {
        if (newState[goalId]) {
          newState[goalId] = { ...newState[goalId], ...action.payload[goalId] };
        } else {
          newState[goalId] = action.payload[goalId];
        }
      });

      return {
        ...newState,
      };
    }

    case UPDATE_GOAL_PROGRESS: {
      const { goalProgress, goalId } = action.payload;

      return {
        ...state,
        [goalId]: {
          ...state[goalId],
          goal_progress: {
            ...state[goalId]?.goal_progress,
            ...goalProgress,
          },
        },
      };
    }

    case UPDATE_GOALS_OBJECT_LIST: {
      const { updatedGoalsObjArray } = action.payload;
      const data = updatedGoalsObjArray.reduce((acc, value) => {
        /* if goal id is present then update the goal id value */
        if (state[value.id]) {
          return {
            ...acc,
            [value.id]: { ...state[value.id], ...value },
          };
        }
        return acc;
      }, {});

      const newState = Object.assign({}, state, data);
      return newState;
    }

    case DELETE_GOALS_OBJECT: {
      const { goalId, goalChildrenIds } = action.payload;
      const deleteGoalIds = [goalId, ...goalChildrenIds];
      const newState = Object.keys(state)
        .filter((key) => !deleteGoalIds.includes(key))
        .reduce((obj, key) => {
          obj[key] = state[key];
          return obj;
        }, {});
      return newState;
    }

    case REMOVE_ALIGNED_PARENT_FROM_GOAL: {
      const newState = { ...state };
      Object.keys(action.payload).forEach((goalId) => {
        if (newState[goalId]) {
          const newGoalObject = action.payload[goalId];
          newState[goalId] = newGoalObject;
        }
      });
      return {
        ...newState,
      };
    }

    case UPDATE_NEW_GOALS_DATA: {
      const { newGoalsList, extend } = action.payload;

      const newState = newGoalsList.reduce(
        (acc, g) => ({
          ...acc,
          [g.id]: { ...g, ...extend },
        }),
        state
      );

      return newState;
    }

    case CLEAN_INITIALLY_VISIBLE_GOALS_OBJECT_LIST: {
      let someInitiallyVisibleRemoved = false;
      const newState = { ...state };

      if (Object.keys(state).length) {
        for (const key in newState) {
          if ("initiallyVisible" in newState[key]) {
            someInitiallyVisibleRemoved = true;
            delete newState[key].initiallyVisible;
          }
        }
      }

      return someInitiallyVisibleRemoved ? newState : state;
    }

    default:
      return state;
  }
};

export default goalsObjectList;
