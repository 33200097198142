import {
  SET_IS_FETCHING_PROGRESS_BREAKDOWN,
  SET_OKR_PROGRESS_BREAKDOWN,
  SET_SELECTED_TIMELINE_CYCLE,
  SET_PROGRESS_BREAKDOWN_FETCHED,
} from "actions/constants";

const initialState = {
  selectedTimelineCycle: {},
  progressBreakdown: {},
  isFetchingProgressBreakdown: {
    monthly: [],
    quarterly: [],
  },
};

const okrProgressBreakdown = (state = initialState, action) => {
  switch (action.type) {
    case SET_OKR_PROGRESS_BREAKDOWN: {
      const { timeline, progressBreakdown } = action.payload;
      const newState = {
        ...state,
        progressBreakdown: {
          ...state.progressBreakdown,
          [timeline]: {
            ...state.progressBreakdown[timeline],
            ...progressBreakdown,
          },
        },
      };
      return newState;
    }

    case SET_SELECTED_TIMELINE_CYCLE: {
      const { cycleId, timelineCycle } = action.payload;
      const newState = {
        ...state,
        selectedTimelineCycle: {
          ...state.selectedTimelineCycle,
          [cycleId]: timelineCycle,
        },
      };

      return newState;
    }

    case SET_IS_FETCHING_PROGRESS_BREAKDOWN: {
      const { timelineCycle, goalsList } = action.payload;
      const newState = {
        ...state,
        isFetchingProgressBreakdown: {
          ...state.isFetchingProgressBreakdown,
          [timelineCycle]: [
            ...state.isFetchingProgressBreakdown[timelineCycle],
            ...goalsList,
          ],
        },
      };

      return newState;
    }

    case SET_PROGRESS_BREAKDOWN_FETCHED: {
      const { timelineCycle, goalsList } = action.payload;
      const currentGoalsList = [
        ...(state?.isFetchingProgressBreakdown?.[timelineCycle] || []),
      ];

      const updatedGoalsList = currentGoalsList.filter((goalId) => {
        return !goalsList.includes(goalId);
      });

      const newState = {
        ...state,
        isFetchingProgressBreakdown: {
          ...state.isFetchingProgressBreakdown,
          [timelineCycle]: updatedGoalsList,
        },
      };

      return newState;
    }

    default:
      return state;
  }
};

export default okrProgressBreakdown;
