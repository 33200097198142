import { GET_DEPARTMENT_FILTERS_REQUEST } from '../../actions/constants';

const departmentFilters = (state = {} , action) => {
  switch (action.type) {
    case GET_DEPARTMENT_FILTERS_REQUEST: {
      const newState = Object.assign({},state,{
        filters : action.payload.filters
      })
      return newState;
    }
    default:
      return state;
  }
};

export default departmentFilters;
