import { GET_PROGRESS_TYPE_LIST } from "actions/constants";

const initialState = { arrayOfKeys: [], objectList: {} };

const goalsProgressTypeList = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROGRESS_TYPE_LIST: {
      const newState = Object.assign({}, state, {
        ...action.payload,
      });
      return newState;
    }

    default:
      return state;
  }
};

export default goalsProgressTypeList;
