import {
  SET_ALIGN_LOADING,
  UPDATE_ALIGN_LIST,
  UPDATE_ALIGN_OBJECT,
} from "actions/Goals/AlignGoals/AlignGoals.action";

const INIT_STATE = {
  alignLoading: false,
  alignList: {},
  alignObject: null,
};

const alignGoalReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  const value = payload?.value;

  switch (type) {
    case SET_ALIGN_LOADING: {
      return {
        ...state,
        alignLoading: value,
      };
    }

    case UPDATE_ALIGN_LIST: {
      const { id, list } = value;
      return {
        ...state,
        alignLoading: false,
        alignList: { ...state.alignList, [id]: list },
      };
    }

    case UPDATE_ALIGN_OBJECT: {
      return {
        ...state,
        alignLoading: false,
        alignObject: { ...value },
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default alignGoalReducer;
