import {
  GET_ANALYTICS_CONFIG,
  SET_ANALYTICS_INSIGHTS,
} from "actions/constants";

const initialState = { config: null, insights: null };

const analytics = (state = initialState, action) => {
  switch (action.type) {
    case GET_ANALYTICS_CONFIG: {
      return {
        ...state,
        config: {
          ...state.config,
          [action.payload.config_key]: action.payload.data,
        },
      };
    }

    case SET_ANALYTICS_INSIGHTS: {
      const { data, insightsType } = action.payload.data;
      const insightsTypeData = state.insights?.[action.payload.config_key]?.[
        insightsType
      ]
        ? {
            ...state.insights[action.payload.config_key][insightsType],
            ...data,
          }
        : { ...data };

      return {
        ...state,
        insights: {
          ...state.insights,
          [action.payload.config_key]: {
            ...state.insights?.[action.payload.config_key],
            [insightsType]: { ...insightsTypeData },
          },
        },
      };
    }

    default:
      return state;
  }
};

export default analytics;
