const initialState = {
  stepsStatus: {},
  isLoading: false,
  isCreatingForm: false,
  isAddingReviewee: false,
  isAddingMultipleReviewees: false,
  isRemovingAllReviewees: false,
  isFetchingReviewCycleDetails: false,
  competencyError: [],
  newlyCreatedReviewCycle: {},
  reviewForms: [],
  reviewFormTemplates: {
    system_templates: [],
    user_templates: [],
  },
  reviewFormQuestions: [],
  questionLibrary: {
    system_question_templates: [],
    user_question_templates: [],
  },
  employees: [],
  reviewees: [],
  reviewers: [],
};

const createReviewCycle = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_IS_LOADING": {
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    }
    case "TOGGLE_IS_FETCHING_REVIEW_CYCLE_DETAILS": {
      return {
        ...state,
        isFetchingReviewCycleDetails: !state.isFetchingReviewCycleDetails,
      };
    }

    case "TOGGLE_IS_CREATING_FORM": {
      return {
        ...state,
        isCreatingForm: !state.isCreatingForm,
      };
    }
    case "TOGGLE_IS_ADDING_REVIEWEE": {
      return {
        ...state,
        isAddingReviewee: !state.isAddingReviewee,
      };
    }
    case "TOGGLE_IS_ADDING_MULTIPLE_REVIEWEES": {
      return {
        ...state,
        isAddingMultipleReviewees: !state.isAddingMultipleReviewees,
      };
    }
    case "TOGGLE_IS_REMOVING_REVIEWEE": {
      return {
        ...state,
        isRemovingAllReviewees: !state.isRemovingAllReviewees,
      };
    }
    case "SET_REVIEW_CYCLE": {
      const newState = Object.assign({}, state, {
        newlyCreatedReviewCycle: action.payload,
      });
      return newState;
    }
    case "UPDATE_REVIEW_CYCLE": {
      return {
        ...state,
        newlyCreatedReviewCycle: action.payload,
      };
    }
    case "SET_REVIEW_FORMS": {
      return {
        ...state,
        reviewForms: action.payload,
      };
    }
    case "SET_REVIEW_FORM_TEMPLATES": {
      return {
        ...state,
        reviewFormTemplates: action.payload,
      };
    }

    case "SET_CURRENT_FORM_TEMPLATE": {
      return {
        ...state,
        currentFormTemplate: action.payload,
      };
    }

    case "SET_QUESTION_LIBRARY": {
      return {
        ...state,
        questionLibrary: action.payload,
      };
    }
    case "ADD_REVIEW_FORM": {
      return {
        ...state,
        reviewForms: [...state.reviewForms, action.payload],
      };
    }
    case "UPDATE_REVIEW_FORMS": {
      const updatedReviewForms = state.reviewForms.map((form) => {
        return form.id === action.payload.id ? action.payload : form;
      });
      return {
        ...state,
        reviewForms: updatedReviewForms,
      };
    }
    case "SET_EMPLOYEES": {
      return {
        ...state,
        employees: action.payload,
      };
    }
    case "SET_REVIEWEES": {
      return {
        ...state,
        reviewees: action.payload,
      };
    }
    case "UPDATE_REVIEWEES": {
      return {
        ...state,
        reviewees: [...state.reviewees, ...action.payload],
      };
    }
    case "UPDATE_REVIEWEE": {
      return {
        ...state,
        reviewees: state.reviewees.map((reviewee) => {
          if (reviewee.id === action.payload.id) {
            return action.payload;
          } else {
            return reviewee;
          }
        }),
      };
    }
    case "REMOVE_REVIEWEE": {
      return {
        ...state,
        reviewees: state.reviewees.filter(
          (reviewee) => Number(reviewee.id) !== Number(action.payload)
        ),
      };
    }
    case "UPDATE_SINGLE_REVIEWEE": {
      return {
        ...state,
        reviewees: state.reviewees.map((reviewee) => {
          if (reviewee.id === action.payload.id) {
            return action.payload;
          } else {
            return reviewee;
          }
        }),
      };
    }
    case "SET_REVIEWERS": {
      return {
        ...state,
        reviewers: action.payload,
      };
    }
    case "REMOVE_REVIEWER": {
      const { reviewerType, reviewerId } = action.payload;
      return {
        ...state,
        reviewers: {
          ...state.reviewers,
          [reviewerType]: state.reviewers[reviewerType].filter(
            ({ reviewer }) => Number(reviewer.id) !== Number(reviewerId)
          ),
        },
      };
    }
    case "SET_STEPS_STATUS": {
      return {
        ...state,
        stepsStatus: action.payload,
      };
    }
    case "SET_COMPETENCY_ERROR": {
      return {
        ...state,
        competencyError: action.payload,
      };
    }
    default:
      return state;
  }
};

export default createReviewCycle;
