import {
  INIT_RAW_FILTER,
  ADD_GOALS_FILTER,
  DELETE_GOALS_FILTER,
  UPDATE_IS_REFRESH,
  SET_IS_GOAL_FILTER_LOADING,
  RESET_GOAL_FILTER,
  SET_INSTANT_REFRESH,
  SET_TEMP_EMPLOYEE_DATA,
  SET_FILTER_SEARCH_RESULT,
  SET_FILTER_LOADING,
  SET_EMPTY_TEXT,
  SET_DEPARTMENT_DATA,
  SET_DEPARTMENT_CHILDREN_MAP,
  SET_ROOT_DEPARTMENT_LIST,
  SET_SELECTED_GOAL_DRAWER_ID,
  SET_CHECKIN_DATA,
  SET_SELECTED_OKR_CHECKIN_CELL,
  RESET_SELECTED_OKR_CHECKIN_CELL,
  SET_SHOW_ALL_GOALS_ACTIVITIES,
} from "actions/Goals/GoalsListingFilter/GoalsListingFilter.action";

const DEFAULT_SELECTED_CHECKIN_CELL_OBJ = {
  checkinDate: null,
  milestoneTitle: "",
};

const INITAL_STATE = {
  isRefresh: false,
  instantRefresh: false,
  rawFilter: {},
  filter: {},
  isLoading: false,
  filterLoading: false,
  filterSearchResult: null,
  tempEmployeeData: null,
  emptyText: null,
  departmentData: null,
  departmentChildrenMap: null,
  rootDepartmentList: null,
  selectedGoalDrawerId: null,
  checkinData: null,
  selectedOkrCheckinCell: DEFAULT_SELECTED_CHECKIN_CELL_OBJ,
};

const goalsListingFilterReducer = (state = INITAL_STATE, action) => {
  switch (action.type) {
    case SET_DEPARTMENT_DATA: {
      return {
        ...state,
        departmentData: action.payload,
      };
    }

    case SET_EMPTY_TEXT: {
      return {
        ...state,
        emptyText: action.payload,
      };
    }
    case SET_FILTER_LOADING: {
      return {
        ...state,
        filterLoading: action.payload,
      };
    }

    case SET_FILTER_SEARCH_RESULT: {
      return {
        ...state,
        filterSearchResult: action.payload,
      };
    }

    case SET_TEMP_EMPLOYEE_DATA: {
      return {
        ...state,
        tempEmployeeData: action.payload,
      };
    }
    case SET_IS_GOAL_FILTER_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case INIT_RAW_FILTER: {
      return {
        ...state,
        rawFilter: action.payload,
      };
    }

    case SET_INSTANT_REFRESH: {
      return {
        ...state,
        instantRefresh: action.payload,
      };
    }

    case RESET_GOAL_FILTER: {
      return {
        ...state,
        filter: {},
      };
    }

    case ADD_GOALS_FILTER: {
      const { filterType, filterKey, filterValue } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          [filterType]: state.filter[filterType]
            ? {
                ...state.filter[filterType],
                [filterKey]: filterValue,
              }
            : {
                [filterKey]: filterValue,
              },
        },
      };
    }

    case DELETE_GOALS_FILTER: {
      const { filterType, filterKey } = action.payload;

      const filter = { ...state.filter };
      delete filter[filterType][filterKey];

      if (!Object.keys(filter[filterType]).length) {
        delete filter[filterType];
      }

      return {
        ...state,
        filter,
      };
    }

    case UPDATE_IS_REFRESH: {
      return {
        ...state,
        isRefresh: action.payload,
      };
    }

    case SET_DEPARTMENT_CHILDREN_MAP: {
      return {
        ...state,
        departmentChildrenMap: action.payload,
      };
    }

    case SET_ROOT_DEPARTMENT_LIST: {
      return {
        ...state,
        rootDepartmentList: action.payload,
      };
    }

    case SET_SELECTED_GOAL_DRAWER_ID: {
      return {
        ...state,
        selectedGoalDrawerId: action.payload,
      };
    }

    case SET_SHOW_ALL_GOALS_ACTIVITIES: {
      return {
        ...state,
        showAllGoalsActivities: action.payload,
      };
    }

    case SET_CHECKIN_DATA: {
      return {
        ...state,
        checkinData: action.payload,
      };
    }

    case SET_SELECTED_OKR_CHECKIN_CELL: {
      const { checkinDate, milestoneTitle } = action.payload;
      return {
        ...state,
        selectedOkrCheckinCell: {
          checkinDate,
          milestoneTitle,
        },
      };
    }

    case RESET_SELECTED_OKR_CHECKIN_CELL: {
      return {
        ...state,
        selectedOkrCheckinCell: DEFAULT_SELECTED_CHECKIN_CELL_OBJ,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default goalsListingFilterReducer;
