const initialState = {
  showAppUpdated: false,
}

const appUpdated = (state=initialState, action) => {
  switch(action.type) {
    case "APP_UPDATED": {
      const newState = Object.assign({}, state);
      newState.showAppUpdated = action.payload.showAppUpdated
      return newState;
    }
    default:
      return state
  }
}

export default appUpdated;