import { DEPARTMENT_TRENDS_REQUEST } from '../../actions/constants'; 

const initialState = {
  engagement : null , 
  manager_support : null
}

const departmentTrends = (state = initialState , action) => {
  switch (action.type) {
    case DEPARTMENT_TRENDS_REQUEST : {
      const newState = Object.assign({},state,{
        engagement : action.payload.engagement,
        manager_support : action.payload.manager_support
      })
      return newState;
    }
    default:
      return state;
  }
};
  
export default departmentTrends;
  