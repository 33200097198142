import { ADD_CSV_BOX_CONFIG } from "actions/constants";

const initialState = {
  csv_box_configs: {},
};

const CSVBoxConfig = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CSV_BOX_CONFIG: {
      return {
        ...state,
        csv_box_configs: { ...state.csv_box_configs, ...action.payload },
      };
    }
    default:
      return state;
  }
};

export default CSVBoxConfig;
