import {
  MICROSOFT_TEAMS_INTEGRATION_STATUS,
  REMOVE_MICROSOFT_TEAMS_INTEGRATION,
} from "actions/constants";

const initialState = {
  notification_integrations: {},
};

const NotificationIntegrations = (state = initialState, action) => {
  switch (action.type) {
    case MICROSOFT_TEAMS_INTEGRATION_STATUS: {
      return {
        ...state,
        notification_integrations: { ...action.payload },
      };
    }
    case REMOVE_MICROSOFT_TEAMS_INTEGRATION: {
      return {
        ...state,
        notification_integrations: {},
      };
    }
    default:
      return state;
  }
};

export default NotificationIntegrations;
