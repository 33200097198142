import {
  GET_REVIEW_CYCLE_LIST,
  GET_ARCHIVED_REVIEW_CYCLE_LIST,
  GET_NOTIFICATION_LAUNCHED_FOR_PHASE,
  GET_REVIEWEE_TABLE_DATA,
  GET_CALIBRATION_TABLE_DATA,
  SET_SELECTED_CALIBRATION_QUESTION,
  GET_CALIBRATABLE_QUESTIONS,
  GET_REVIEWS_FOR_ME,
} from "actions/constants";

const initialState = {
  revieweeSearchQuery: "",
  revieweeFilters: "",
};

const ManageReviews = (state = initialState, action) => {
  switch (action.type) {
    case GET_REVIEW_CYCLE_LIST: {
      // reviewList:{
      //   1:[{..},{..},..],
      //   2:[{..},{..},..],
      //   3:[{..},{..},..],
      //   .
      //   .
      //   .
      //   total_pages: int,
      //   total_count: int}
      return {
        ...state,
        review_list: {
          ...state.review_list,
          ...action.payload.data,
        },
      };
    }

    case "RESET_REVIEW_CYCLE_LIST": {
      return {
        ...state,
        review_list: null,
      };
    }

    case GET_ARCHIVED_REVIEW_CYCLE_LIST: {
      return {
        ...state,
        archived_reviews: {
          ...state.archived_reviews,
          ...action.payload.data,
        },
      };
    }

    case "RESET_ARCHIVED_REVIEW_CYCLE_LIST": {
      return {
        ...state,
        archived_reviews: null,
      };
    }

    case GET_REVIEWS_FOR_ME: {
      return {
        ...state,
        reviewsForMe: {
          ...state.reviewsForMe,
          ...action.payload.data,
        },
      };
    }

    case "RESET_REVIEWS_FOR_ME": {
      return {
        ...state,
        reviewsForMe: null,
      };
    }

    case GET_NOTIFICATION_LAUNCHED_FOR_PHASE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case GET_REVIEWEE_TABLE_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case GET_CALIBRATION_TABLE_DATA: {
      return {
        ...state,
        calibration_reviewees: {
          ...state.calibration_reviewees,
          ...action.payload,
        },
      };
    }
    case SET_SELECTED_CALIBRATION_QUESTION: {
      return { ...state, selectedCalibrationQuestion: action.payload };
    }
    case GET_CALIBRATABLE_QUESTIONS: {
      return {
        ...state,
        calibratable_questions: {
          ...action.payload,
        },
      };
    }
    case "SET_SEARCH_QUERY": {
      return {
        ...state,
        revieweeSearchQuery: action.payload,
      };
    }
    case "SET_REVIEWEE_FILTERS": {
      return {
        ...state,
        revieweeFilters: action.payload,
      };
    }
    default:
      return state;
  }
};

export default ManageReviews;
