import competency from "./Competency/reducer";
import employee from "./Employee/reducer";
import { combineReducers } from "redux";

const growthPath = combineReducers({
  competency,
  employee,
});

export default growthPath;
