// constants
import {
  SET_SAVE_AND_CREATE_NEXT_GOAL_ID,
  RESET_SAVE_AND_CREATE_NEXT_GOAL_ID,
} from "actions/constants";

const initialState = {
  goalId: undefined,
};

const goalsSaveAndCreateNext = (state = initialState, action) => {
  switch (action.type) {
    case SET_SAVE_AND_CREATE_NEXT_GOAL_ID: {
      return {
        ...state,
        goalId: action.payload.goalId,
      };
    }

    case RESET_SAVE_AND_CREATE_NEXT_GOAL_ID: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default goalsSaveAndCreateNext;
