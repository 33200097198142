import { SET_OPEN_REPLAY_TRACKER } from "./constants";
import Tracker from "@openreplay/tracker";
import { APP_ENV } from "config/api-config";

// initialise the tracker

const OPEN_REPLAY_TRACKER_KEY = {
  PRODUCTION: "tu9AtYLl1RL5W45BL9Wa",
  DEMO: "25hQ5vyi5L98iykicrWC",
  TESTING: "dPnrhcoReZbHCa5YOJjn",
};

export const initOpenReplayTracker = () => {
  return async (dispatch) => {
    const tracker = new Tracker({
      projectKey:
        OPEN_REPLAY_TRACKER_KEY[APP_ENV] || OPEN_REPLAY_TRACKER_KEY.TESTING,
      ingestPoint: "https://sessionlogs.peoplebox.ai/ingest",
      network: {
        // Enable HTTP payload capture in the network option
        capturePayload: true,
      },
      // Enable this line to use in localhost
      // __DISABLE_SECURE_MODE: true,
      flags: {
        onFlagsLoad: (flags) => {
          // handle active flags
        },
      },
    });
    dispatch({
      type: SET_OPEN_REPLAY_TRACKER,
      payload: tracker,
    });
  };
};

export const startOpenReplayTracker = () => {
  return async (dispatch, getState) => {
    const openReplayTracker = getState()?.trackers?.openReplay;
    if (openReplayTracker) {
      await openReplayTracker.start();
    }
  };
};

export const captureOpenReplayEvent = (eventName, eventData) => {
  return async (dispatch, getState) => {
    const openReplayTracker = getState()?.trackers?.openReplay;
    if (openReplayTracker) {
      openReplayTracker.event(eventName, eventData);
    }
  };
};

export const setOpenReplayUserId = (userIdOrEmail) => {
  return async (dispatch, getState) => {
    const openReplayTracker = getState()?.trackers?.openReplay;
    if (openReplayTracker) {
      openReplayTracker.setUserID(userIdOrEmail);
    }
  };
};

export const setOpenReplayMetadata = ({ metaDataType, metaDataValue }) => {
  return async (dispatch, getState) => {
    const openReplayTracker = getState()?.trackers?.openReplay;
    if (openReplayTracker) {
      openReplayTracker.setMetadata(`${metaDataType}`, metaDataValue);
    }
  };
};
