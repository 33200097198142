import { CURRENT_OOO } from '../../../actions/constants'; 

const initialState = {
  current_ooo : null
}

const currentOneOnOne = (state = initialState , action) => {
  switch (action.type) {
    case CURRENT_OOO : {
      const newState = Object.assign({},state,{
        current_ooo : action.payload
      })
      return newState;
    }
    default:
      return state;
  }
};
  
export default currentOneOnOne;
  