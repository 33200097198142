import { GET_MY_FEEDBACK_LIST , GET_TEAM_FEEDBACK_LIST} from '../../actions/constants'; 

const initialState = {
  my_current_feedback : null ,
  my_past_feedback : null ,
  team_current_feedback : null,
  team_past_feedback : null
}

const feedbackList = (state = initialState , action) => {
  switch (action.type) {
    case GET_MY_FEEDBACK_LIST : {
      const newState = Object.assign({},state,{
        my_current_feedback : action.payload.my_current_feedback,
        my_past_feedback : action.payload.my_past_feedback
      })
      return newState;
    }

    case GET_TEAM_FEEDBACK_LIST : {
      const newState = Object.assign({},state,{
        team_current_feedback : action.payload.team_current_feedback,
        team_past_feedback : action.payload.team_past_feedback
      })
      console.log("inisde reducer " , newState)
      return newState;
    }


    default:
      return state;
  }
};
  
export default feedbackList;
  