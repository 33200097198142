import { HEATMAP_OVERVIEW_REQUEST } from '../../actions/constants';

const heatmapOverview = (state = {} , action) => {
    switch (action.type) {
        case HEATMAP_OVERVIEW_REQUEST : {
            const newState = Object.assign({},state,{
                heatmap_overview : action.payload
            })
            return newState;
        }
        default:
            return state;
    }
};

export default heatmapOverview;
