import { SET_MILESTONE_CYCLES } from "actions/constants";

const initialState = {};

export const milestoneCycles = (state = initialState, action) => {
  switch (action.type) {
    case SET_MILESTONE_CYCLES: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
};
