import { transformElementTo, unwrapChildElements } from "../update";
import { Transforms } from "slate";

// wrap list items inside ul and ol

export const handleDeletionAtFirstLine = (element, editor) => {
  const { depth } = element;
  transformElementTo(editor, "paragraph", depth);
  unwrapChildElements(editor);
  // if (type !== 'talking-point' && type !== 'list-item' ) {
  //   // When the user is deleting the elements from the first line which are not of type list-item,
  //   // by default we need to show a talking point list item element

  //   //convertToListItemElement(editor, depth)
  //   convertToTalkingPointElement(editor, depth)
  //   // transformElementTo(editor, 'paragraph', depth);
  //   // unwrapChildElements(editor);

  // } else {
  //   // If the only element in the document at the first line is list-item and
  //   // if user tries to delete it then tranform it to paragraph

  //   transformElementTo(editor, 'paragraph', depth);
  //   unwrapChildElements(editor);
  // }
};

export const deleteEmptyElement = (editor, elementWithPath) => {
  // const [element, path] = elementWithPath;
  // const { selection } = editor;
  // const { anchor } = selection;
  // const start = Editor.start(editor, path);
  // const range = { anchor, focus: start };
  // Transforms.select(editor, range);
  // Transforms.delete(editor);
  unwrapChildElements(editor);
  transformElementTo(editor, "paragraph", "0"); // arguments are 'editor, element-type, depth'
  // unwrapChildElements(editor);
};

export const removeLink = (editor, opts = {}) => {
  Transforms.unwrapNodes(editor, {
    ...opts,
    match: (n) => n.type === "link",
  });
};
