/* Deprecated, used for coffee connect */
import React from "react";
import { H1, Text1 } from "DesignSystem/Typography";
import { Card } from "DesignSystem/Card";
import { updateCalendarAccess } from "actions/CalenderItems/UpdateCalendarAcess";

import peopleBoxLogo from "resources/images/peoplebox-full-logo.png";
import loaderGif from "resources/images/loader.gif";

import "./index.scss";

const SlackIntegrationCalendar = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [text, setText] = React.useState({ title: "", description: "" });

  React.useEffect(() => {
    let query;
    if (window.location.hash.indexOf("#") === 0) {
      query = new URLSearchParams(window.location.hash.substr(1));
    } else {
      query = new URLSearchParams(props.location.search);
    }
    const { code, state } = Object.fromEntries(query);

    const authData = {
      code,
      state,
      user: { userId: 100 },
    };
    code &&
      updateCalendarAccess(authData)()
        .then((res) => {
          setLoading(false);
          setText({
            title: "success",
            description:
              "You can now return to slack to schedule a meeting with your calender",
          });
        })
        .catch((res) => {
          setLoading(false);
          setText({
            title: "error",
            description: "There seems to an error. Please try again.",
          });
        });
  }, []);

  return (
    <div className="slack-google-microsoft-calendar">
      <img src={peopleBoxLogo} />

      {loading ? (
        <img
          className="loading"
          height="150"
          width="150"
          src={loaderGif}
          alt="loader"
        />
      ) : (
        text.title && (
          <Card className="card">
            <H1 style={{ textTransform: "capitalize" }}>{text.title} !</H1>
            <Text1>{text.description} </Text1>
          </Card>
        )
      )}
    </div>
  );
};

export default SlackIntegrationCalendar;
