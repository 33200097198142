import { GET_ONE_ON_ONE_REASONS } from '../../../actions/constants'; 

const initialState = {
  one_on_one_reasons_list : null,
}

const oneOnOneReasons = (state = initialState , action) => {
  switch (action.type) {
    
    case GET_ONE_ON_ONE_REASONS : {
      const newState = Object.assign({},state,{
        one_on_one_reasons_list : action.payload
      })
      return newState;
    }

    default:
      return state;
    }
};
  
export default oneOnOneReasons;
  