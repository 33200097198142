import {
  GET_EMPLOYEES_LIST_SUCCESS,
  UPDATE_EMPLOYEE_DETAILS,
  ADD_NEW_EMPLOYEES,
  INACTIVATE_EMPLOYEE,
  UPDATE_BILLING_STATUS,
  SET_IDP_TRACK_POSITIONS,
} from "actions/constants";

const initialState = {
  employeeListObject: null,
  employeeListIdsArray: null,
  idpTrackPositions: {},
};

const employeeList = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEES_LIST_SUCCESS: {
      const newState = Object.assign({}, state, {
        ...action.payload,
      });
      return newState;
    }
    case UPDATE_EMPLOYEE_DETAILS: {
      const newState = Object.assign({}, state, {
        employeeListObject: { ...state.employeeListObject, ...action.payload },
      });
      return newState;
    }
    case ADD_NEW_EMPLOYEES: {
      const newState = Object.assign({}, state, {
        employeeListObject: {
          ...action.payload.employeeListObject,
          ...state.employeeListObject,
        },
        employeeListIdsArray: [
          ...action.payload.employeeListIdsArray,
          ...state.employeeListIdsArray,
        ],
      });
      return newState;
    }
    case INACTIVATE_EMPLOYEE: {
      const { employeeData } = action.payload;
      const empObject = Object.assign({}, state.employeeListObject);
      empObject[employeeData.id] = employeeData;
      const newState = Object.assign({}, state, {
        employeeListObject: empObject,
      });
      return newState;
    }
    case UPDATE_BILLING_STATUS: {
      const { id, billingStatus } = action.payload;
      const empObject = Object.assign({}, state.employeeListObject);
      empObject[id] = { ...empObject[id], billing_status: billingStatus };
      const newState = Object.assign({}, state, {
        employeeListObject: empObject,
      });
      return newState;
    }
    case SET_IDP_TRACK_POSITIONS: {
      return {
        ...state,
        idpTrackPositions: {
          ...state.idpTrackPositions,
          [action.payload.id]: action.payload.data,
        },
      };
    }
    default:
      return state;
  }
};

export default employeeList;
