import { GET_SUBSCRIPTION, GET_BILLING_PLANS } from "../../actions/constants";
import { APP_ENV } from "config/api-config";

const initialState = {
  plansList: [],
  cbInstance: window.Chargebee.init({
    site: APP_ENV === "PRODUCTION" ? "peoplebox" : "peoplebox-test",
  }),
  subscription: {
    expires_at: null,
    max_seats: 0,
    active_seats: 0,
    days_left: -1,
    status: null,
    chargebee_subscription_id: null,
    plan_name: null,
    plan_code: null,
    plan_price: 0,
    billing_cycle: null,
  },
};

const billing = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION: {
      let { days_left, starts_at } = action.payload.subscription;

      days_left = days_left >= 0 ? days_left : days_left * -1;

      starts_at = new Date(starts_at)
        .toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
        .replace(/ /g, " ");

      const plan_name = action.payload.subscription.plan.name;
      const plan_code = action.payload.subscription.plan.code;
      const plan_price = parseInt(action.payload.subscription.plan.price);

      const newState = {
        ...state,
        subscription: {
          ...action.payload.subscription,
          days_left,
          starts_at,
          plan_name,
          plan_code,
          plan_price,
        },
      };

      return newState;
    }

    case GET_BILLING_PLANS: {
      return { ...state, plansList: action.payload };
    }

    default:
      return state;
  }
};

export default billing;
