import {
  GET_CC_STATS,
  GET_CC_ACTIVITIES,
  SET_ACTIVE_STATS_DATE,
  UPDATE_CC_STATS,
  SET_SHOW_LOADER,
} from "actions/constants";

const initialState = {
  statsMetrics: null,
  activities: null,
  activeStartDate: null,
  showLoader: false,
};

const stats = (state = initialState, action) => {
  switch (action.type) {
    case GET_CC_STATS:
      return {
        ...state,
        statsMetrics: {
          ...state.statsMetrics,
          ...action.payload,
        },
      };

    case GET_CC_ACTIVITIES:
      return {
        ...state,
        activities: {
          ...state.activities,
          ...action.payload,
        },
      };

    case SET_ACTIVE_STATS_DATE:
      return {
        ...state,
        activeStartDate: action.payload,
      };
    case UPDATE_CC_STATS:
      return {
        ...state,
        statsMetrics: {
          ...state.statsMetrics,
          [state.activeStartDate]: {
            ...state.statsMetrics[state.activeStartDate],
            ...action.payload,
          },
        },
      };

    case SET_SHOW_LOADER: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export default stats;
