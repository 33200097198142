import React from "react";
import loaderGif from "../../resources/images/Ripple.gif";

const fullArea = {
  height: "100vh",
  position: "relative",
  backgroundColor: "#fff",
};

const alignMiddle = {
  margin: "auto",
  position: "absolute",
  left: "0px",
  right: "0px",
  bottom: "0px",
  top: "0px",
};

export const Loader = ({
  style,
  imgStyle,
  containerClassName,
  imgClassName,
}) => {
  return (
    <div style={{ ...fullArea, ...style }} className={containerClassName}>
      <img
        style={{ ...alignMiddle, ...imgStyle }}
        src={loaderGif}
        alt="loader"
        className={imgClassName}
      />
    </div>
  );
};
