import { useEffect, useState } from "react";
import { ChatwootAPI } from "Tracking";

const Chatwoot = (props) => {
  const [isSDKReady, setISSDKReady] = useState(false);
  const { setUser, setCustomAttributes } = ChatwootAPI();

  const {
    employeeName,
    email,
    companyName,
    userId,
    employee_details,
    chatwootHash,
    ...rest
  } = props;
  const { phone_number, profile_picture_url } = employee_details || {};

  const handleSDKStateUpdate = () => {
    setISSDKReady(true);
  };

  useEffect(() => {
    window.addEventListener("chatwoot:ready", handleSDKStateUpdate);
    return () => {
      window.removeEventListener("chatwoot:ready", handleSDKStateUpdate);
    };
  }, []);

  useEffect(() => {
    if (!isSDKReady || !userId) {
      return;
    }

    setUser(userId, {
      name: employeeName,
      avatar_url: profile_picture_url,
      email: email,
      identifier_hash: chatwootHash,
      phone_number: phone_number,
      company_name: companyName,
    });
    setCustomAttributes({ ...rest });
  }, [userId, isSDKReady, chatwootHash]);

  return null;
};

export default Chatwoot;
