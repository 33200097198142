import { API_ROOT } from '../../../config/api-config';
import axios from 'axios';
import { GET_OOO_LIST_POLLING_DATA , STOP_POLLING_ONE_ON_ONE} from '../../constants';
import { getOneOnOneList } from '../OneOnOneList';

const headers = () => {
  return {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('dashboard_auth_token')}`
    },
  };
};

let oneOneOnePollingTimerInterval = null;
let oneOneOnePollingTimerTimeout = null;

export const oneOneOneListPolling = () => {
  return  ( dispatch , getState) => {
    oneOneOnePollingTimerInterval = setInterval(() => {
      dispatch(oneOneOneListPollingApi())
    },2000) 

    oneOneOnePollingTimerTimeout = setTimeout(() => {  //clear interval for polling after 20 seconds
      clearInterval(oneOneOnePollingTimerInterval) 
      //dispatching this action to show zero state screen to manually create 1:1 if not getting any data till 25secs.
      dispatch(addOneOnOneListPagePollingSuccess({
        keep_polling : false,
        create_one_on_one : true,
        timer_cleared : true
      }))
    },25000)
  }
}

export const oneOneOneListPollingApi = () => {
  return  ( dispatch , getState) => {
    const requestUrl = 'one_on_one_page_polling';
    const { accountId ,  employee_details } =  getState().user;
    const { id } = employee_details;
    axios.get(`${API_ROOT}/accounts/${accountId}/managers/${id}/${requestUrl}`, headers())
    .then((response) => {
      let resp_data = response.data;
      resp_data.timer_cleared = false;
      dispatch(addOneOnOneListPagePollingSuccess(resp_data))
      return response
    }).then((response) => {
        if(!response.data.keep_polling){ //if keep_polling is false stop the timers and interval to stop hitting api
          clearInterval(oneOneOnePollingTimerInterval)
          clearTimeout(oneOneOnePollingTimerTimeout)
          dispatch(addOneOnOneListPagePollingSuccess({timer_cleared: true}))
        }
        if(!response.data.keep_polling && response.data.upcoming_api){ //if upcoming api is true then get the upcoming list of 1 on 1's
          dispatch(getOneOnOneList("upcoming"));
        }
    }).catch((error) => {
      clearInterval(oneOneOnePollingTimerInterval)
      clearTimeout(oneOneOnePollingTimerTimeout)
    })
  }
}

//this function is needed in case of component unmount , if timer is not cleared
export const stopOneOnOnePollingTimers = () => {  
  clearInterval(oneOneOnePollingTimerInterval)
  clearTimeout(oneOneOnePollingTimerTimeout)
  return(
    {
      type : STOP_POLLING_ONE_ON_ONE,
      payload : {
        timer_cleared : true
      }
    }
  )
}

const addOneOnOneListPagePollingSuccess = (response) => {
  return {
    type: GET_OOO_LIST_POLLING_DATA,
    payload: response
  }
}