import { UPDATE_ACTION_ITEMS_ON_MOUNT, UPDATE_ACTION_ITEMS_ON_VIEW_MORE, UPDATE_ACTION_ITEM, CREATE_ACTION_ITEM, REQUEST_INITIATED, COMPLETED_ACTION_ITEMS_COUNT, UPDATE_VIEW_MORE } from '../../actions/constants';

const initialState = {
  actionItems : null,
  actionItemIds : [],
  isFetching: true,
  requestInitiated: false,
  viewMore: {suggested: {showMoreContent: true, page: 1}, completed: {showMoreContent: true, page: 1}},
  completedCount: 0
}

const actionItemsList = (state = initialState, action) => {
  switch(action.type) {
    case CREATE_ACTION_ITEM: {
      return {
        ...state,
        actionItems: {
          ...state.actionItems,
          [action.payload.actionItem.id]: {...action.payload.actionItem}
        },
        actionItemIds: [
          ...action.payload.actionItemIds,
          ...state.actionItemIds
        ],
        requestInitiated: false
      }
    }

    case UPDATE_ACTION_ITEMS_ON_MOUNT: {
      return {
        ...state,
        actionItems: {
          ...state.actionItems,
          ...action.payload.actionItems
        },
        actionItemIds: [
          ...action.payload.actionItemIds,
          ...state.actionItemIds,
        ],
        isFetching: false,
      }
    }

    case UPDATE_ACTION_ITEMS_ON_VIEW_MORE: {
      return {
        ...state,
        actionItems: {
          ...state.actionItems,
          ...action.payload.actionItems
        },
        actionItemIds: [
          ...state.actionItemIds,
          ...action.payload.actionItemIds
        ],
        isFetching: false,
      }
    }

    case UPDATE_ACTION_ITEM: {
      return {
        ...state,
        actionItems: {
          ...state.actionItems,
          [action.payload.actionItem.id]: {
            ...action.payload.actionItem
          }
        }
      }
    }

    case REQUEST_INITIATED: {
      return {
        ...state,
        requestInitiated: action.payload.requestInitiated
      }
    }

    case COMPLETED_ACTION_ITEMS_COUNT: {
      return {
        ...state,
        completedCount: action.payload.completedCount
      }
    }

    case UPDATE_VIEW_MORE: {
      return {
        ...state,
        viewMore: action.payload.viewMore
      }
    }

    default:
      return state;
  }
}

export default actionItemsList;