import { GET_SURVEY_PARTICIPANTS } from "actions/constants";

const initialState = {
  Engagement: {
    surveyParticipants: [],
    totalParticipants: 0,
  },
};

const SurveyParticipation = (state = initialState, action) => {
  switch (action.type) {
    case GET_SURVEY_PARTICIPANTS: {
      const newState = {
        ...state,
        Engagement: {
          surveyParticipants: action.payload.participants,
          totalParticipants: action.payload.total_participants,
        },
      };
      return newState;
    }
    default:
      return state;
  }
};

export default SurveyParticipation;
