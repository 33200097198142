import {
  UPDATE_TASK_LIST,
  SET_TASK_LIST_LOADING,
  SET_TASK_ITEM_LOADING,
  SET_TASK_ITEM_STATUS,
} from "./GoalsShowPage.action";

/* ***Data sample***
- projectTaskList 
{ 
    [projectId] : { 
        [taskId1]: {...taskDetail1}, 
        [taskId1]: {...taskDetail1}
     }
} 

- taskListLoading
false/true

-taskItemLoading
[taskId1, taskId2, taskId3]
*/

const INIT_STATE = {
  projectTaskList: {},
  taskListLoading: false,
  taskItemLoading: [],
};

const goalsShowPageStore = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_TASK_LIST: {
      const { goalId, taskList } = payload;
      const { projectTaskList } = state;
      return {
        ...state,
        projectTaskList: {
          ...projectTaskList,
          [goalId]: taskList,
        },
      };
    }

    case SET_TASK_LIST_LOADING: {
      const { taskListLoading } = payload;
      return {
        ...state,
        taskListLoading,
      };
    }

    case SET_TASK_ITEM_LOADING: {
      const { taskId, status } = payload;
      const { taskItemLoading } = state;

      return {
        ...state,
        taskItemLoading: status
          ? [...taskItemLoading, taskId]
          : taskItemLoading.filter((taskItemId) => taskItemId !== taskId),
      };
    }

    case SET_TASK_ITEM_STATUS: {
      const { projectId, taskId, completed } = payload;
      const { projectTaskList } = state;
      const taskItem = projectTaskList?.[projectId]?.[taskId];

      taskItem.completed = completed;

      return {
        ...state,
        projectTaskList: {
          ...projectTaskList,
          [projectId]: {
            ...projectTaskList[projectId],
            [taskId]: { ...taskItem },
          },
        },
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default goalsShowPageStore;
