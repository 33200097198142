import React from "react";
import Bowser from "bowser";

import LoadableErrorMessage from "../LoadableErrorMessage/LoadableErrorMessage";

import Sentry from "config/sentryConfig";

// Old Redux stuff
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Open Replay Tracking
import { captureOpenReplayEvent } from "actions/tracker";
import { WebService } from "services/web/webService";

import { APP_ENV } from "config/api-config";

class LoadableErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false, error: null };
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.error !== this.state.error &&
      this.state.error &&
      this.state.error.message
    ) {
      const { getMetaData } = this.props;

      if (getMetaData && (APP_ENV === "PRODUCTION" || APP_ENV === "DEMO")) {
        const metaData = getMetaData();

        const deviceInfo = Bowser.parse(window.navigator.userAgent);

        const browserString = `${deviceInfo?.browser?.name}/${deviceInfo?.browser?.version} ${deviceInfo?.os?.name}/${deviceInfo?.os?.version}`;

        const blockData = {
          blocks: [
            {
              type: "header",
              text: {
                type: "plain_text",
                text: `:no_entry_sign: App has crashed for ${metaData.name}`,
                emoji: true,
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `\`\`\`${this.state.error.message}\n\`\`\``,
              },
            },
            {
              type: "context",
              elements: [
                {
                  type: "mrkdwn",
                  text: `Account ID: *${metaData.accountId}* Time: *${metaData.time}*`,
                },
              ],
            },
            {
              type: "context",
              elements: [
                {
                  type: "mrkdwn",
                  text: `Platform: *${browserString}*`,
                },
              ],
            },
            {
              type: "context",
              elements: [
                {
                  type: "mrkdwn",
                  text: `Email: ${metaData.email}`,
                },
              ],
            },
            {
              type: "context",
              elements: [
                {
                  type: "mrkdwn",
                  text: `URL: ${metaData.url}`,
                },
              ],
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `\`\`\`${this.state.error?.stack}\n\`\`\``,
              },
            },
            {
              type: "context",
              elements: [
                {
                  type: "mrkdwn",
                  text: `<@U022YHPHPDJ>`,
                },
              ],
            },
          ],
        };

        WebService({
          url: `/send_slack_notification`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            channel: "#dashboard-crash-support",
            icon: ":building_construction:",
            name: "Dashboard Bot",
            attachment: JSON.stringify(blockData),
          },
        }).catch((error) =>
          console.error("Error sending Slack notification:", error)
        );

        console.error({
          message: "Error",
          Sentry,
        });

        Sentry.captureException(this.state.error);
      }
    }
  }

  static getDerivedStateFromError(error) {
    if (!error.message.includes("chunk")) {
      return {
        hasError: true,
        error,
      };
    }

    return {
      hasError: false,
      error: null,
    };
  }

  render() {
    return this.state.hasError
      ? this.props.fallback ?? <LoadableErrorMessage />
      : this.props.children;
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      captureOpenReplayEvent,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadableErrorBoundary);
