import {
  GET_OOO_PAST_LIST,
  GET_OOO_UPCOMING_LIST,
  ADD_TO_ONE_ON_ONE_LIST_DETAILS,
  GET_OOO_TODAY_LIST,
  GET_OOO_TOMORROW_LIST,
  GET_OOO_THIS_WEEK_UPCOMING_LIST,
  GET_OOO_NEXT_WEEK_UPCOMING_LIST,
} from "../../constants";
import { API_ROOT } from "../../../config/api-config";
import keyBy from "lodash/keyBy";
import axios from "axios";
import { checksBeforeOnboardingUpdate } from "../../Onboarding/UpdateOnboardingItems";
import { ONE_ON_ONE_CREATED } from "../../constants/onboardingConfig";

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("dashboard_auth_token")}`,
    },
  };
};

export const getOneOnOneList = (status) => {
  return (dispatch, getState) => {
    const requestUrl = "one_on_ones";
    const { accountId, employee_details } = getState().user;
    const { id } = employee_details;
    axios
      .get(
        `${API_ROOT}/accounts/${accountId}/employees/${id}/${requestUrl}.json?status=${status}`,
        headers()
      )
      .then((response) => {
        let filtered_one_on_ones = [];
        const {
          ooo_today_list,
          ooo_tomorrow_list,
          ooo_this_week_upcoming_list,
          ooo_next_week_upcoming_list,
          ooo_past_list,
        } = response.data;
        filtered_one_on_ones = filtered_one_on_ones.concat(
          ooo_today_list,
          ooo_tomorrow_list,
          ooo_this_week_upcoming_list,
          ooo_next_week_upcoming_list,
          ooo_past_list
        );
        dispatch(addOneOnOneListDetailsSuccess(filtered_one_on_ones));
        return response;
      })
      .then((response) => {
        if (status === "past") {
          dispatch(getOneOnOnePastListSuccess(response.data));
        } else if (status === "upcoming") {
          dispatch(getOneOnOneUpcomingListSuccess(response.data));
          // const { ooo_past_list } = getState().oneOnOneList;
          // if (ooo_past_list === null) {
          //   dispatch(getOneOnOnePastListSuccess(response.data));
          // }
        }
        return response;
      })
      .then((response) => {
        if (
          response.data.ooo_today_list.length ||
          response.data.ooo_tomorrow_list.length ||
          response.data.ooo_this_week_upcoming_list.length ||
          response.data.ooo_next_week_upcoming_list.length
        ) {
          dispatch(checksBeforeOnboardingUpdate(ONE_ON_ONE_CREATED));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const addOneOnOneListDetailsSuccess = (filtered_one_ones) => {
  const oneOnOneObjectOfObjects = keyBy(filtered_one_ones, (x) => x.id);
  return {
    type: ADD_TO_ONE_ON_ONE_LIST_DETAILS,
    payload: {
      ooo_list_details: oneOnOneObjectOfObjects,
    },
  };
};

export const getOneOnOnePastListSuccess = (response) => {
  return {
    type: GET_OOO_PAST_LIST,
    payload: {
      loading: true,
      ooo_past_list: response.ooo_past_list,
    },
  };
};

export const getOneOnOneUpcomingListSuccess = (response) => {
  return {
    type: GET_OOO_UPCOMING_LIST,
    payload: {
      ooo_today_list: response.ooo_today_list,
      ooo_tomorrow_list: response.ooo_tomorrow_list,
      ooo_this_week_upcoming_list: response.ooo_this_week_upcoming_list,
      ooo_next_week_upcoming_list: response.ooo_next_week_upcoming_list,
      ooo_past_list: response.ooo_past_list,
    },
  };
};

export const getOneOnOneTodayListSuccess = (response) => {
  return {
    type: GET_OOO_TODAY_LIST,
    payload: {
      ooo_today_list: response.ooo_today_list,
    },
  };
};

export const getOneOnOneTomorrowListSuccess = (response) => {
  return {
    type: GET_OOO_TOMORROW_LIST,
    payload: {
      ooo_tomorrow_list: response.ooo_tomorrow_list,
    },
  };
};

export const getOneOnOneThisWeekUpcomingListSuccess = (response) => {
  return {
    type: GET_OOO_THIS_WEEK_UPCOMING_LIST,
    payload: {
      ooo_this_week_upcoming_list: response.ooo_this_week_upcoming_list,
    },
  };
};

export const getOneOnOneNextWeekUpcomingListSuccess = (response) => {
  return {
    type: GET_OOO_NEXT_WEEK_UPCOMING_LIST,
    payload: {
      ooo_next_week_upcoming_list: response.ooo_next_week_upcoming_list,
    },
  };
};
