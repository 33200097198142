const initialState = {};

const ReviewCycleActions = (state = initialState, action) => {
  switch (action.type) {
    case "SET_REVIEW_CYCLE_ACTIONS": {
      return action.payload;
    }
    default:
      return state;
  }
};

export default ReviewCycleActions;
