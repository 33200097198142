import { GET_FILTERS_REQUEST } from '../../../actions/constants'; 

const initialState = {
  departments : null , 
  drivers : null , 
  locations : null , 
  managers : null 
}

const filters = (state = initialState , action) => {
  switch (action.type) {
    case GET_FILTERS_REQUEST : {
      let newState ; 
      newState = Object.assign({},state,{
        departments : action.payload.departments,
        drivers : action.payload.drivers,
        managers : action.payload.managers,
        locations : action.payload.locations,
      })
      return newState;
    }
    default:
      return state;
  }
};
  
export default filters;
  