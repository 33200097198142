import { ADD_MARKETING_LEADS } from '../../actions/constants';

const initialState = {
  marketing_lead : null
}

const marketingLeads = (state = initialState , action) => {
  switch (action.type) {
    case ADD_MARKETING_LEADS : {
      const newState = Object.assign({},state,{
        marketing_lead : action.payload
      })
      return newState;
    }

    default:
      return state;
  }
};

export default marketingLeads;
