import {
  CREATING_SURVEY,
  GET_SURVEY_LIST,
  UPDATE_SURVEY_LIST,
  SET_ACTIVE_SURVEY_CARD,
  DELETE_SURVEY,
  DUPLICATE_SURVEY,
  UPDATE_SURVEY_LIST_IDS,
  UPDATE_QUESTIONS_LIST,
  UPDATE_SURVEY_QUESTIONS_LIST_IDS,
  UPDATE_SURVEY_QUESTION_LIST,
  GET_ALL_QUESTIONS,
  UPDATE_SURVEY,
  GET_SURVEY_ANALYSIS,
  UPDATE_DRIVERS_LIST,
  GET_DRIVERS,
  GET_QUESTIONS,
  CUSTOM_QUESTION,
  GET_TEXT_RESPONSES,
  GET_SCORE_CARD_FILTER,
  GO_BACK_HISTORY,
} from "actions/constants";

const initialState = {
  creatingSurvey: false,
  surveyListStatus: null,
  surveyList: null,
  surveyListIds: null,
  activeSurveyCard: null,
  surveyQuestionListIds: null,
  questionsList: null,
  questionsListArray: null,
  maxQuestions: Infinity,
  drivers: [],
  goBackHistoryUrl: false,
  surveyQuestions: null,
  surveyAnalysis: {},
};

const surveyInfo = (state = initialState, action) => {
  switch (action.type) {
    case CREATING_SURVEY: {
      return { ...state, creatingSurvey: true };
    }

    case GET_SURVEY_LIST: {
      const newState = Object.assign({}, state, {
        surveyListStatus: action.payload.surveyListStatus,
        surveyList: action.payload.surveyList,
        surveyListIds: action.payload.surveyListIds,
      });
      return newState;
    }

    case UPDATE_SURVEY_LIST: {
      return {
        ...state,
        surveyList: {
          [action.payload.survey.id]: { ...action.payload.survey },
          ...state.surveyList,
        },
        surveyListIds: [action.payload.survey.id, ...state.surveyListIds],
        creatingSurvey: false,
      };
    }
    case UPDATE_SURVEY_LIST_IDS: {
      return {
        ...state,
        surveyListIds: action.payload.surveyListIds,
      };
    }
    case SET_ACTIVE_SURVEY_CARD: {
      return {
        ...state,
        activeSurveyCard: action.payload.activeSurveyCard,
      };
    }
    case UPDATE_SURVEY: {
      return {
        ...state,
        surveyList: {
          ...state.surveyList,
          [action.payload.survey.id]: { ...action.payload.survey },
        },
      };
    }
    case DELETE_SURVEY: {
      return {
        ...state,
        surveyList: action.payload.surveyList,
        surveyListIds: action.payload.surveyListIds,
        activeSurveyCard: action.payload.activeSurveyCard,
        surveyQuestionListIds: action.payload.surveyQuestionListIds,
      };
    }
    case DUPLICATE_SURVEY: {
      return {
        ...state,
        surveyList: action.payload.surveyList,
        surveyListIds: action.payload.surveyListIds,
        activeSurveyCard: action.payload.activeSurveyCard,
      };
    }
    case CUSTOM_QUESTION: {
      const questionsList = {
        ...state.questionsList,
        ...action.payload.customQuestion,
      };
      return {
        ...state,
        questionsList,
        questionsListArray: Object.values(questionsList),
      };
    }

    case UPDATE_QUESTIONS_LIST: {
      const questionsList = {
        ...action.payload.questionsList,
        ...state.questionsList,
      };
      return {
        ...state,
        questionsList,
        questionsListArray: Object.values(questionsList),
      };
    }
    case UPDATE_SURVEY_QUESTIONS_LIST_IDS: {
      return {
        ...state,
        surveyQuestionListIds: {
          ...action.payload.surveyQuestionListIds,
          ...state.surveyQuestionListIds,
        },
      };
    }
    case UPDATE_SURVEY_QUESTION_LIST: {
      return {
        ...state,
        surveyQuestionListIds: {
          ...state.surveyQuestionListIds,
          [action.payload.surveyId]: [...action.payload.questionsList],
        },
      };
    }
    case GO_BACK_HISTORY: {
      return {
        ...state,
        goBackHistoryUrl: action.payload,
      };
    }
    case GET_ALL_QUESTIONS: {
      const questionsList = {
        ...state.questionsList,
        ...action.payload.questionsList,
      };
      return {
        ...state,
        questionsList,
        questionsListArray: Object.values(questionsList),
        maxQuestions: action.payload.maxQuestions,
      };
    }
    case GET_SURVEY_ANALYSIS: {
      const properties = [
        "managers_team_participation",
        "employee_participation",
        "department_participation",
        "engagement_scores",
        "engagement_trends",
        "department_engagement_scores",
        "manager_support_scores",
        "manager_support_trends",
        "managers_scores",
      ];
      const data = {};
      action.payload.surveyAnalysisData.forEach((analysis, i) => {
        data[Object.keys(analysis)[0]] = analysis[properties[i]];
      });
      return {
        ...state,
        surveyAnalysis: {
          ...state.surveyAnalysis,
          [action.payload.surveyId]: data,
        },
      };
    }
    case UPDATE_DRIVERS_LIST: {
      return {
        ...state,
        drivers: action.payload.drivers,
      };
    }

    case GET_TEXT_RESPONSES: {
      const { dataObj } = action.payload;
      return {
        ...state,
        quesRespWithId: {
          ...state.quesRespWithId,
          ...dataObj,
        },
      };
    }

    case GET_DRIVERS: {
      const { survey_report } = action.payload.surveyDrivers;
      return {
        ...state,
        surveyDrivers: survey_report,
      };
    }

    case GET_QUESTIONS: {
      const { survey_report } = action.payload.surveyQuestions;
      return {
        ...state,
        surveyQuestions: survey_report,
      };
    }

    case GET_SCORE_CARD_FILTER: {
      return {
        ...state,
        scoreCardFilter: action.payload,
      };
    }
    default:
      return state;
  }
};

export default surveyInfo;
