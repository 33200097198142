import {
  GET_MESSAGE_LIST_REQUEST,
  UPDATE_LIST_BY,
  GET_SINGLE_MESSAGE_CONTENT,
} from "../../../actions/constants";

const initialState = {
  totalFeaturedResponseCount: null,
  totalResponseDueCount: null,
  totalResponseAwaitingCount: null,
  totalResponseClosedCount: null,
  totalPagesCount: null,
  content: null,
  list_by: "featured",
  sort_by: "default",
  departments: "",
  drivers: "",
  locations: "",
  managers: "",
  currentPage: 1,
};

const messageList = (state = initialState, action) => {
  switch (action.type) {
    case GET_MESSAGE_LIST_REQUEST: {
      const newState = Object.assign({}, state, {
        totalFeaturedResponseCount:
          action.payload.messageList.totalFeaturedResponseCount,
        totalResponseDueCount: action.payload.messageList.totalResponseDueCount,
        totalResponseAwaitingCount:
          action.payload.messageList.totalResponseAwaitingCount,
        totalResponseClosedCount:
          action.payload.messageList.totalResponseClosedCount,
        totalPagesCount: action.payload.messageList.totalPagesCount,
        content: action.payload.messageList.content,
        list_by: action.payload.list_by,
        sort_by: action.payload.sort_by,
        departments: action.payload.departments,
        drivers: action.payload.drivers,
        locations: action.payload.locations,
        managers: action.payload.managers,
        currentPage: action.payload.currentPage,
      });
      return newState;
    }
    case UPDATE_LIST_BY: {
      return {
        ...state,
        list_by: action.payload.listBy,
      };
    }
    case GET_SINGLE_MESSAGE_CONTENT: {
      return {
        ...state,
        totalFeaturedResponseCount:
          action.payload.singleMessageContent.totalFeaturedResponseCount,
        totalResponseDueCount:
          action.payload.singleMessageContent.totalResponseDueCount,
        totalResponseAwaitingCount:
          action.payload.singleMessageContent.totalResponseAwaitingCount,
        totalResponseClosedCount:
          action.payload.singleMessageContent.totalResponseClosedCount,
      };
    }
    default:
      return state;
  }
};

export default messageList;
