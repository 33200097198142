import {
  ADD_ONBOARDING_STEP_DETAILS,
  UPDATE_ONBOARDING_STEP_DETAILS,
  HIDE_TOUR_COMPONENT,
  SET_CURRENT_COMPONENT,
  SHOW_INCOMPLETE_STATE_TOUR_COMPONENT,
} from "../../actions/constants";
import { onboardingSequenceArr } from "../../actions/constants/onboardingConfig";

const initialState = {
  completedItemsCount: 0,
  currentItemKey: null,
  nextItemKey: null,
  nextButtonText: null,
  onboardingItemsObject: {},
  onboardingSequenceArr,
  showTourComponent: false,
  showConfettiComponent: true,
  totalItemsCount: onboardingSequenceArr.length,
  currentOnboardingComponent: null,
};

const onboarding = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ONBOARDING_STEP_DETAILS: {
      const newState = Object.assign({}, state, {
        ...action.payload,
      });
      return newState;
    }

    case UPDATE_ONBOARDING_STEP_DETAILS: {
      const newState = Object.assign({}, state, {
        ...action.payload,
      });
      return newState;
    }

    case HIDE_TOUR_COMPONENT: {
      const newState = Object.assign({}, state, {
        ...action.payload,
      });
      return newState;
    }

    case SET_CURRENT_COMPONENT: {
      const newState = Object.assign({}, state, {
        ...action.payload,
      });
      return newState;
    }

    case SHOW_INCOMPLETE_STATE_TOUR_COMPONENT: {
      const newState = Object.assign({}, state, {
        ...action.payload,
      });
      return newState;
    }

    default:
      return state;
  }
};

export default onboarding;
