import { GET_DEPARTMENTS_REQUEST } from '../../actions/constants'; 

const departmentListing = (state = {} , action) => {
  switch (action.type) {
    case GET_DEPARTMENTS_REQUEST: {
      const newState = Object.assign({},state,{
        departments : action.payload
      })
      return newState;
    }
    default:
      return state;
  }
};
  
export default departmentListing;
  