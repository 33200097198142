import { GET_DRIVERS_LIST_SUCCESS, ADD_NEW_DRIVER, DELETE_DRIVER, SAVE_DRIVER } from 'actions/constants';
import { log } from 'utils';

const initialState = {
  driverListObject: null,
  driverListIdsArray: null
}  

const driversList = (state = initialState , action) => {
  switch (action.type) {
    case GET_DRIVERS_LIST_SUCCESS: {
      const newState = Object.assign({},state,{
        ...action.payload
      })
      return newState;
    }
    case ADD_NEW_DRIVER: {
      const newState = Object.assign({},state,{
        driverListObject: [...state.driverListObject,...action.payload.drivers],
        driverListIdsArray: [ ...state.driverListIdsArray,action.payload.drivers[0].id]
      })
      return newState;
    }
    case DELETE_DRIVER: {
      
      const existingObjects = state.driverListObject.filter(driver=>{if(driver.id !== action.payload.id)return driver});
      
      const newState = {...state,
        driverListObject: existingObjects,
        driverListIdsArray: state.driverListIdsArray.filter(id => {if(id != action.payload.id)return id})
      }
      return newState;
    }
    case SAVE_DRIVER: {
      const existingObjects = state.driverListObject.filter(driver=>{if(driver.id !== action.payload.drivers[0].id)return driver});
      const newState = Object.assign({},state, {
        driverListObject: [...existingObjects,...action.payload.drivers] 
      });
      return newState;
    }
    default:
      return state;
  }
};

export default driversList;
