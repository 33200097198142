import { SHARED_NOTES_SAVE_STATUS , PRIVATE_NOTES_SAVE_STATUS} from '../../actions/constants'; 

const initialState = {
  sharedNotesSaved: {},
  privateNotesSaved: {}
}

const oneOnOneNotesSaveStatus = (state = initialState , action) => {
  switch (action.type) {    
    case SHARED_NOTES_SAVE_STATUS: {
      const newState = Object.assign({}, state , { sharedNotesSaved : {
        ...state.sharedNotesSaved , ...action.payload 
      }})
      return newState
    }

    case PRIVATE_NOTES_SAVE_STATUS: {
      const newState = Object.assign({}, state, { privateNotesSaved : {
        ...state.privateNotesSaved , ...action.payload 
      }})
      return newState
    }

    default:
      return state;
  }
};
  
export default oneOnOneNotesSaveStatus;
  