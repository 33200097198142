import { UPDATE_PAGE_TITLE , SUBNAVBAR_ACTIVE_MENU , GET_REFERRER_LOCATION} from '../actions/constants';

const initialState = {
  pageTitle: '',
  sidebarActiveMenu: '',
  activeMenu: null,
  referrerLocation : null
}

const ui = (state=initialState, action) => {
  switch(action.type) {
    case UPDATE_PAGE_TITLE: {
      const newState = Object.assign({}, state);
      newState.pageTitle = action.payload.pageTitle;
      newState.sidebarActiveMenu = action.payload.sidebarActiveMenu;
      return newState;
    }
    case SUBNAVBAR_ACTIVE_MENU : {
      const newState = Object.assign({}, state);
      newState.activeMenu = action.payload.activeMenu;
      return newState;
    }
    case GET_REFERRER_LOCATION : {
      const newState = Object.assign({}, state);
      newState.referrerLocation = action.payload;
      return newState;
    }
    default:
      return state;
  }
}
export default ui;