import { UPDATE_ONBOARDING_STEP_DETAILS , HIDE_TOUR_COMPONENT , SET_CURRENT_COMPONENT , SHOW_INCOMPLETE_STATE_TOUR_COMPONENT} from '../../constants';
import { ONE_ON_ONE_CREATED , RELATIONSHIP_ADDED, TALKING_POINTS, ACTION_ITEMS , onboardingConfig } from '../../constants/onboardingConfig';

export const checksBeforeOnboardingUpdate = (currentItemKey , dependency , showIncompleteStateTour) => {
  return ( dispatch , getState) => {
    const { onboardingItemsObject } = getState().onboarding;
    const { onboardingDetails } = getState().user;
    let onboardingComplete = true;
   /*  checking if onboarding details is present and onboarding complete and current step is complete , if all complete then not firing update api to display confetti */
    if(onboardingDetails && !onboardingDetails.onboarding_complete && !onboardingItemsObject[currentItemKey].complete){
      onboardingComplete = false
      if(!showIncompleteStateTour){
        if(currentItemKey === TALKING_POINTS || currentItemKey === ACTION_ITEMS){
          dispatch(parseNotesItems(currentItemKey , dependency));
        }else{
          dispatch(updateOnboardingObject(currentItemKey));
        }
      }else{
        /* special case when closing the pop up of create 1-on-1 display tour component with incomplete state heading and content */
        dispatch(showIncompleteStateTourComponent(currentItemKey));
      }
    }
    return onboardingComplete;
  }
}

export const parseNotesItems = (currentItemKey , notes_data) => {
  return ( dispatch , getState) => {
    const { employeeId } = getState().user;
    if(currentItemKey === TALKING_POINTS){
      let talkingPointListObjArr = notes_data.filter(notes_data => notes_data.type === 'talking-point-list');
      if(talkingPointListObjArr.length){
        talkingPointListObjArr.some((obj , key) => {
          let children = obj.children;
          /* logic to check talking point is filled not empty and is added by the user */
          let filledTalkingPoint = children.filter(value => value.children[0].text !== '' && value.added_by === employeeId);
          if(filledTalkingPoint.length){
            dispatch(updateOnboardingObject(currentItemKey));
            return true
          }
        })
      }
    }else if(currentItemKey === ACTION_ITEMS){
      let checkListItemArr = notes_data.filter(notes_data => notes_data.type === 'check-list-item' && notes_data.added_by === employeeId);
      if(checkListItemArr.length){
        checkListItemArr.some((obj , key) => {
          /* logic to check action item is not empty */
          if(obj.children[0].text !== ''){
            dispatch(updateOnboardingObject(currentItemKey));
            return true
          }
        })
      }
    }
  }
}

export const updateOnboardingObject = (currentItemKey , dependencies) => {
  return ( dispatch , getState) => {
    const {  
      completedItemsCount, 
      onboardingItemsObject, 
      totalItemsCount
    } = getState().onboarding;
    let updateOnboardingListObj = onboardingItemsObject;

    /* for mark complete */
    updateOnboardingListObj = { ...onboardingItemsObject , [currentItemKey]: { ...onboardingItemsObject[currentItemKey] , complete: true }}

    /* for updating enable state */
    if(currentItemKey === ONE_ON_ONE_CREATED || currentItemKey === RELATIONSHIP_ADDED){
      updateOnboardingListObj = updateOnboardingItemEnableState(updateOnboardingListObj , currentItemKey)
    }

    /* Setting next step key and button text dynamically for cases where next step is already complete */
    let nextItemObj = dispatch(setNextStep(currentItemKey)); 

    dispatch({
      type: UPDATE_ONBOARDING_STEP_DETAILS,
      payload: {
        completedItemsCount: completedItemsCount < totalItemsCount ? completedItemsCount + 1 : completedItemsCount,
        currentItemKey,
        nextItemKey: nextItemObj.nextItemKey,
        nextButtonText: nextItemObj.nextButtonText,
        onboardingItemsObject: {...onboardingItemsObject , ...updateOnboardingListObj},
        showTourComponent: true,
        showConfettiComponent: true
      }
    })
  }
}

/* settnig and updating enable state of the steps based on current completed key */
export const updateOnboardingItemEnableState = (onboardingItemsObject , stepKey) => {
  let updatedOnboardingItemsObj = onboardingItemsObject;
  if(updatedOnboardingItemsObj[stepKey].complete) {
    onboardingConfig[stepKey].enableSteps.map((item) => {
      updatedOnboardingItemsObj[item].enabled = true;
    });
  }
  return updatedOnboardingItemsObj;
}



export const setNextStep = (currentItemKey) => {
  return ( dispatch , getState) => {
    const { onboardingSequenceArr , onboardingItemsObject } = getState().onboarding;
    let currentItemIndex = onboardingSequenceArr.indexOf(currentItemKey);
    let arrLength = onboardingSequenceArr.length
    let nextItemObj = {}
    /* slicing out all left steps after the current step */
    let nextItems = onboardingSequenceArr.slice(currentItemIndex + 1, arrLength);
    if(nextItems.length){
      nextItems.some((value , key) => {
        /* setting next step only if the next value in sequence is incomplete */
        if(!onboardingItemsObject[value].complete){
          nextItemObj['nextItemKey'] = value;
          nextItemObj['nextButtonText'] = onboardingItemsObject[value].btnText;
          return true;
        }else{
          nextItemObj['nextItemKey'] = null;
          nextItemObj['nextButtonText'] = 'Done';
        }
      })
    }else{
      /* if the item is last then set next key as null */
      nextItemObj['nextItemKey'] = null;
      nextItemObj['nextButtonText'] = 'Done';
    }
    return nextItemObj;
  }
}

export const hideTourComponent = () => {
  return{
    type: HIDE_TOUR_COMPONENT,
    payload: {
      showTourComponent: false,
      currentItemKey: null,
      currentItemIndex: 0
    }
  }
}

export const setCurrentOnboardingComponent = ( currentOnboardingComponent ) => {
  return ( dispatch , getState) => {
    const { onboardingItemsObject } = getState().onboarding;
    if(Object.keys(onboardingItemsObject).length){
      dispatch({
        type: SET_CURRENT_COMPONENT,
        payload: {
          currentOnboardingComponent
        }
      })
    }
  }
}

/* Displaying when no items is complete , then to focus on onboarding components */
export const showIncompleteStateTourComponent = (currentItemKey) => {
  return ( dispatch , getState) => {
    const { onboardingItemsObject } = getState().onboarding;
    dispatch({
      type: SHOW_INCOMPLETE_STATE_TOUR_COMPONENT,
      payload: {
        showTourComponent: true,
        showConfettiComponent: false,
        currentItemKey,
        currentItemIndex: onboardingItemsObject[currentItemKey].step_no
      }
    })
  }
}