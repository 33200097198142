import { APPSUMO_LOADING_START, APPSUMO_LOADING_COMPLETE, 
  APPSUMO_REDEEM_SUCCESS, APPSUMO_REDEEM_ERROR } from '../../actions/constants'; 

const initialState = {
  loading : false,
  redeem_success: false,
  errors: null
}

const appSumo = (state = initialState , action) => {
    switch (action.type) {
      case APPSUMO_LOADING_START : {
        const newState = Object.assign({},state,{
          loading: true,
          redeem_success: false,
          errors: null
        })
        return newState;
      }
      case APPSUMO_LOADING_COMPLETE : {
        const newState = Object.assign({},state,{
          loading: false,
          redeem_success: false,
          errors: null
        })
        return newState;
      }
      case APPSUMO_REDEEM_SUCCESS : {
        const newState = Object.assign({},state,{
          loading: false,
          redeem_success: true,
          errors: null
        })
        return newState;
      }

      case APPSUMO_REDEEM_ERROR : {
        const newState = Object.assign({},state,{
          ...action.payload,
          loading: false,
          redeem_success: false
        })
        return newState;
      }
      
      default:
        return state;
    }
};
    
export default appSumo;