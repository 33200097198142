import { GET_PERFORMANCE_CHECKIN_DETAILS } from '../../actions/constants'; 

const initialState = {
  pulse: null
}

const performanceCheckin = (state = initialState , action) => {
  switch (action.type) {
    case GET_PERFORMANCE_CHECKIN_DETAILS : {
      const newState = Object.assign({},state,{
        pulse : action.payload.pulse,
      })
      return newState;
    }
    default:
      return state;
  }
};
  
export default performanceCheckin;
  