import { GET_FOLLOWUP_ITEMS , ADD_FOLLOWUP_ITEMS , DELETE_FOLLOWUP_ITEM , UPDATE_FOLLOWUP_ITEMS , TOGGLE_PAGINATE_ITEMS} from '../../actions/constants'; 

const followupItems = (state = {} , action) => {
  switch (action.type) {
    
    case GET_FOLLOWUP_ITEMS : {
      const newState = Object.assign({},state,{
        ...action.payload
      })
      return newState;
    }

    case TOGGLE_PAGINATE_ITEMS : {
      const newState = Object.assign({},state,{
        ...action.payload
      })
      return newState;
    }

    case ADD_FOLLOWUP_ITEMS : {
      const newState = Object.assign({},state,{
        followup_items : [...state.followup_items , action.payload]
      })
      return newState;
    }
    case UPDATE_FOLLOWUP_ITEMS : {
      const newState = Object.assign({},state,{

        ...action.payload
      })
      return newState;
    }

    case DELETE_FOLLOWUP_ITEM : {
      const newState = Object.assign({},state,{
        followup_items : action.payload
      })
      return newState;
    }

    default:
      return state;
  }
};
  
export default followupItems;
  