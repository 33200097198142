import { GET_QUESTIONS_LIST , GET_QUESTIONS_FROM_DB , ADD_LIBRARY_QUESTIONS , SCHEDULE_LIST, DRIVER_LIST } from '../../actions/constants';


const initialState = {
  newQuestionsList : [] ,
  questionsFromDb : [] ,
  scheduleList : [] , 
  driverList : []
}  

const manageQuestions = (state = initialState , action) => {
  switch (action.type) {
    case GET_QUESTIONS_LIST: {
      const newState = Object.assign({},state,{
        newQuestionsList : action.payload
      })
      return newState;
    }
    case GET_QUESTIONS_FROM_DB: {
      const newState = Object.assign({},state,{
        questionsFromDb : action.payload
      })
      return newState;
    }
    case ADD_LIBRARY_QUESTIONS: {
      const newState = Object.assign({},state,{
        addLibraryQuestions : action.payload
      })
      return newState;
    }
    case SCHEDULE_LIST : {
      const newState = Object.assign({},state,{
        scheduleList : action.payload
      })
      return newState;
    }
    case DRIVER_LIST :{
      const newState = Object.assign({},state,{
        driverList : action.payload
      })
      return newState;
    }
    default:
      return state;
  }
};

export default manageQuestions;
