import { ReactEditor } from "slate-react";
import { Editor, Path, Transforms, Range, Node } from "slate";
import { removeLink } from "../delete";
// import { transformElementTo, unwrapChildElements } from "../update";

// Below function can generate unique ids associated for node.
export const uuid = () => {
  const uuidv1 = require("uuid/v1");
  return uuidv1();
};

export const getCustomPropsValue = (customProps) => {
  let owner_id = null;
  let shared = null;
  if (customProps) {
    owner_id = customProps.owner.id;
    shared = customProps.shared;
  }
  return {
    added_by: owner_id,
    assigned_to: owner_id,
    shared: shared,
  };
};
// Below function can generate checklist item nodes on different depth levels
export const createCheckListItem = (depth, customProps, text) => {
  const propsValue = getCustomPropsValue(customProps);
  let checklistNodeItem = {
    id: uuid(),
    depth: "0",
    ...propsValue,
    type: "check-list-item",
    checked: false,
    children: [{ text }],
  };
  if (depth) {
    checklistNodeItem = {
      id: uuid(),
      depth,
      ...propsValue,
      type: "check-list-item",
      checked: false,
      children: [{ text }],
    };
  }
  return checklistNodeItem;
};

// Below function can generate paragraph nodes on different levels
export const createParagraph = (depth, customProps) => {
  const propsValue = getCustomPropsValue(customProps);
  let paragraph = {
    id: uuid(),
    depth: "0",
    ...propsValue,
    type: "paragraph",
    checked: null,
    children: [{ text: "" }],
  };
  if (depth) {
    paragraph = {
      id: uuid(),
      depth,
      ...propsValue,
      type: "paragraph",
      checked: null,
      children: [{ text: "" }],
    };
  }
  return paragraph;
};

// Below function can generate h1 nodes on different depth levels
export const createHeadingOne = (depth, customProps) => {
  const propsValue = getCustomPropsValue(customProps);
  let headingOne = {
    id: uuid(),
    depth: "0",
    ...propsValue,
    type: "heading-one",
    checked: null,
    children: [{ text: "" }],
  };
  if (depth) {
    headingOne = {
      id: uuid(),
      depth,
      ...propsValue,
      type: "heading-one",
      checked: null,
      children: [{ text: "" }],
    };
  }
  return headingOne;
};

// Below function can generate li elements on different depth levels
export const createListItem = (depth, customProps, text) => {
  const propsValue = getCustomPropsValue(customProps);
  let listItem = {
    id: uuid(),
    depth: "0",
    ...propsValue,
    type: "list-item",
    checked: null,
    children: [{ text }],
  };
  if (depth) {
    listItem = {
      id: uuid(),
      depth,
      ...propsValue,
      type: "list-item",
      checked: null,
      children: [{ text }],
    };
  }
  return listItem;
};
export const createTalkingPoint = (depth, customProps, text) => {
  const propsValue = getCustomPropsValue(customProps);
  let talkingPoint = {
    id: uuid(),
    depth: "0",
    ...propsValue,
    type: "talking-point",
    checked: false,
    children: [{ text }],
  };
  if (depth) {
    talkingPoint = {
      id: uuid(),
      depth,
      ...propsValue,
      type: "talking-point",
      checked: false,
      children: [{ text }],
    };
  }
  return talkingPoint;
};

// Below function can generate ul elements on different depth levels
export const createBulletListWrapper = () => {
  const bulletedList = {
    id: uuid(),
    type: "bulleted-list",
    checked: null,
    children: [],
  };
  return bulletedList;
};

export const createTalkingPointListWrapper = (customProps) => {
  const propsValue = getCustomPropsValue(customProps);
  const talkingPointList = {
    id: uuid(),
    type: "talking-point-list",
    ...propsValue,
    checked: null,
    children: [],
  };
  return talkingPointList;
};

// Below function can generate ol elements on different depth levels
export const createNumberedBulletListWrapper = () => {
  const numberedBulletedList = {
    id: uuid(),
    checked: null,
    type: "numbered-list",
    children: [],
  };
  return numberedBulletedList;
};

export const createParagraphNode = (children = [{ text: "" }]) => ({
  type: "paragraph",
  children,
});

export const createLinkNode = (href, text, textToDisplay) => {
  return {
    type: "link",
    url: href,
    href: href.indexOf("://") === -1 ? "http://" + href : href,
    children: [{ text: textToDisplay || text }],
  };
};

// export const handleInsertLink = (editor) => {
//   const url = prompt("Enter an Image URL");
//   insertLink(editor, url);
// };

export const handleInsertLink = (editor, linkData, editorSelection) => {
  insertLink(editor, linkData.url, linkData.text, editorSelection);
};

function checkURL(url) {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
}

export const insertLink = (editor, url, textToDisplay, editorSelection) => {
  // const modifiedEditorObject = { ...editor };
  editor.selection = editorSelection?.current;
  if (!url) return;
  const selection = editor?.selection || editorSelection?.current;
  const link = createLinkNode(url, url, textToDisplay);
  ReactEditor.focus(editor);
  if (selection) {
    const [parentNode, parentPath] = Editor.parent(
      editor,
      selection.focus?.path
    );
    // Remove the Link node if we're inserting a new link node inside of another link.
    if (parentNode.type === "link") {
      removeLink(editor);
    }
    if (editor.isVoid(parentNode)) {
      // Insert the new link after the void node
      Transforms.insertNodes(editor, createParagraph([link]), {
        at: Path.next(parentPath),
        select: true,
      });
    } else if (Range.isCollapsed(selection)) {
      // Insert the new link in our last known location
      Transforms.insertNodes(editor, link, { select: true });
    } else {
      // Wrap the currently selected range of text into a Link
      // console.log(editor, link, { split: true }, editor?.selection);
      Transforms.wrapNodes(editor, link, { split: true });
      // Remove the highlight and move the cursor to the end of the highlight
      Transforms.collapse(editor, { edge: "end" });
    }
  } else {
    // Insert the new link node at the bottom of the Editor when selection
    // is falsey
    Transforms.insertNodes(editor, createParagraphNode([link]));
  }
  // toggleBlock(editor, "link");
  // unwrapChildElements(editor);
  // transformElementTo(editor, "link", 0);
};

export const handleInsertImage = (editor, url, editorSelection) => {
  insertImage(editor, url, editorSelection);
};

// export const handleInsertImage = (editor, setLinkState) => {
//   setLinkState(null);
// };

export const createImageNode = (alt, src) => ({
  type: "image",
  alt,
  src,
  children: [{ text: "" }],
});

export const insertImage = (editor, url, editorSelection) => {
  if (!url) return;
  if (!checkURL(url)) return;
  const selection = editor?.selection || editorSelection?.current;
  const image = createImageNode("image", url);
  ReactEditor.focus(editor);
  if (selection) {
    const [parentNode, parentPath] = Editor.parent(
      editor,
      selection.focus?.path
    );

    if (editor.isVoid(parentNode) || Node.string(parentNode).length) {
      // Insert the new image node after the void node or a node with content
      Transforms.insertNodes(editor, image, {
        at: Path.next(parentPath),
        select: true,
      });
    } else {
      // If the node is empty, replace it instead
      Transforms.removeNodes(editor, { at: parentPath });
      Transforms.insertNodes(editor, image, { at: parentPath, select: true });
    }
  } else {
    // Insert the new image node at the bottom of the Editor when selection
    // is falsey
    Transforms.insertNodes(editor, image, { select: true });
  }
};
