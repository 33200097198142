import { GET_TIME_RANGE_90_DAYS , SUBSTRACT_INDEX_ONE , ADD_INDEX_ONE , GET_TIME_RANGE_QUATERLY} from '../actions/constants'; 
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

const initialState = {
  end_date : dayjs().format("Do MMM'YY"),
  start_date : dayjs().subtract(89, 'days').format("Do MMM'YY"),
}


const timeRange = (state = initialState , action) => {
  switch (action.type) {
    case GET_TIME_RANGE_90_DAYS : {
      let newState = Object.assign({},state,{
        time_range_last_90_days : action.payload.dateRange,
        start_date_range : action.payload.start_date_range,
        end_date_range : action.payload.end_date_range,
        start_date : action.payload.start_date,
        end_date : action.payload.end_date,
        curr_index : 0,
        last_index : action.payload.dateRange.length - 1
      })
      return newState;
    }

    case GET_TIME_RANGE_QUATERLY : {
      let newState = Object.assign({},state,{
        time_range_last_90_days : action.payload.dateRange,
        start_date_range : action.payload.start_date_range,
        end_date_range : action.payload.end_date_range,
        start_date : action.payload.start_date,
        end_date : action.payload.end_date,
        curr_index : 0,
        last_index : action.payload.dateRange.length - 1
      })
      return newState;
    }


    case SUBSTRACT_INDEX_ONE : {
      let newState;
      if(typeof state.curr_index !== "undefined" && state.curr_index !== 0){
        newState = Object.assign({} , state , {
          curr_index : action.payload.curr_index,
          start_date : action.payload.start_date,
          end_date : action.payload.end_date
        });
      }
      else{
        newState = Object.assign({} , state );
      }
      return newState;
    }

    case ADD_INDEX_ONE : {
      let newState;
      if(typeof state.curr_index !== "undefined" && state.curr_index !== state.last_index){
        newState = Object.assign({} , state , {
          curr_index : action.payload.curr_index,
          start_date : action.payload.start_date,
          end_date : action.payload.end_date
        });
      }
      else{
        newState = Object.assign({} , state);
      }
      return newState;

    }

    default:
      return state;
  }
};
  
export default timeRange;
  