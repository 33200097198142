import { FETCH_UPDATED_SUMMARY } from "actions/Goals/GoalsMetaData/GoalsMetaData.action";

const goalsSummary = (state = {}, action) => {
  switch (action.type) {
    case FETCH_UPDATED_SUMMARY: {
      const { payload } = action;
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};

export default goalsSummary;
