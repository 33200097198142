import { GET_ALL_ACCOUNT_ADMINS, USER_SWITCHED } from '../actions/constants';

const initialState = {
  accounts: null
}

const adminAccounts = (state=initialState, action) => {
  switch(action.type) {
    case GET_ALL_ACCOUNT_ADMINS: {
      const newState = Object.assign({}, state);
      newState.accounts = action.payload.accounts;
      return newState;
    }
    case USER_SWITCHED: {
      const newState = Object.assign({}, state);
      newState.accounts = null;
      return newState;
    }
    default:
      return state;
  }
}
export default adminAccounts;