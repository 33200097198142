import { INSIGHTS_REQUEST } from '../../actions/constants'; 

const initialState = {
  insights : null
}

const insights = (state = initialState , action) => {
  switch (action.type) {
    case INSIGHTS_REQUEST: {
      const newState = Object.assign({},state,{
        insights : action.payload
      })
      return newState;
    }
    default:
      return state;
  }
};
  
export default insights;
  