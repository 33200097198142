import {
  UPDATE_PROJECT_META_DATA,
  UPDATE_PROJECT_META_DATA_LOADING,
} from "./ProjectMetaData.action";

// projectDetailsList, goalList, keyResultIdList, projectIdList is just dump
// and not mapped to current page id(department, user) like others
// UPDATE_PROJECT_META_DATA check this action for more info

const INIT_STATE = {
  projectMetaDataLoading: true,
  metaTypes: {},
  objectiveList: {},
  keyResultIdList: {},
  projectIdList: {},
  goalList: {},
  projectDetailsList: {},
};

const projectsMetaDataStore = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PROJECT_META_DATA_LOADING: {
      return {
        ...state,
        projectMetaDataLoading: payload,
      };
    }
    case UPDATE_PROJECT_META_DATA: {
      const {
        goalTypeId,
        metaTypes,
        objectiveList,
        keyResultIdList,
        projectIdList,
        goalList,
        projectDetailsList,
      } = payload;
      return {
        ...state,
        metaTypes: { ...state.metaTypes, ...metaTypes },
        objectiveList: {
          ...state.objectiveList,
          [goalTypeId]: Object.keys(objectiveList)?.length
            ? {
                ...(state.objectiveList[goalTypeId] || {}),
                ...objectiveList,
              }
            : { ...(state.objectiveList[goalTypeId] || {}) },
        },
        keyResultIdList: {
          ...state.keyResultIdList,
          ...keyResultIdList,
        },
        projectIdList: {
          ...state.projectIdList,
          ...projectIdList,
        },
        goalList: {
          ...state.goalList,
          ...goalList,
        },
        projectDetailsList: {
          ...state.projectDetailsList,
          ...projectDetailsList,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default projectsMetaDataStore;
