import { GET_TALKING_POINTS_FROM_ANSWERS } from '../../actions/constants'; 

const initialState = {
  talking_points_from_answers_list : null
}

const talkingPointsFromAnswers = (state = initialState , action) => {
  switch (action.type) {
    case GET_TALKING_POINTS_FROM_ANSWERS : {
      const newState = Object.assign({},state,{
        talking_points_from_answers_list : action.payload
      })
      return newState;
    }

    default:
      return state;
  }
};
  
export default talkingPointsFromAnswers;
  