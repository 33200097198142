import {
  INTEGRATION_CONNECTOR_DETAIL,
  INTEGRATION_CONNECTOR_LIST,
  INTEGRATION_CONNECTOR_METRIC,
  INTEGRATION_CONNECTOR_VERIFY_INPUT,
  INTEGRATION_QUERY_BLOCK_SUCCESS,
} from "actions/constants";

const initialState = {
  connectorList: [],
  connectorDetail: {},
};

const goalIntegrationsDetails = (state = initialState, action) => {
  switch (action.type) {
    case INTEGRATION_CONNECTOR_LIST: {
      return { ...state, connectorList: action.payload };
    }

    case INTEGRATION_CONNECTOR_DETAIL: {
      const detailObject = {};
      detailObject[action.payload.id] = action.payload.data;
      return {
        ...state,
        connectorDetail: detailObject,
      };
    }
    case INTEGRATION_CONNECTOR_METRIC: {
      const metricObject = {};
      metricObject[action.payload?.id] = action.payload?.data;
      return {
        ...state,
        connectorMetrics: metricObject || null,
      };
    }
    case INTEGRATION_CONNECTOR_VERIFY_INPUT: {
      return {
        ...state,
        connectorVerifyDetails: action.payload,
      };
    }
    case INTEGRATION_QUERY_BLOCK_SUCCESS: {
      return {
        ...state,
        connectorQueryFields: {
          data: action?.payload?.response,
          dontUseDefault: action.payload?.dontUseDefault,
        },
      };
    }
    default:
      return state;
  }
};

export default goalIntegrationsDetails;
