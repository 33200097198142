import { DEPARTMENT_MANAGER_PERFORMANCE_REQUEST } from '../../actions/constants'; 

const initialState = {
  managers_performance : null
}
  
const departmentManagerPerformance = (state = initialState , action) => {
  switch (action.type) {
    case DEPARTMENT_MANAGER_PERFORMANCE_REQUEST: {
      const newState = Object.assign({},state,{
        managers_performance : action.payload.managers_performance,
      })
      return newState;
    }
    default:
      return state;
  }
};
  
export default departmentManagerPerformance;
  