import initHelpHero from 'helphero';
export let HelpHero = initHelpHero('bDCdnOcTxVs');


export const ONE_ON_ONE_CREATED = "one_on_one_created";
export const RELATIONSHIP_ADDED = "relationship_added";
export const REPORT_INVITED = "report_invited";
export const PERFORMANCE_RATING = "performance_rating";
export const TALKING_POINTS = "talking_points";
export const ACTION_ITEMS = "action_items";
export const SLACK_INTEGRATION = "slack_integrated";

export const onboardingSequenceArr = [ONE_ON_ONE_CREATED , RELATIONSHIP_ADDED , REPORT_INVITED , PERFORMANCE_RATING , TALKING_POINTS , ACTION_ITEMS , SLACK_INTEGRATION];
export const onboardingConfig = {
  [ONE_ON_ONE_CREATED]: {
    listItemText: `Create your 1:1s`,
    heading: `Woohoo! Your 1-on-1 has been created!`,
    content: `Click ‘Set Relationship’ to take the next step. 👉`,
    incompleteStateHeading: `Please click on "Create 1-on-1" 👉`,
    incompleteStateContent: `Creating 1-on-1 will take you to the next step`,
    btnText: null,
    defaultEnabled: true,
    complete: false,
    enableSteps: [RELATIONSHIP_ADDED],
  },
  [RELATIONSHIP_ADDED]: {
    listItemText: `Set Relationship`,
    heading: `Great! You’ve successfully set your relationship.`,
    content: `Now, go ahead and invite them to use Peoplebox.`,
    incompleteStateHeading: null,
    incompleteStateContent: null,
    btnText: `Next Step: Set Relationship`,
    defaultEnabled: false,
    complete: false,
    enableSteps: [REPORT_INVITED, PERFORMANCE_RATING, TALKING_POINTS, ACTION_ITEMS, SLACK_INTEGRATION],
  },
  [REPORT_INVITED]: {
    listItemText: `Invite Reports`,
    heading: `Awesome! Now that your direct reports are onboard, you’ll be notified when they share their productivity & add talking points before next 1-on-1.`,
    content: `Let us get to the next step where you can ‘Rate Performance’.`,
    incompleteStateHeading: null,
    incompleteStateContent: null,
    btnText: `Next Step: Invite Reports`,
    defaultEnabled: false,
    complete: false,
    enableSteps: [],
  },
  [PERFORMANCE_RATING]: {
    listItemText: `Rate Performance`,
    heading: `Great! Reflecting on your direct report’s performance helps you share instant feedback.`,
    content: `Now, ‘Add Talking Points’ to set an agenda for your upcoming 1-on-1.`,
    incompleteStateHeading: null,
    incompleteStateContent: null,
    btnText: `Next Step: Rate Performance`,
    defaultEnabled: false,
    complete: false,
    enableSteps: [],
  },
  [TALKING_POINTS]: {
    listItemText: `Add Talking points`,
    heading: `Yay! You are now all set for the upcoming 1-on-1 meeting. You can add more talking points as and when they occur to you.`,
    content: `The best way to conclude a 1-on-1 is with ‘Action Items’. Let us try it out!`,
    incompleteStateHeading: null,
    incompleteStateContent: null,
    btnText: `Next Step: Add Talking points`,
    defaultEnabled: false,
    complete: false,
    enableSteps: [],
  },
  [ACTION_ITEMS]: {
    listItemText: `Add Action items`,
    heading: `Actions speak louder than words. Make sure to add ‘Action Items’ at the end of every 1-on-1.`,
    content: `You can also set reminders & assign ‘Action Items’ to inculcate a culture of accountability.`,
    incompleteStateHeading: null,
    incompleteStateContent: null,
    btnText: `Next Step: Add Action items`,
    defaultEnabled: false,
    complete: false,
    enableSteps: [],
  },
  [SLACK_INTEGRATION]: {
    listItemText: `Integrate Slack`,
    heading: `Woah! That was simple, right?`,
    content: `You are all set to have an awesome 1-on-1! Go ahead and explore Peoplebox.`,
    incompleteStateHeading: null,
    incompleteStateContent: null,
    btnText: `Done`,
    defaultEnabled: false,
    complete: false,
    enableSteps: [],
  }
}