import { SYNCING_PROJECTS_FROM_GOOGLE_SHEET } from "actions/constants";

const initialState = {};

const GoogleSheetProjectSyncStatus = (state = initialState, action) => {
  switch (action.type) {
    case SYNCING_PROJECTS_FROM_GOOGLE_SHEET: {
      const currentPayload = action?.payload;
      const departmentId = currentPayload?.departmentId;

      return {
        ...state,
        googleSheetProjectSyncStatus: {
          ...state.googleSheetProjectSyncStatus,
          [departmentId]: { ...currentPayload },
        },
      };
    }
    default:
      return state;
  }
};

export default GoogleSheetProjectSyncStatus;
