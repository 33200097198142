import { GET_PRODUCT_PERMISSIONS } from "actions/constants";

const ProductPermissions = (state = {}, action) => {
  switch (action.type) {
    case GET_PRODUCT_PERMISSIONS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default ProductPermissions;
