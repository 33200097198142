import { GET_OOO_LIST_POLLING_DATA , STOP_POLLING_ONE_ON_ONE } from '../../../actions/constants'; 

const initialState = {
  upcoming_api: false,
  create_one_on_one: false,
  add_from_calendar: false,
  keep_polling: false,
  timer_cleared : true
}
const oneOnOnePollingDetails = (state = initialState , action) => {
  switch (action.type) {

    case GET_OOO_LIST_POLLING_DATA : {
      const newState = Object.assign({},state,{
        ...action.payload
      })
      return newState;
    }

    case STOP_POLLING_ONE_ON_ONE : {
      const newState = Object.assign({},state,{
        ...action.payload
      })
      return newState;
    }

    default:
      return state;
    }
};
  
export default oneOnOnePollingDetails;
  