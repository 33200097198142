import { POST_MESSAGE_REQUEST , SHOW_CONVERSATION_REQUEST } from '../../../actions/constants';

const initialState = {
  conversation : null , 
  response_id : null 
}

const postMessage = (state = initialState , action) => {
  switch (action.type) {
    case POST_MESSAGE_REQUEST : {
      let newState ; 
      newState = Object.assign({},state,{
        new_message : action.payload
      })
      return newState;
    }

    case SHOW_CONVERSATION_REQUEST : {
        let newState ;
        newState = Object.assign({} , state , {
          conversation : action.payload.messages,
          response_id : action.payload.response_id
        })
      return newState;
    }
    default:
      return state;
  }
};
  
export default postMessage;
  