import styled from "@emotion/styled";
import variables from "styles/variables.scss";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

export const H1 = styled.h1`
  font-size: 26px;
  font-weight: 600;
  color: ${variables.blackTextColor};
  margin-bottom: 0px;
`;

export const H2 = styled(H1)`
  font-size: 22px;
`;
export const H3 = styled(H1)`
  font-size: 20px;
`;
export const H4 = styled(H1)`
  font-size: 18px;
  font-weight: 600;
`;
export const H5 = styled(H1)`
  font-size: 16px;
`;
export const PrimaryH1 = styled(H1)`
  color: ${variables.primaryColor};
`;
export const PrimaryH2 = styled(H2)`
  color: ${variables.primaryColor};
`;
export const PrimaryH3 = styled(H3)`
  color: ${variables.primaryColor};
`;
export const PrimaryH4 = styled(H4)`
  color: ${variables.primaryColor};
`;
export const Text1 = styled.p`
  font-size: 16px;
  color: ${variables.blackTextColor};
  margin-bottom: 0px;
`;
export const Text2 = styled(Text1)`
  font-size: 14px;
`;
export const Text3 = styled(Text1)`
  font-size: 12px;
`;

export const ErrorText3 = styled(Text3)`
  color: red;
`;
export const FadedH4 = styled(H4)`
  color: ${variables.blackTextFadedColor};
  font-weight: normal;
`;
export const FadedText1 = styled(Text1)`
  color: ${variables.blackTextFadedColor};
`;
export const FadedText2 = styled(Text2)`
  color: ${variables.blackTextFadedColor};
`;
export const FadedText3 = styled(Text3)`
  color: ${variables.blackTextFadedColor};
`;
export const PrimaryText1 = styled(Text1)`
  color: ${variables.primaryColor};
`;
export const PrimaryText2 = styled(Text2)`
  color: ${variables.primaryColor};
`;
export const PrimaryText3 = styled(Text3)`
  color: ${variables.primaryColor};
`;
export const Link1 = styled.a`
  font-size: 18px;
  font-weight: 500;
  color: ${variables.primaryColor};
  text-decoration: underline;
  &:hover {
    color: ${variables.primaryColor};
    text-decoration: underline;
  }
`;
export const Link2 = styled(Link1)`
  font-size: 16px;
`;
export const Link3 = styled(Link1)`
  font-size: 14px;
`;

const titleHypenCss = css`
  position: relative;
  padding: 0px 20px;
  &:before {
    content: " ";
    border-bottom: 1px solid ${variables.blackTextFadedColor};
    position: absolute;
    top: 50%;
    left: -150px;
    width: 150px;
  }
  &:after {
    content: " ";
    border-bottom: 1px solid ${variables.blackTextFadedColor};
    position: absolute;
    top: 50%;
    right: -150px;
    width: 150px;
  }
`;

export const TitleHyphen = (props) => {
  return (
    <FadedH4 css={titleHypenCss} className={props.className}>
      {props.children}
    </FadedH4>
  );
};

export const TitleDash = styled.div`
  width: 20%;
  height: 5px;
  background-color: ${variables.backgroundSecondaryColor};
`;

export const AddCircle = styled.div({
  borderRadius: "50%",
  height: "48px",
  width: "48px",
  background: "#FFFFFF",
  border: "1px dashed #D7D7E9",
  boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.08)",
  color: "#9799A9",
  fontSize: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
});
