import React from "react";
import wootric from "../../config/wootric";

class Wootric extends React.Component {
  componentDidMount() {
    const setupScript = document.createElement("script");
    setupScript.type = "text/javascript";
    setupScript.id = "wootric-settings";
    setupScript.async = true;
    setupScript.innerHTML = `
      wootric_no_surveyed_cookie = ${wootric.WOOTRIC_NO_SURVEYED_COOKIE};
      wootric_survey_immediately = ${wootric.WOOTRIC_SURVEY_IMMEDIATELY};
    `;

    window.wootricSettings = {
      email: this.props.email,
      created_at: Date.parse(this.props.created_at) / 1000,
      account_token: wootric.WOOTRIC_ACCOUNT_TOKEN,
      modal_footprint: "compact",
    };

    if (document.getElementById("wootric-settings") == null) {
      document.body.appendChild(setupScript);
    }

    // Beacon
    const beacon = document.createElement("script");
    beacon.type = "text/javascript";
    beacon.id = "wootric-beacon";
    beacon.async = true;

    beacon.src = "https://cdn.wootric.com/wootric-sdk.js";
    beacon.onload = function () {
      window.wootric("run");
    };
    if (document.getElementById("wootric-beacon") == null) {
      document.body.appendChild(beacon);
    }
  }

  render() {
    return <div />;
  }
}

export default Wootric;
