import { ADD_TO_ONE_ON_ONE_LIST_DETAILS } from "../../../actions/constants";

const initialState = {
  ooo_list_details: null,
};

const oneOnOneListDetails = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_ONE_ON_ONE_LIST_DETAILS: {
      const newState = {
        ...state,
        ooo_list_details: {
          ...state.ooo_list_details,
          ...action.payload.ooo_list_details,
        },
      };
      return newState;
    }

    default:
      return state;
  }
};

export default oneOnOneListDetails;
