import { GET_DIRECT_REPORT_GOAL_DATA } from "actions/constants";

const initialState = {};

const ApproveGoals = (state = initialState, action) => {
  switch (action.type) {
    case GET_DIRECT_REPORT_GOAL_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export default ApproveGoals;
