import {
  GET_CALENDER_ITEMS,
  HANDLE_CHECKED_EVENTS_IDS,
  EMPTY_CALENDER_STATE,
  SET_SYNCING_STATUS,
  CALENDAR_TIMER_STATUS,
} from "../../constants";
import { API_ROOT } from "../../../config/api-config";
import axios from "axios";
import { message } from "antd";
import { getOneOnOneList } from "../../OneOnOne/OneOnOneList";
message.config({
  top: 100,
  duration: 2,
  maxCount: 3,
});

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("dashboard_auth_token")}`,
    },
  };
};

let timerInterval = null;
let timerTimeout = null;

export const getCalenderDataPolling = (callFromRefresh) => {
  return (dispatch, getState) => {
    timerInterval = setInterval(() => {
      dispatch(getCalenderItemsList(callFromRefresh));
    }, 2000);

    timerTimeout = setTimeout(() => {
      // clear interval for polling after 10 seconds
      clearInterval(timerInterval); // stop timer if data recieved
      dispatch(
        getCalenderItemsListSuccess({ calendar_data_fetch_complete: true })
      ); // on timeout displaying the previous fetched data if any
      const timer_cleared = true;
      dispatch(calendarTimerStatus(timer_cleared)); // stop timer if data recieved
      dispatch(setSyncingStatus(false));
    }, 25000);
  };
};

export const getCalenderItemsList = (callFromRefresh) => {
  return (dispatch, getState) => {
    const requestUrl = "fetch_calendar_data";
    const { accountId, employee_details } = getState().user;
    const { calendar_timer_cleared } = getState().calenderItems;
    let emp_id;
    if (employee_details) {
      emp_id = employee_details.id;
    }
    axios
      .get(
        `${API_ROOT}/accounts/${accountId}/managers/${emp_id}/${requestUrl}`,
        headers()
      )
      .then((response) => {
        if (response.data.calendar_data_fetch_complete) {
          dispatch(stopCalendarTimer()); // stop timer if data recieved
          dispatch(getCalenderItemsListSuccess(response.data));
          dispatch(setSyncingStatus(false));
        } else {
          if (calendar_timer_cleared) {
            const timer_cleared = false;
            dispatch(calendarTimerStatus(timer_cleared));
          }
          dispatch(getCalenderItemsListSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(stopCalendarTimer()); // stop timer if data error
        dispatch(setSyncingStatus(false));
        console.log(error);
      });
  };
};

export const calendarTimerStatus = (response) => {
  return {
    type: CALENDAR_TIMER_STATUS,
    payload: {
      calendar_timer_cleared: response,
    },
  };
};

export const stopCalendarTimer = () => {
  // when component unmount or data_fetched or timeout then stop timers
  return (dispatch, getState) => {
    const { syncing_calender_items } = getState().calenderItems;
    clearTimeout(timerTimeout);
    clearInterval(timerInterval);
    if (syncing_calender_items) {
      dispatch(setSyncingStatus(false)); // setting this to initial state
    }
    dispatch(
      getCalenderItemsListSuccess({ calendar_data_fetch_complete: true })
    ); // on timeout displaying the previous fetched data if any
    // for user display
    return {
      type: CALENDAR_TIMER_STATUS,
      payload: {
        calendar_timer_cleared: true,
      },
    };
  };
};

export const getCalenderItemsListSuccess = (response) => {
  return {
    type: GET_CALENDER_ITEMS,
    payload: {
      ...response,
    },
  };
};

export const OnChangeCalendarEventsCheckbox = (value) => {
  return (dispatch, getState) => {
    const { checked_event_ids } = getState().calenderItems;
    let new_checked_event_ids = [];
    if (checked_event_ids.includes(value.id)) {
      new_checked_event_ids = checked_event_ids.filter(
        (val) => val !== value.id
      ); // if id already present then remove it
    } else {
      new_checked_event_ids = [...checked_event_ids, value.id];
    }
    dispatch({
      type: HANDLE_CHECKED_EVENTS_IDS,
      payload: {
        checked_event_ids: new_checked_event_ids,
      },
    });
  };
};

export const createEventsFromCalendar = () => {
  return (dispatch, getState) => {
    const requestUrl = "add_reportees_events";
    const { accountId, employee_details } = getState().user;
    const { id } = employee_details;
    const { checked_event_ids } = getState().calenderItems;
    const data = { events: checked_event_ids };
    axios
      .post(
        `${API_ROOT}/accounts/${accountId}/managers/${id}/${requestUrl}`,
        data,
        headers()
      )
      .then((response) => {
        message.success("Events added successfully");
        dispatch(emptyCalenderState());
        dispatch(getOneOnOneList("upcoming"));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const refreshCalendarEvents = () => {
  return (dispatch, getState) => {
    const requestUrl = "refresh_calendar_data";
    const { accountId, employee_details } = getState().user;
    const { id } = employee_details;
    const data = {};
    axios
      .post(
        `${API_ROOT}/accounts/${accountId}/managers/${id}/${requestUrl}`,
        data,
        headers()
      )
      .then((response) => {
        const callFromRefresh = true;
        dispatch(getCalenderDataPolling(callFromRefresh));
        dispatch(setSyncingStatus(true));
        dispatch(calendarTimerStatus(false));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const emptyCalenderState = () => {
  return (dispatch, getState) => {
    dispatch({
      type: EMPTY_CALENDER_STATE,
      payload: {
        events_list: null,
        data_hash: [],
        checked_event_ids: [],
        syncing_calender_items: false,
        zero_state: false,
        calendar_data_fetch_complete: false,
      },
    });
  };
};

export const setSyncingStatus = (status) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_SYNCING_STATUS,
      payload: {
        syncing_calender_items: status,
      },
    });
  };
};
