import { uuid } from "components/SlateEditor/utils/create";

const initialState = {
  socket: false,
  socketConnectError: true,
  socketConnectStatus: "Connecting",
  uniqueId: uuid(),
  bizEditorDraftStatus: null,
};

const socketConnect = (state = initialState, action) => {
  switch (action.type) {
    case "SOCKET_CONNECTED": {
      const newState = Object.assign({}, state);
      newState.socket = action.payload.socket;
      return newState;
    }
    case "SOCKET_CONNECT_ERROR": {
      const newState = Object.assign({}, state);
      newState.socketConnectError = action.payload.socketConnectError;
      return newState;
    }
    case "SOCKET_CONNECT_STATUS": {
      const newState = {
        ...state,
        socketConnectStatus: action.payload.socketConnectStatus,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default socketConnect;
