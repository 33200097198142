import { GET_FEEDBACK_QUESTIONS , GET_FEEDBACK_STATUS , ADD_TEMP_RESPONSES , FEEDBACK_SURVEY_STATUS , NAVIGATE_QUESTION, CHANGE_SURVEY_START} from '../../actions/constants'; 

const initialState = {
  pulse : null,
  pulse_question_ids_list : null ,
  pulse_questions : null ,
  prev_pulse_ques_id : null ,
  current_pulse_ques_id : null ,
  next_pulse_ques_id : null,
  survey_start : false,
  current_question : null,
  temp_responses : [],
  temp_current_response : null,
  next_btn_disabled : true,
  feedback_status : null
}

const feedbackQuestions = (state = initialState , action) => {
  switch (action.type) {
    case GET_FEEDBACK_QUESTIONS : {
      const newState = Object.assign({},state,{
        pulse : action.payload.pulse,
        pulse_question_ids_list : action.payload.pulse_question_ids_list,
        pulse_questions : action.payload.pulse_questions,
        prev_pulse_ques_id : action.payload.prev_pulse_ques_id,
        current_pulse_ques_id : action.payload.current_pulse_ques_id,
        next_pulse_ques_id : action.payload.next_pulse_ques_id,
        current_question : action.payload.current_question,
        temp_current_response : action.payload.temp_current_response,
        next_btn_disabled : action.payload.next_btn_disabled,
        feedback_status : action.payload.pulse.status,
        survey_start: action.payload.survey_start
      })
      return newState;
    }

    case FEEDBACK_SURVEY_STATUS : {
      const newState = Object.assign({},state,{
        survey_start : action.payload.survey_start
      })
      return newState;
    }

    case NAVIGATE_QUESTION : {
      const newState = Object.assign({},state,{
        prev_pulse_ques_id : action.payload.prev_pulse_ques_id,
        current_pulse_ques_id : action.payload.current_pulse_ques_id,
        next_pulse_ques_id : action.payload.next_pulse_ques_id,
        current_question : action.payload.current_question,
        temp_current_response : action.payload.temp_current_response,
        next_btn_disabled : action.payload.next_btn_disabled
      })
      return newState;
    }

    case ADD_TEMP_RESPONSES : {
      const newState = Object.assign({},state,{
        temp_responses: [...state.temp_responses , action.payload.temp_responses],
        temp_current_response : action.payload.temp_current_response
      })
      return newState;
    }

    case GET_FEEDBACK_STATUS : {
      const newState = Object.assign({},state,{
        feedback_status : action.payload.status,
        pulse : action.payload
      })
      return newState;
    }
    case CHANGE_SURVEY_START : {
      const newState = Object.assign({}, state, {
        survey_start: action.payload.survey_start
      })
      return newState;
    }
    default:
      return state;
  }
};
  
export default feedbackQuestions;
  