import {
  SET_SELECTED_GOAL_ID,
  SET_CURRENT_TIMELINE,
  SET_SELECTED_DEPARTMENT_ID,
  UPDATE_BIZ_GOAL_DATA,
  SET_BIZ_GOAL_LOADING,
  SET_REFRESH_BIZ_REVIEW,
  SET_PREVIOUS_PAGE,
  RESET_BIZ_REVIEW_GOAL_DATA,
  SET_RCA_FORM_DATA,
  SET_BIZ_CONFIG_LOADING,
  SET_OWNER_DATA,
  SET_BIZ_REVIEW_CARD_DATA,
  SET_IS_BIZ_CARD_LIST_LOADING,
  SET_BIZ_CARD_DATA,
  SET_BIZ_CARD_LOADING,
  SET_OWNER_ID_DATA,
  SET_BIZ_STATUS,
  SET_BIZ_STATUS_LOADING,
  SET_BIZ_OWNER_EMPTY_TEXT,
  SET_BIZ_OWNER_LOADING,
  SET_BIZ_OWNER_SEARCH_RESULT,
  DELETE_ACTION_ITEM,
  CREATE_NEW_ACTION_ITEM,
  UPDATE_BIZ_ACTION_ITEM,
  SET_MOM_FORM_DATA,
  BIZ_PRESENTATION_CARD_ORDER,
  RESET_SELECTED_GOAL_ID,
  SET_BIZ_WEEKLY_MONTHLY_DATA,
  SET_BIZ_WEEKLY_MONTHLY_LOADING_KEY,
  RESET_BIZ_WEEKLY_MONTHLY_LOADING_KEY,
  ISFROMLISTINGPAGE,
  SET_IS_CREATING_BIZ_TEXT_PANEL,
  SET_IS_DELETING_BIZ_REVIEW_CARD,
  SET_BIZ_GOAL_NODE_TREE_DATA,
  SELECTED_COMMENT_CARD_ID,
  UPDATE_BIZ_CARD_COMMENT_COUNT,
  UPDATE_BIZ_CARD_TITLE,
  SET_CARD_DATA,
  RESET_BIZ_WEEKLY_MONTHLY,
  SET_KPI_CARD_VIEW_TYPE,
  SET_CONTEXTUAL_COMMENTS,
  SET_CONTEXTUAL_COMMENT_THREAD,
  UPDATE_CARD_MENU_CONFIG,
  FLUSH_SELECTED_GOAL_ID,
  RESET_OWNER_ID_DATA,
  UPDATE_BIZ_CARD_QUALITATIVE_UPDATE,
  SET_NOTE_HISTORY_CARD_ID,
  SET_BIZ_OKR_TOGGLE_STATE,
  SET_COLUMN_LIST,
  SET_SELECTED_BIZ_CHECKIN_CELL,
  RESET_SELECTED_BIZ_CHECKIN_CELL,
  SET_COLUMN_CELL_VALUE,
  SET_CUSTOM_COLUMN_TITLE,
  DELETE_CUSTOM_COLUMN,
} from "./BizReview.action";

const DEFAULT_SELECTED_CHECKIN_CELL_OBJ = {
  checkinDate: null,
  isMilestoneGoal: false,
  milestoneTitle: "",
};

const INIT_STATE = {
  selectedGoalId: {},
  activityLoading: false,
  currentTimeLine: null,
  selectedDepartmentId: null,
  bizReviewGoalData: {},
  isBizGoalLoading: false,
  refreshBizReview: false,
  previousPage: null,
  departmentName: null,
  rcaFormData: null,
  isBizConfigLoading: false,
  ownerObjectData: {},
  ownerIdList: [],
  bizReviewCardData: {},
  isBizCardListLoading: false,
  cardObject: {},
  bizCardData: {},
  bizCardLoading: {},
  ownerIdData: {},
  bizReviewOwner: {},
  bizWeeklyMonthly: {},
  bizWeeklyMonthlylLoading: {},
  bizStatusLoading: null,
  // Added for owner filter in biz review
  bizOwnerLoading: false,
  bizOwnerSearchResult: null,
  bizOwnerTempEmployeeData: null,
  bizOwnerEmptyText: null,
  isFromListingPage: false,
  isCreatingBizTextPanel: false,
  isDeletingBizReviewCard: false,
  bizGoalNodeTreeData: {},
  bizSelectedCommentCardId: null,
  bizContextualComments: {},
  bizContextualSelectedThreadIdentifier: null,
  bizContextualThreadFromEditor: null,
  cardMenuConfig: {},
  bizNoteHistoryCardId: {},
  selectedCheckinCell: DEFAULT_SELECTED_CHECKIN_CELL_OBJ,
  selectedBizCheckinCell: DEFAULT_SELECTED_CHECKIN_CELL_OBJ,
  // Custom columns
  goalColumnsMap: {},
  columnMap: {},
};

const bizReviewStore = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case RESET_BIZ_REVIEW_GOAL_DATA: {
      return {
        ...state,
        bizReviewGoalData: {},
      };
    }

    case SET_SELECTED_GOAL_ID: {
      const { selectedGoalId, cardId } = payload;
      return {
        ...state,
        selectedGoalId: { ...state.selectedGoalId, [cardId]: selectedGoalId },
      };
    }

    case RESET_SELECTED_GOAL_ID: {
      return {
        ...state,
        selectedGoalId: {},
      };
    }

    case SET_CURRENT_TIMELINE: {
      const { currentTimeLine } = payload;

      return {
        ...state,
        currentTimeLine,
      };
    }

    case SET_SELECTED_DEPARTMENT_ID: {
      const { selectedDepartmentId, departmentName } = payload;

      return {
        ...state,
        selectedDepartmentId,
        departmentName,
      };
    }

    case UPDATE_BIZ_GOAL_DATA: {
      const { departmentId, timeline, goalList } = payload;
      const { bizReviewGoalData, departmentName } = state;
      const finalDepartmentKey = `${departmentId} - ${departmentName}`;
      return {
        ...state,
        bizReviewGoalData: {
          ...bizReviewGoalData,
          [finalDepartmentKey]: bizReviewGoalData[finalDepartmentKey]
            ? { ...bizReviewGoalData[finalDepartmentKey], [timeline]: goalList }
            : { [timeline]: goalList },
        },
      };
    }

    case SET_BIZ_GOAL_LOADING: {
      const { isBizGoalLoading } = payload;
      return {
        ...state,
        isBizGoalLoading,
      };
    }

    case SET_REFRESH_BIZ_REVIEW: {
      const { refreshBizReview } = payload;
      return {
        ...state,
        refreshBizReview,
      };
    }

    case SET_PREVIOUS_PAGE: {
      const { previousPage } = payload;
      return {
        ...state,
        previousPage,
      };
    }

    case SET_RCA_FORM_DATA: {
      const { rcaFormData, goalId } = payload;
      return {
        ...state,
        rcaFormData: {
          ...state.rcaFormData,
          [goalId]: rcaFormData,
        },
      };
    }

    case SET_BIZ_CONFIG_LOADING: {
      const { isBizConfigLoading } = payload;
      return {
        ...state,
        isBizConfigLoading,
      };
    }

    case SET_OWNER_DATA: {
      const { ownerObjectData, ownerIdList } = payload;

      return {
        ...state,
        ownerObjectData,
        ownerIdList,
      };
    }

    case SET_BIZ_REVIEW_CARD_DATA: {
      const { ownerId, bizReviewId, scheduleId, cardList, cardObject } =
        payload;

      return {
        ...state,
        bizReviewCardData: {
          ...state.bizReviewCardData,
          [ownerId]: {
            ...(state?.bizReviewCardData?.[ownerId] || {}),
            [bizReviewId]: {
              ...(state?.bizReviewCardData?.[ownerId]?.[bizReviewId] || {}),
              [scheduleId]: cardList,
            },
          },
        },
        cardObject: { ...state?.cardObject, ...cardObject },
      };
    }

    case SET_IS_BIZ_CARD_LIST_LOADING: {
      const { isBizCardListLoading } = payload;

      return {
        ...state,
        isBizCardListLoading,
      };
    }

    case SET_BIZ_CARD_DATA: {
      const { bizCardData, cardId } = payload;

      return {
        ...state,
        bizCardData: { ...state.bizCardData, [cardId]: bizCardData },
      };
    }

    case SET_BIZ_CARD_LOADING: {
      const { bizCardLoading, cardId } = payload;

      return {
        ...state,
        bizCardLoading: { ...state.bizCardLoading, [cardId]: bizCardLoading },
      };
    }

    case SET_CARD_DATA: {
      const { cardData, cardId } = payload;

      return {
        ...state,
        cardObject: { ...state.cardObject, [cardId]: cardData },
      };
    }

    case SET_OWNER_ID_DATA: {
      const { ownerId, bizReviewId, scheduleId } = payload;

      return {
        ...state,
        ownerIdData: {
          ...state.ownerIdData,
          [ownerId]: { bizReviewId, scheduleId },
        },
        bizReviewOwner: { ...state.bizReviewOwner, [bizReviewId]: ownerId },
      };
    }

    case RESET_OWNER_ID_DATA: {
      return {
        ...state,
        ownerIdData: {},
      };
    }

    case SET_BIZ_OWNER_EMPTY_TEXT: {
      return {
        ...state,
        bizOwnerEmptyText: action.payload,
      };
    }
    case SET_BIZ_OWNER_LOADING: {
      return {
        ...state,
        bizOwnerLoading: action.payload,
      };
    }

    case SET_BIZ_OWNER_SEARCH_RESULT: {
      return {
        ...state,
        bizOwnerSearchResult: action.payload,
      };
    }

    case DELETE_ACTION_ITEM: {
      const { actionItemId, cardId, keyOfActionItem } = action.payload;
      return {
        ...state,
        bizCardData: {
          ...state.bizCardData,
          [cardId]: {
            ...state.bizCardData[cardId],
            [keyOfActionItem]: [
              ...state.bizCardData[cardId]?.[keyOfActionItem].filter(
                (item) => item.id !== actionItemId
              ),
            ],
          },
        },
      };
    }
    case SET_MOM_FORM_DATA: {
      const { rcaFormData, goalId } = payload;
      return {
        ...state,
        momFormData: {
          ...state.rcaFormData,
          [goalId]: rcaFormData,
        },
      };
    }

    case CREATE_NEW_ACTION_ITEM: {
      const { cardId, data } = action.payload;
      const updateActionItemList = [...state.bizCardData[cardId].action_items];
      updateActionItemList.unshift(data);
      return {
        ...state,
        bizCardData: {
          ...state.bizCardData,
          [cardId]: {
            ...state.bizCardData[cardId],
            action_items: [...updateActionItemList],
          },
        },
      };
    }

    case UPDATE_BIZ_ACTION_ITEM: {
      const { cardId, actionItemId, data, keyOfActionItem } = action.payload;
      const actionItemArray = [
        ...state?.bizCardData[cardId]?.[keyOfActionItem],
      ];
      const indexOfData = state?.bizCardData[cardId]?.[
        keyOfActionItem
      ].findIndex((x) => x.id === actionItemId);
      actionItemArray[indexOfData] = { ...data };
      return {
        ...state,
        bizCardData: {
          ...state.bizCardData,
          [cardId]: {
            ...state.bizCardData[cardId],
            [keyOfActionItem]: [...actionItemArray],
          },
        },
      };
      // const actionItemArray = [...state?.bizCardData[cardId].action_items];
      // const indexOfData = state?.bizCardData[cardId].action_items.findIndex(
      //   (x) => x.id === actionItemId
      // );
      // actionItemArray[indexOfData] = { ...data };
      // return {
      //   ...state,
      //   bizCardData: {
      //     ...state.bizCardData,
      //     [cardId]: {
      //       ...state.bizCardData[cardId],
      //       action_items: [...actionItemArray],
      //     },
      //   },
      // };
    }

    case SET_BIZ_STATUS_LOADING: {
      const { status } = payload;

      return {
        ...state,
        bizStatusLoading: status,
      };
    }

    case SET_BIZ_STATUS: {
      const { ownerId, status, scheduleId } = payload;

      const ownerObjectData = {
        ...(state.ownerObjectData || {}),
        [ownerId]: {
          ...(state.ownerObjectData[ownerId] || {}),
          allSchedule: {
            ...(state.ownerObjectData[ownerId]?.allSchedule || {}),
            [scheduleId]: {
              ...(state.ownerObjectData[ownerId]?.allSchedule?.[scheduleId] ||
                {}),
              status,
            },
          },
        },
      };

      return {
        ...state,
        ownerObjectData,
      };
    }

    case BIZ_PRESENTATION_CARD_ORDER: {
      const { bizReviewId, bizScheduleId, ownerId, data } = payload;

      const ownerPresentationData = {
        ...state?.ownerPresentationData,
        [ownerId]: {
          [bizReviewId]: {
            [bizScheduleId]: data,
          },
        },
      };
      return {
        ...state,
        ownerPresentationData,
      };
    }

    case SET_BIZ_WEEKLY_MONTHLY_DATA: {
      const { cardId, progressData, filterType } = payload;

      return {
        ...state,
        bizWeeklyMonthly: {
          ...(state.bizWeeklyMonthly || {}),
          [cardId]: {
            ...(state.bizWeeklyMonthly?.[cardId] || {}),
            [filterType]: {
              ...(state.bizWeeklyMonthly?.[cardId]?.[filterType] || {}),
              ...progressData,
            },
          },
        },
      };
    }

    case RESET_BIZ_WEEKLY_MONTHLY: {
      const { cardId } = payload;
      const updatedBizWeeklyMonthly = { ...(state.bizWeeklyMonthly || {}) };
      delete updatedBizWeeklyMonthly[cardId];

      return {
        ...state,
        bizWeeklyMonthly: updatedBizWeeklyMonthly,
      };
    }

    case SET_BIZ_WEEKLY_MONTHLY_LOADING_KEY: {
      const { cardId, loadingKeys, filterType } = payload;

      return {
        ...state,
        bizWeeklyMonthlylLoading: {
          ...(state.bizWeeklyMonthlylLoading || {}),
          [cardId]: {
            ...(state.bizWeeklyMonthlylLoading?.[cardId] || {}),
            [filterType]: {
              ...(state.bizWeeklyMonthlylLoading?.[cardId]?.[filterType] || {}),
              ...loadingKeys,
            },
          },
        },
      };
    }

    case RESET_BIZ_WEEKLY_MONTHLY_LOADING_KEY: {
      const { cardId, filterType } = payload;

      return {
        ...state,
        bizWeeklyMonthlylLoading: {
          ...(state.bizWeeklyMonthlylLoading || {}),
          [cardId]: {
            ...(state.bizWeeklyMonthlylLoading?.[cardId] || {}),
            [filterType]: {},
          },
        },
      };
    }

    case ISFROMLISTINGPAGE: {
      const { isFromListingPage } = payload;

      return {
        ...state,
        isFromListingPage,
      };
    }

    case SET_IS_CREATING_BIZ_TEXT_PANEL: {
      const { isCreating } = payload;

      return {
        ...state,
        isCreatingBizTextPanel: isCreating,
      };
    }

    case SET_IS_DELETING_BIZ_REVIEW_CARD: {
      const { isDeleting } = payload;

      return {
        ...state,
        isDeletingBizReviewCard: isDeleting,
      };
    }

    case SET_BIZ_GOAL_NODE_TREE_DATA: {
      const { id, nodeTreeData } = payload;

      if (id === undefined) {
        return {
          ...state,
          bizGoalNodeTreeData: state.bizGoalNodeTreeData,
        };
      }

      return {
        ...state,
        bizGoalNodeTreeData: {
          ...state.bizGoalNodeTreeData,
          [id]: { ...(state.bizGoalNodeTreeData[id] ?? {}), ...nodeTreeData },
        },
      };
    }

    case SELECTED_COMMENT_CARD_ID: {
      const { cardId } = payload;
      return {
        ...state,
        bizSelectedCommentCardId: cardId,
      };
    }

    case UPDATE_BIZ_CARD_COMMENT_COUNT: {
      const { cardId, newCommentCount } = payload;

      const modifiedCardObject = { ...state?.cardObject };

      modifiedCardObject[cardId].biz_review_comments_count = newCommentCount;
      return { ...state };
    }

    case UPDATE_BIZ_CARD_TITLE: {
      const { cardId, title } = payload;

      const modifiedCardObject = { ...state?.cardObject };

      modifiedCardObject[cardId].title = title;
      return { ...state };
    }

    case UPDATE_BIZ_CARD_QUALITATIVE_UPDATE: {
      const { cardId, showQualitativeUpdates } = payload;
      const modifiedCardObject = { ...state?.cardObject };
      modifiedCardObject[cardId].properties = {
        ...modifiedCardObject[cardId].properties,
        show_qualitative_updates: showQualitativeUpdates,
      };
      return { ...state };
    }

    case SET_CONTEXTUAL_COMMENTS: {
      const { cardId, commentArray } = payload;
      const modifiedContextualComments = { ...state?.bizContextualComments };
      modifiedContextualComments[cardId] = commentArray;
      return { ...state, bizContextualComments: modifiedContextualComments };
    }

    case SET_CONTEXTUAL_COMMENT_THREAD: {
      return { ...state, bizContextualSelectedThreadIdentifier: payload };
    }

    case SET_KPI_CARD_VIEW_TYPE: {
      const { cardId, viewType } = payload;
      const newData = {};
      newData[cardId] = viewType;

      return {
        ...state,
        kpiCardViewType: { ...state.kpiCardViewType, ...newData },
      };
    }

    case UPDATE_CARD_MENU_CONFIG: {
      const { cardId, menuConfig } = payload;

      return {
        ...state,
        cardMenuConfig: {
          ...state.cardMenuConfig,
          [cardId]: menuConfig,
        },
      };
    }

    case FLUSH_SELECTED_GOAL_ID: {
      return { ...state, selectedGoalId: {} };
    }

    case SET_NOTE_HISTORY_CARD_ID: {
      return {
        ...state,
        bizNoteHistoryCardId: payload,
      };
    }

    case SET_BIZ_OKR_TOGGLE_STATE: {
      return {
        ...state,
        toggleState: payload,
      };
    }

    case SET_SELECTED_BIZ_CHECKIN_CELL: {
      const { checkinDate, isMilestoneGoal, milestoneTitle } = payload;
      return {
        ...state,
        selectedBizCheckinCell: {
          checkinDate,
          isMilestoneGoal,
          milestoneTitle,
        },
      };
    }

    case RESET_SELECTED_BIZ_CHECKIN_CELL: {
      return {
        ...state,
        selectedBizCheckinCell: DEFAULT_SELECTED_CHECKIN_CELL_OBJ,
      };
    }

    case "SET_CUSTOM_COLUMN_DATA": {
      const { cardId, columnData } = payload;

      return {
        ...state,
        goalColumnsMap: {
          ...state.goalColumnsMap,
          [cardId]: state.goalColumnsMap[cardId]
            ? Object.keys(columnData).reduce(
                (acc, columnId) => {
                  if (!state.goalColumnsMap[cardId][columnId]) {
                    acc[columnId] = {};
                  }
                  acc[columnId] = { ...acc[columnId], ...columnData[columnId] };
                  return acc;
                },
                { ...state.goalColumnsMap[cardId] }
              )
            : { ...columnData },
        },
      };
    }

    case SET_COLUMN_LIST: {
      const { cardId, columnList } = payload;

      return {
        ...state,
        columnMap: {
          ...state.columnMap,
          [cardId]: columnList,
        },
      };
    }

    case SET_COLUMN_CELL_VALUE: {
      const { cardId, columnId, nodeId, value } = payload;

      const newState = { ...state };
      if (state?.goalColumnsMap?.[cardId]?.[columnId]?.[nodeId]) {
        newState.goalColumnsMap[cardId][columnId][nodeId].value = value;

        return newState;
      } else {
        if (!state?.goalColumnsMap?.[cardId]) {
          newState.goalColumnsMap[cardId] = {
            [columnId]: {
              [nodeId]: {
                value,
              },
            },
          };

          return newState;
        }

        if (!state?.goalColumnsMap?.[cardId]?.[columnId]) {
          newState.goalColumnsMap[cardId][columnId] = {
            [nodeId]: {
              value,
            },
          };

          return newState;
        }

        if (!state?.goalColumnsMap?.[cardId]?.[columnId]?.[nodeId]) {
          newState.goalColumnsMap[cardId][columnId][nodeId] = {
            value,
          };

          return newState;
        }
      }

      return newState;
    }

    case SET_CUSTOM_COLUMN_TITLE: {
      const { cardId, title, columnId } = payload;

      return {
        ...state,
        columnMap: {
          ...state.columnMap,
          [cardId]: state.columnMap[cardId].map((column) => {
            if (column.id === columnId) {
              return {
                ...column,
                field_name: title,
              };
            }
            return column;
          }),
        },
      };
    }

    case DELETE_CUSTOM_COLUMN: {
      const { cardId, columnId } = payload;

      return {
        ...state,
        columnMap: {
          ...state.columnMap,
          [cardId]: state.columnMap[cardId].filter(
            (column) => column.id !== columnId
          ),
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default bizReviewStore;
