import {
  FETCH_META_DATA_DETAILS,
  FETCH_META_DATA_DETAILS_FAIL,
} from "actions/constants";

const initialState = {
  metaDataloading: true,
  metaDataFailError: null,
};

const getMetaDataContent = (state = initialState, action) => {
  const { metaDataResponse, deptId } = action.payload || {};
  switch (action.type) {
    case FETCH_META_DATA_DETAILS: {
      return {
        metaDataFailError: null,
        metaDataloading: false,
        [deptId]: {
          ...state[deptId],
          ...metaDataResponse,
        },
      };
    }

    case FETCH_META_DATA_DETAILS_FAIL: {
      return {
        ...state,
        metaDataFailError: action.payload,
        metaDataloading: false,
      };
    }

    default:
      return state;
  }
};

export default getMetaDataContent;
