import {
  GET_MANAGER_AND_DIRECT_REVIEWS,
  UPDATE_SEARCH_PEERS_RESULT,
} from "actions/constants";

const initialState = {};

const NominatePeer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_PEERS_RESULT: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case GET_MANAGER_AND_DIRECT_REVIEWS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export default NominatePeer;
