import {
  GET_APPROVE_PEER_LIST,
  GET_MANAGER_APPROVE_NOMINATIONS,
} from "actions/constants";

const initialState = {};

const ApprovePeers = (state = initialState, action) => {
  switch (action.type) {
    case GET_MANAGER_APPROVE_NOMINATIONS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case GET_APPROVE_PEER_LIST: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export default ApprovePeers;
