export const INIT_RAW_FILTER = "INIT_RAW_FILTER";
export const UPDATE_RAW_FILTER = "UPDATE_RAW_FILTER";
export const ADD_GOALS_FILTER = "ADD_GOALS_FILTER";
export const DELETE_GOALS_FILTER = "DELETE_GOALS_FILTER";
export const UPDATE_IS_REFRESH = "UPDATE_IS_REFRESH";
export const SET_IS_GOAL_FILTER_LOADING = "SET_IS_GOAL_FILTER_LOADING";
export const RESET_GOAL_FILTER = "RESET_GOAL_FILTER";
export const SET_INSTANT_REFRESH = "SET_INSTANT_REFRESH";
export const SET_TEMP_EMPLOYEE_DATA = "SET_TEMP_EMPLOYEE_DATA";
export const SET_FILTER_LOADING = "SET_FILTER_LOADING";
export const SET_FILTER_SEARCH_RESULT = "SET_FILTER_SEARCH_RESULT";
export const SET_EMPTY_TEXT = "SET_EMPTY_TEXT";
export const SET_DEPARTMENT_DATA = "SET_DEPARTMENT_DATA";
export const SET_DEPARTMENT_CHILDREN_MAP = "SET_DEPARTMENT_CHILDREN_MAP";
export const SET_ROOT_DEPARTMENT_LIST = "SET_ROOT_DEPARTMENT_LIST";
export const SET_SELECTED_GOAL_DRAWER_ID = "SET_SELECTED_GOAL_DRAWER_ID";
export const SET_SHOW_ALL_GOALS_ACTIVITIES = "SET_SHOW_ALL_GOALS_ACTIVITIES";
export const SET_CHECKIN_DATA = "SET_CHECKIN_DATA";
export const SET_SELECTED_OKR_CHECKIN_CELL = "SET_SELECTED_OKR_CHECKIN_CELL";
export const RESET_SELECTED_OKR_CHECKIN_CELL =
  "RESET_SELECTED_OKR_CHECKIN_CELL";
