import { SET_IS_ALL_GOAL } from "./GoalMeta.action";

const INIT_STATE = {
  isAllGoal: undefined,
};

const goalMetaReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_IS_ALL_GOAL: {
      return {
        ...state,
        isAllGoal: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default goalMetaReducer;
