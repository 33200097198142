import {
  GET_REVIEW_CYCLE_STATUS,
  RESET_REVIEW_CYCLES,
} from "actions/constants";

const initialState = {};

const ReviewCycle = (state = initialState, action) => {
  switch (action.type) {
    case GET_REVIEW_CYCLE_STATUS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case RESET_REVIEW_CYCLES: {
      return {};
    }
    default:
      return state;
  }
};

export default ReviewCycle;
