import { TRENDS_DATA_REQUEST } from '../../actions/constants'; 

const initialState = {
  engagement : null , 
  manager_support : null
}

const trends = (state = initialState , action) => {
  switch (action.type) {
    case TRENDS_DATA_REQUEST : {
      const newState = Object.assign({},state,{
        engagement : action.payload.engagement,
        manager_support : action.payload.manager_support
      })
      return newState;
    }
    
    default:
      return state;
  }
};
  
export default trends;
  