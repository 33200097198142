import { lazy } from "react";

// Pages prefetched

// Dashboard pre fetch
const LoadableDashboardPromise = import(
  "../containers/DashboardContainer" /* webpackChunkName: "dashboardContainer" */
);

export const LoadableDashboard = lazy(() => LoadableDashboardPromise);

// Goal Listing Page
const LoadableNewGoalsListingPagePromise = import(
  "containers/Goals/pages/NewGoalsListingPage" /* webpackChunkName: "GoalListingPage" */
);

export const LoadableNewGoalsListingPage = lazy(
  () => LoadableNewGoalsListingPagePromise
);

// One on One Listing Page
const LoadableOneOnOneListPromise = import(
  "../containers/DashboardContainer/OneOnOne/OneOnOneList" /* webpackChunkName: "OneOnOneList" */
);

export const LoadableOneOnOneList = lazy(() => LoadableOneOnOneListPromise);

// Home Page
const LoadableHomePagePromise = import(
  "containers/NewHomepage" /* webpackChunkName: "HomePage" */
);

export const LoadableHomePage = lazy(() => LoadableHomePagePromise);

// Engagement Container
const LoadableEngagementContainerPromise = import(
  "containers/Engagement/EngagementContainer" /* webpackChunkName: "EngagementContainer" */
);

export const LoadableEngagementContainer = lazy(
  () => LoadableEngagementContainerPromise
);

// MetaBase Usage Reports
const LoadableMetabaseUsageReportsPromise = import(
  "containers/DashboardContainer/Settings/MetabaseUsageReports/MetabaseUsageReports" /* webpackChunkName: "MetabaseUsageReports" */
);

export const LoadableMetabaseUsageReports = lazy(
  () => LoadableMetabaseUsageReportsPromise
);

const LoadablePerformanceNominateApprovePromise = import(
  "containers/PerformanceReviews/pages/ApprovePeers" /* webpackChunkName: "ApprovePeers" */
);

export const LoadablePerformanceNominateApprove = lazy(
  () => LoadablePerformanceNominateApprovePromise
);

const LoadableBizReviewsPagePromise = import(
  "containers/BizReviews/pages/BizReviewsListing" /* webpackChunkName: "BizReviewListingPage" */
);

export const LoadableBizReviewsPage = lazy(() => LoadableBizReviewsPagePromise);

// Pages fetched on demand

export const LoadableLogin = lazy(() =>
  import("../containers/Login" /* webpackChunkName: "Login" */)
);

export const LoadableSSOLogin = lazy(() =>
  import("../containers/SsoContainer" /* webpackChunkName: "SSOLogin" */)
);

export const LoadableSignup = lazy(() =>
  import("../containers/SignUp" /* webpackChunkName: "signup" */)
);

export const LoadableAppSumo = lazy(() =>
  import("../containers/AppSumo" /* webpackChunkName: "AppSumo" */)
);

export const LoadableDashboardOverview = lazy(() =>
  import(
    "../containers/DashboardContainer/Overview" /* webpackChunkName: "Analytics" */
  )
);

export const LoadableInsightsDepartment = lazy(() =>
  import(
    "../containers/DashboardContainer/Insights/Department" /* webpackChunkName: "Department" */
  )
);

export const LoadableInsightsHeatmap = lazy(() =>
  import(
    "../containers/DashboardContainer/Insights/Heatmap" /* webpackChunkName: "Heatmap" */
  )
);

export const LoadableActionMessaging = lazy(() =>
  import(
    "../containers/DashboardContainer/Messaging" /* webpackChunkName: "Messaging" */
  )
);

export const LoadableManageQuestion = lazy(() =>
  import(
    "../containers/DashboardContainer/ManageQuestion" /* webpackChunkName: "ManageQuestion" */
  )
);

export const LoadableViewQuestion = lazy(() =>
  import(
    "../containers/DashboardContainer/ViewQuestion" /* webpackChunkName: "ViewQuestion" */
  )
);

export const LoadableSingleMessaging = lazy(() =>
  import(
    "../containers/DashboardContainer/Messaging/SingleMessaging" /* webpackChunkName: "SingleMessaging" */
  )
);

export const LoadableSwitchUser = lazy(() =>
  import(
    "../containers/DashboardContainer/SwitchUser" /* webpackChunkName: "SwitchUser" */
  )
);

export const LoadableEmployeeMessaging = lazy(() =>
  import(
    "../containers/EmployeeMessaging" /* webpackChunkName: "EmployeeMessaging" */
  )
);

export const LoadableSetPassword = lazy(() =>
  import("../containers/SetPassword" /* webpackChunkName: "SetPassword" */)
);

export const LoadableSingleDepartment = lazy(() =>
  import(
    "../containers/DashboardContainer/Insights/Department/Overview" /* webpackChunkName: "SingleDepartment" */
  )
);

export const LoadableOneOnOneContainer = lazy(() =>
  import(
    "../containers/DashboardContainer/OneOnOne/OneOnOneShow" /* webpackChunkName: "OneOnOneShow" */
  )
);

export const LoadableSettingsContainer = lazy(() =>
  import(
    "../containers/DashboardContainer/Settings" /* webpackChunkName: "SettingsContainer" */
  )
);

export const LoadableManageTeam = lazy(() =>
  import(
    "../containers/DashboardContainer/Settings/ManageTeam" /* webpackChunkName: "ManageTeam" */
  )
);

export const LoadableBilling = lazy(() =>
  import(
    "../containers/DashboardContainer/Settings/Billing" /* webpackChunkName: "SwitchUser" */
  )
);

export const LoadableSlack = lazy(() =>
  import(
    "../containers/DashboardContainer/Settings/Slack" /* webpackChunkName: "Slack" */
  )
);

export const LoadableGeneralSettings = lazy(() =>
  import(
    "../containers/DashboardContainer/Settings/GeneralSettings" /* webpackChunkName: "General settings" */
  )
);
export const LoadableAccountSettings = lazy(() =>
  import(
    "../containers/DashboardContainer/Settings/AccountSettings" /* webpackChunkName: "AccountSettings" */
  )
);
export const LoadableNotifications = lazy(() =>
  import(
    "../containers/DashboardContainer/Settings/Notifications" /* webpackChunkName: "Notifications" */
  )
);

export const LoadableManageEmployeeData = lazy(() =>
  import(
    "../containers/DashboardContainer/Settings/ManageEmployeeData" /* webpackChunkName: "ManageEmployeeData" */
  )
);

export const LoadableEngagementAnalytics = lazy(() =>
  import(
    "containers/Engagement/EngagementAnalytics" /* webpackChunkName: "EngagementContainer" */
  )
);

// Pulse survey Lodables, reused: survey audience and survey schedule

export const LoadablePulseSurveyDashboard = lazy(() =>
  import(
    "containers/Engagement/PulseSurveyDashboard/PulseSurveyDashboard.page" /* webpackChunkName: "PulseSurveyDashboard" */
  )
);

export const LoadablePulseSurveyEdit = lazy(() =>
  import(
    "containers/Engagement/PulseSurveyEdit/PulseSurveyEdit.page" /* webpackChunkName: "PulseSurveyEdit" */
  )
);

export const LoadablePulseSurveySchedule = lazy(() =>
  import(
    "containers/Engagement/SurveyScheduleNewPage/SurveyScheduleNew.pages" /* webpackChunkName: "SurveyScheduleNew" */
  )
);

export const LoadableCreateSurveyContainer = lazy(() =>
  import(
    "containers/Engagement/CreateSurveyFromTemplate" /* webpackChunkName: "EngagementContainer" */
  )
);

export const LoadableConfigSurveyContainer = lazy(() =>
  import(
    "containers/Engagement/SurveyConfig" /* webpackChunkName: "SurveyScheduleNew" */
  )
);

export const LoadableSelectSurveyQuestionsContainer = lazy(() =>
  import(
    "containers/Engagement/SurveyQuestionSelect" /* webpackChunkName: "EngagementContainer" */
  )
);
export const LoadableSurveyQuestionSetup = lazy(() =>
  import(
    "containers/Engagement/SurveyQuestionSetup/SurveyQuestionSetup.page" /* webpackChunkName: "EngagementContainer" */
  )
);
export const LoadableSurveyAudiencePageContainer = lazy(() =>
  import(
    "containers/Engagement/SurveyAudiencePage" /* webpackChunkName: "EngagementContainer" */
  )
);
export const LoadableSurveySchedulePageContainer = lazy(() =>
  import(
    "containers/Engagement/SurveySchedulePage" /* webpackChunkName: "EngagementContainer" */
  )
);

export const LoadableDesignSystem = lazy(() =>
  import("DesignSystem" /* webpackChunkName: "DesignSystem" */)
);

export const LoadableBusinessReviewPage = lazy(() =>
  import(
    "containers/Goals/pages/BizReviewPage/BizReviewPage" /* webpackChunkName: "BizReviewPage" */
  )
);

export const LoadableGoalsShowPage = lazy(() =>
  import(
    "containers/Goals/pages/GoalsShowPage" /* webpackChunkName: "GoalsShowPage" */
  )
);
export const LoadableGoalsChartPage = lazy(() =>
  import(
    "containers/Goals/pages/GoalsChart" /* webpackChunkName: "GoalsChartPage" */
  )
);

export const LoadableCoffeeConnect = lazy(() =>
  import("containers/CoffeeConnect" /* webpackChunkName: "CoffeeConnect" */)
);

export const LoadableReview = lazy(() =>
  import(
    "containers/Reviews/pages/ReviewsList" /* webpackChunkName: "Review" */
  )
);

export const LoadableReviewShow = lazy(() =>
  import(
    "containers/Reviews/pages/ReviewsShow" /* webpackChunkName: "Review Show" */
  )
);

export const LoadableBasicReviewDetails = lazy(() =>
  import(
    "containers/Reviews/pages/BasicReviewDetails" /* webpackChunkName: "EngagementContainer" */
  )
);

export const LoadableReviewPhases = lazy(() =>
  import(
    "containers/Reviews/pages/ReviewPhases" /* webpackChunkName: "EngagementContainer" */
  )
);

export const LoadableReviewQuestions = lazy(() =>
  import(
    "containers/Reviews/pages/ReviewForms" /* webpackChunkName: "EngagementContainer" */
  )
);

export const LoadableReviewQuestionsSelector = lazy(() =>
  import(
    "containers/Reviews/pages/ReviewForms/QuestionSelector" /* webpackChunkName: "EngagementContainer" */
  )
);

export const LoadableReviewFormTemplates = lazy(() =>
  import(
    "containers/Reviews/pages/ReviewForms/TemplateScreen" /* webpackChunkName: "TemplateScreen" */
  )
);

export const LoadableReviewee = lazy(() =>
  import(
    "containers/Reviews/pages/Reviewee/index" /* webpackChunkName: "EngagementContainer" */
  )
);

export const LoadableReviewers = lazy(() =>
  import(
    "containers/Reviews/pages/Reviewers/index" /* webpackChunkName: "EngagementContainer" */
  )
);

export const LoadableVerifyReviewDetails = lazy(() =>
  import(
    "containers/Reviews/pages/VerifyReviewDetails/index" /* webpackChunkName: "EngagementContainer" */
  )
);

export const LoadableSlackIntegrationCalendar = lazy(() =>
  import(
    "components/SlackIntegrationCalendar" /* webpackChunkName: "SlackIntegrationCalendar" */
  )
);

// Onboarding components

export const LoadableGoalsOnboarding = lazy(() =>
  import(
    "components/Onboarding/GoalsOnboarding" /* webpackChunkName: "GoalsOnboarding" */
  )
);

export const LoadablePerformanceNominatePeer = lazy(() =>
  import(
    "containers/PerformanceReviews/pages/NominatePeer" /* webpackChunkName: "NominatePeer" */
  )
);

export const LoadablePerformanceNominateApproveSelectedPeer = lazy(() =>
  import(
    "containers/PerformanceReviews/pages/ApprovePeers/ApprovePeersShow" /* webpackChunkName: "ApprovePeersShow" */
  )
);

export const LoadablePerformanceReviewRevieweesForReviewer = lazy(() =>
  import(
    "containers/PerformanceReviews/pages/RevieweesForReviewer" /* webpackChunkName: "RevieweesForReviewer" */
  )
);
export const LoadablePerformanceReviewRevieweesList = lazy(() =>
  import(
    "containers/PerformanceReviews/pages/RevieweesList" /* webpackChunkName: "RevieweesList" */
  )
);

export const LoadablePerformanceReviewRevieweeShow = lazy(() =>
  import(
    "containers/PerformanceReviews/pages/FilledReviewForms" /* webpackChunkName: "FilledReviewForms" */
  )
);

export const LoadablePerformanceReviewForm = lazy(() =>
  import(
    "containers/PerformanceReviews/pages/ReviewForm" /* webpackChunkName: "ReviewForm" */
  )
);
export const LoadablePerformanceReviewFormResult = lazy(() =>
  import(
    "containers/PerformanceReviews/pages/FilledReviewForms" /* webpackChunkName: "FilledReviewForms" */
  )
);
export const LoadablePerformanceReviewApproveGoals = lazy(() =>
  import(
    "containers/PerformanceReviews/pages/ApproveGoals" /* webpackChunkName: "ApproveGoals" */
  )
);
export const LoadablePerformanceReviewDefineGoals = lazy(() =>
  import(
    "containers/PerformanceReviews/pages/GoalManager" /* webpackChunkName: "GoalManager" */
  )
);

export const LoadablePerformanceApproveEmployeeGoals = lazy(() =>
  import(
    "containers/PerformanceReviews/pages/GoalManager" /* webpackChunkName: "GoalManager" */
  )
);

export const LoadableCompetencyManager = lazy(() =>
  import(
    "containers/PerformanceReviews/pages/CompetencyManager" /* webpackChunkName: "CompetencyManager" */
  )
);

export const LoadableApproveCompetency = lazy(() =>
  import(
    "containers/PerformanceReviews/pages/ApproveCompetency" /* webpackChunkName: "ApproveCompetency" */
  )
);

// KPI
export const LoadableKpiPage = lazy(() =>
  import("containers/KPI/index" /* webpackChunkName: "KPI" */)
);

export const LoadableWidget = lazy(() =>
  import("containers/ScreenshotWidget/index")
);

export const LoadableOKRUpdatesNotification = lazy(() =>
  import("containers/OKRUpdatesNotification")
);

export const LoadableOrgChart = lazy(() => import("containers/OrgChart"));

export const LoadableReports = lazy(() => import("containers/Reports/index"));

// Growth Path
export const LoadableEmployeeGrowthPath = lazy(() =>
  import(
    "containers/EmployeeGrowthPath/index" /* webpackChunkName: "EmployeeGrowthPath" */
  )
);
