import { EMPLOYEE_DATA_IMPORT_SUCCESS, STOP_POLLING_EMPLOYEE_IMPORT, START_POLLING_EMPLOYEE_IMPORT} from 'actions/constants';


const initialState = {
  id: null,
  uploadStatus: null,
  pollingStarted: false,
  addedCount: null,
  updatedCount: null,
  failureCount: null,
  s3FailedUrl: null,
  createdAt: null
}  

const employeeDataImport = (state = initialState , action) => {
  switch (action.type) {
    case EMPLOYEE_DATA_IMPORT_SUCCESS: {
      const newState = Object.assign({},state,{
        ...action.payload
      })
      return newState;
    }
    case START_POLLING_EMPLOYEE_IMPORT: {
      const newState = Object.assign({},state,{
        ...action.payload
      })
      return newState;
    }
    case STOP_POLLING_EMPLOYEE_IMPORT: {
      const newState = Object.assign({},state,{
        ...action.payload
      })
      return newState;
    }
    default:
      return state;
  }
};

export default employeeDataImport;
