import {
  GET_COLLECT_PRODUCTIVITY,
  UPDATE_COLLECT_PRODUCTIVIY,
  COLLECT_PRODUCTIVITY_DISPLAY_STATE,
} from "../../../actions/constants";

const initialState = {
  collect_productivity: null,
  showProductivityModal: false,
};

const collectProductivityDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_COLLECT_PRODUCTIVITY: {
      const newState = Object.assign({}, state, {
        collect_productivity: action.payload.collect_productivity,
        showProductivityModal: action.payload.showProductivityModal,
      });
      return newState;
    }

    case UPDATE_COLLECT_PRODUCTIVIY: {
      const newState = Object.assign({}, state, {
        collect_productivity: action.payload.collect_productivity,
        showProductivityModal: action.payload.showProductivityModal,
      });
      return newState;
    }

    case COLLECT_PRODUCTIVITY_DISPLAY_STATE: {
      const newState = Object.assign({}, state, {
        showProductivityModal: !state.showProductivityModal,
      });
      return newState;
    }

    default:
      return state;
  }
};

export default collectProductivityDetails;
