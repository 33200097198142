import React from "react";
import { Card as AntdCard } from "antd";

import "./index.scss";

export const Card = (props) => {
  return (
    <AntdCard {...props} className={`${props.className || ""} shadowCard`}>
      {props.children}
    </AntdCard>
  );
};

export const DeckCard = (props) => {
  return (
    <>
      <div style={{ display: "relative" }}>
        <AntdCard
          {...props}
          style={{
            ...props.style,
            position: "relative",
            width: "100%",
            zIndex: 999,
          }}
          className={`${props.className} shadowCard`}
        >
          {props.children}
        </AntdCard>
        <AntdCard
          style={{
            transform: "rotate(6.5deg)",
            position: "absolute",
            width: "80%",
            top: 24,
            zIndex: 990,
            height: 185,
          }}
          className="shadowCard"
        />
      </div>
    </>
  );
};

export const PlaneCard = (props) => {
  return (
    <AntdCard
      {...props}
      className={props.className ? `planeCard ${props.className}` : "planeCard"}
    >
      {props.children}
    </AntdCard>
  );
};
