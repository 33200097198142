export const SET_SELECTED_GOAL_ID = "SET_SELECTED_GOAL_ID";
export const SET_CURRENT_TIMELINE = "SET_CURRENT_TIMELINE";
export const SET_SELECTED_DEPARTMENT_ID = "SET_SELECTED_DEPARTMENT_ID";
export const UPDATE_BIZ_GOAL_DATA = "UPDATE_BIZ_GOAL_DATA";
export const SET_BIZ_GOAL_LOADING = "SET_BIZ_GOAL_LOADING";
export const SET_REFRESH_BIZ_REVIEW = "SET_REFRESH_BIZ_REVIEW";
export const SET_PREVIOUS_PAGE = "SET_PREVIOUS_PAGE";
export const RESET_BIZ_REVIEW_GOAL_DATA = "RESET_BIZ_REVIEW_GOAL_DATA";
export const SET_RCA_FORM_DATA = "SET_RCA_FORM_DATA";
export const SET_MOM_FORM_DATA = "SET_MOM_FORM_DATA";
export const SET_BIZ_CONFIG_LOADING = "SET_BIZ_CONFIG_LOADING";
export const SET_OWNER_DATA = "SET_OWNER_DATA";
export const SET_BIZ_REVIEW_CARD_DATA = "SET_BIZ_REVIEW_CARD_DATA";
export const SET_IS_BIZ_CARD_LIST_LOADING = "SET_IS_BIZ_CARD_LIST_LOADING";
export const SET_BIZ_CARD_DATA = "SET_BIZ_CARD_DATA";
export const SET_BIZ_CARD_LOADING = "SET_BIZ_CARD_LOADING";
export const SET_NOTE_HISTORY_CARD_ID = "SET_NOTE_HISTORY_CARD_ID";
export const SET_OWNER_ID_DATA = "SET_OWNER_ID_DATA";
export const CREATE_RCA_FORM_DATA = "CREATE_RCA_FORM_DATA";
export const UPDATE_RCA_FORM_DATA = "UPDATE_RCA_FORM_DATA";
export const SET_BIZ_STATUS = "SET_BIZ_STATUS";
export const SET_BIZ_STATUS_LOADING = "SET_BIZ_STATUS_LOADING";
export const SET_BIZ_OWNER_EMPTY_TEXT = "SET_BIZ_OWNER_EMPTY_TEXT";
export const SET_BIZ_OWNER_LOADING = "SET_BIZ_OWNER_LOADING";
export const SET_BIZ_OWNER_SEARCH_RESULT = "SET_BIZ_OWNER_SEARCH_RESULT";
export const CREATE_NEW_ACTION_ITEM = "CREATE_NEW_ACTION_ITEM";
export const UPDATE_BIZ_ACTION_ITEM = "UPDATE_BIZ_ACTION_ITEM";
export const DELETE_ACTION_ITEM = "DELETE_ACTION_ITEM";
export const BIZ_PRESENTATION_CARD_ORDER = "BIZ_PRESENTATION_CARD_ORDER";
export const RESET_SELECTED_GOAL_ID = "RESET_SELECTED_GOAL_ID";
export const SET_BIZ_WEEKLY_MONTHLY_DATA = "SET_BIZ_WEEKLY_MONTHLY_DATA";
export const SET_BIZ_WEEKLY_MONTHLY_LOADING_KEY =
  "SET_BIZ_WEEKLY_MONTHLY_LOADING_KEY";
export const RESET_BIZ_WEEKLY_MONTHLY_LOADING_KEY =
  "RESET_BIZ_WEEKLY_MONTHLY_LOADING_KEY";
export const SET_IS_CREATING_BIZ_TEXT_PANEL = "SET_IS_CREATING_BIZ_TEXT_PANEL";
export const SET_IS_DELETING_BIZ_REVIEW_CARD =
  "SET_IS_DELETING_BIZ_REVIEW_CARD";
export const ISFROMLISTINGPAGE = "ISFROMLISTINGPAGE";
export const SET_BIZ_GOAL_NODE_TREE_DATA = "SET_BIZ_GOAL_NODE_TREE_DATA";
export const SELECTED_COMMENT_CARD_ID = "SELECTED_COMMENT_CARD_ID";
export const UPDATE_BIZ_CARD_COMMENT_COUNT = "UPDATE_BIZ_CARD_COMMENT_COUNT";
export const UPDATE_BIZ_CARD_TITLE = "UPDATE_BIZ_CARD_TITLE";
export const UPDATE_BIZ_CARD_QUALITATIVE_UPDATE =
  "UPDATE_BIZ_CARD_QUALITATIVE_UPDATE";
export const SET_CARD_DATA = "SET_CARD_DATA";
export const RESET_BIZ_WEEKLY_MONTHLY = "RESET_BIZ_WEEKLY_MONTHLY";
export const SET_KPI_CARD_VIEW_TYPE = "SET_KPI_CARD_VIEW_TYPE";
export const SET_CONTEXTUAL_COMMENTS = "SET_CONTEXTUAL_COMMENTS";
export const SET_CONTEXTUAL_COMMENT_THREAD = "SET_CONTEXTUAL_COMMENT_THREAD";
// Updates Card Menu Dropdown Config
export const UPDATE_CARD_MENU_CONFIG = "UPDATE_CARD_MENU_CONFIG";
export const FLUSH_SELECTED_GOAL_ID = "FLUSH_SELECTED_GOAL_ID";
export const RESET_OWNER_ID_DATA = "RESET_OWNER_ID_DATA";
export const SET_BIZ_OKR_TOGGLE_STATE = "SET_BIZ_OKR_TOGGLE_STATE";

export const SET_SELECTED_BIZ_CHECKIN_CELL = "SET_SELECTED_BIZ_CHECKIN_CELL";
export const RESET_SELECTED_BIZ_CHECKIN_CELL =
  "RESET_SELECTED_BIZ_CHECKIN_CELL";

// Custom column
export const SET_COLUMN_LIST = "SET_COLUMN_LIST";
export const SET_COLUMN_CELL_VALUE = "SET_COLUMN_CELL_VALUE";
export const SET_CUSTOM_COLUMN_TITLE = "SET_CUSTOM_COLUMN_TITLE";
export const DELETE_CUSTOM_COLUMN = "DELETE_CUSTOM_COLUMN";
