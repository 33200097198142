import { DEPARTMENT_INSIGHTS_REQUEST } from '../../actions/constants'; 

const initialState = {
  insights : null
}

const departmentInsights = (state = initialState , action) => {
  switch (action.type) {
    case DEPARTMENT_INSIGHTS_REQUEST: {
      const newState = Object.assign({},state,{
        insights : action.payload
      })
      return newState;
    }
    default:
      return state;
  }
};
  
export default departmentInsights;
  