import React from "react";

import { ReactComponent as ErrorComputer } from "resources/icons/error-computer.svg";

import "./LoadableErrorMessage.scss";

const LoadableErrorMessage = () => {
  return (
    <div className="w-100 h-v flex flex-col align-center justify-center">
      <ErrorComputer />
      <span className="fs-plus fw-600 mb-20">Oops!</span>

      <span className="fs-sdefault fw-500 mb-20">
        Something went wrong. Please try again.
      </span>
      <div
        onClick={() => {
          window.location.reload();
        }}
        className="px-7 py-10 b-r-regular cursor-pointer flex align-center justify-center loadable-error-message-button"
      >
        <span className="color-white fs-smedium fw-500">Try Again</span>
      </div>
    </div>
  );
};

export default LoadableErrorMessage;
