import { SET_OPEN_REPLAY_TRACKER } from "../actions/constants";

const initialState = {
  openReplay: null,
};

const trackers = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPEN_REPLAY_TRACKER: {
      const newState = {
        ...state,
        openReplay: action.payload,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default trackers;
