import { GET_TALKING_POINTS_TEMPLATES , FILTER_TEMPLATE_LIST } from '../../actions/constants'; 

const talkingPointTemplates = (state = {} , action) => {
  switch (action.type) {
    case GET_TALKING_POINTS_TEMPLATES : {
      const newState = Object.assign({},state,{
        ...action.payload 
      })
      return newState;
    }

    case FILTER_TEMPLATE_LIST : {
      const newState = Object.assign({},state,{ 
        ...state, ...action.payload
      })
      return newState;
    }

    default:
      return state;
  }
};
  
export default talkingPointTemplates;
  