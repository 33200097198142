import axios from "axios";
import headers from "actions/headers.js";
import { API_ROOT } from "config/api-config.js";
import { UPDATE_CALENDAR_ACCESS_CHECK } from "actions/constants";
import { notification } from "antd";
import { getCalenderDataPolling } from "../CalenderItemsList";
import { oneOneOneListPolling } from "actions/OneOnOne/OneOnOneListPagePolling";

export const updateCalendarAccess = (authData, origin) => {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const requestUrl = "setup_calendar";

      const { userId } =
        (authData?.user?.userId && authData.user) || getState().user;

      axios
        .post(`${API_ROOT}/users/${userId}/${requestUrl}`, authData, headers())
        .then((response) => {
          resolve(response);
          if (!dispatch) {
            return;
          }

          const callFromRefresh = false;
          if (origin === "modal") {
            dispatch(getCalenderDataPolling(callFromRefresh));
          } else {
            dispatch(oneOneOneListPolling());
          }

          dispatch(updateCalendarAccessSuccess());
        })
        .catch((error) => {
          reject(error);

          notification.error({
            message: `Error`,
            description: error.response.data.error,
          });
        });
    });
};

export const updateCalendarAccessSuccess = () => {
  return {
    type: UPDATE_CALENDAR_ACCESS_CHECK,
    payload: {
      calendarAccess: true,
    },
  };
};
