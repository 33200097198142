import { SET_TEMPLATES, CLEAR_TEMPLATES } from "../../actions/constants";

const initialState = {
  templates: [],
};

const superList = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEMPLATES: {
      const newState = Object.assign({}, state, {
        templates: action.payload,
      });
      return newState;
    }

    case CLEAR_TEMPLATES: {
      const newState = Object.assign({}, state, {
        templates: action.payload,
      });
      return newState;
    }

    default:
      return state;
  }
};

export default superList;
