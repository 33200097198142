import {
  GET_CALENDER_ITEMS,
  HANDLE_CHECKED_EVENTS_IDS,
  EMPTY_CALENDER_STATE,
  SET_SYNCING_STATUS,
  CALENDAR_TIMER_STATUS,
} from "../../actions/constants";

const initialState = {
  events_list: [],
  checked_event_ids: [],
  data_hash: [],
  syncing_calender_items: false,
  zero_state: false,
  calendar_data_fetch_complete: false,
  last_updated_at: null,
  calendar_timer_cleared: true,
};

const calenderItems = (state = initialState, action) => {
  switch (action.type) {
    case GET_CALENDER_ITEMS: {
      const newState = Object.assign({}, state, {
        ...action.payload,
      });
      return newState;
    }

    case HANDLE_CHECKED_EVENTS_IDS: {
      const newState = Object.assign({}, state, {
        checked_event_ids: action.payload.checked_event_ids,
      });
      return newState;
    }

    case EMPTY_CALENDER_STATE: {
      const newState = Object.assign({}, state, {
        events_list: action.payload.events_list,
        checked_event_ids: action.payload.checked_event_ids,
        data_hash: action.payload.data_hash,
        syncing_calender_items: action.payload.syncing_calender_items,
        zero_state: action.payload.zero_state,
        calendar_data_fetch_complete:
          action.payload.calendar_data_fetch_complete,
        last_updated_at: null,
      });
      return newState;
    }

    case SET_SYNCING_STATUS: {
      const newState = Object.assign({}, state, {
        syncing_calender_items: action.payload.syncing_calender_items,
      });
      return newState;
    }

    case CALENDAR_TIMER_STATUS: {
      const newState = Object.assign({}, state, {
        ...action.payload,
      });
      return newState;
    }

    default:
      return state;
  }
};

export default calenderItems;
