import { GET_GOALS_ALIGNMENT_LIST } from "actions/constants";

const initialState = [];

const goalAlignmentList = (state = initialState, action) => {
  switch (action.type) {
    case GET_GOALS_ALIGNMENT_LIST: {
      const newState = [...action.payload];
      return newState;
    }

    default:
      return state;
  }
};

export default goalAlignmentList;
