import {
  GET_USER_GOALS_CYCLE_LIST,
  GET_GOALS_CYCLE_IDS_OBJECT_LIST,
  // GET_GOALS_CYCLE_UPDATED_LIST,
} from "actions/constants";

const initialState = {
  initialCycleList: {},
};

const goalsCycleList = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_GOALS_CYCLE_LIST: {
      const newState = Object.assign({}, state, {
        initialCycleList: { ...action.payload },
      });
      return newState;
    }

    // case GET_GOALS_CYCLE_UPDATED_LIST: {
    //   const { activeGoaltype, activeGoaltypeId } = action.payload;

    //   // const { activeGoaltype, activeGoaltypeId } = action.payload.data;
    //   let stateObject;

    //   if (action.payload.data?.allGoal) {
    //     stateObject = Object.assign({}, state, {
    //       [activeGoaltype]: {
    //         ...state[activeGoaltype],
    //         [activeGoaltypeId]: {
    //           checkedActiveBoxes: [
    //             ...new Set([
    //               ...(state[activeGoaltype][activeGoaltypeId]
    //                 ?.checkedActiveBoxes
    //                 ? state[activeGoaltype][activeGoaltypeId].checkedActiveBoxes
    //                 : []),
    //             ]),
    //           ],
    //           checkedUpcomingBoxes: [
    //             ...new Set([
    //               ...(state[activeGoaltype][activeGoaltypeId]
    //                 ?.checkedUpcomingBoxes
    //                 ? state[activeGoaltype][activeGoaltypeId]
    //                     .checkedUpcomingBoxes
    //                 : []),
    //             ]),
    //           ],
    //           checkedPreviousBoxes: [
    //             ...new Set([
    //               ...(state[activeGoaltype][activeGoaltypeId]
    //                 ?.checkedPreviousBoxes
    //                 ? state[activeGoaltype][activeGoaltypeId]
    //                     .checkedPreviousBoxes
    //                 : []),
    //             ]),
    //           ],
    //           allGoal: action.payload.data.allGoal,
    //         },
    //       },
    //     });
    //   } else {
    //     stateObject = Object.assign({}, state, {
    //       [activeGoaltype]: {
    //         ...state[activeGoaltype],
    //         [activeGoaltypeId]: {
    //           checkedActiveBoxes: [
    //             ...new Set([...action.payload.data.checkedActiveBoxes]),
    //           ],
    //           checkedUpcomingBoxes: [
    //             ...new Set([...action.payload.data.checkedUpcomingBoxes]),
    //           ],
    //           checkedPreviousBoxes: [
    //             ...new Set([...action.payload.data.checkedPreviousBoxes]),
    //           ],
    //         },
    //       },
    //     });
    //   }
    //   const newState = { ...stateObject };
    //   return newState;
    // }
    default:
      return state;
  }
};

const goalsCycleIdsObjectList = (state = {}, action) => {
  switch (action.type) {
    case GET_GOALS_CYCLE_IDS_OBJECT_LIST: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

// export { goalsCycleList, goalsCycleStateList, goalsCycleIdsObjectList };
export { goalsCycleList, goalsCycleIdsObjectList };
