import {
  GET_GOAL_DRAWER_ACTIVITIES,
  UPDATE_GOAL_DRAWER_ACTVITIES,
  RESET_GOAL_ACTVITIES_DRAWER,
  UPDATE_GOAL_ACTIVITIES_COMMENTS_DRAFT,
  UPDATE_GOAL_ACTIVITIES_CHECKIN_DRAFT,
  UPDATE_EDIT_GOAL_ACTIVITY_DATA,
  DELETE_DRAWER_ACTIVITY_MESSAGE_DATA,
} from "actions/constants";

const initialState = {
  goalsActivities: null,
  goalsCommentDraft: {},
  goalsCheckinDraft: {},
};

const goalActivitiesDrawer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GOAL_DRAWER_ACTIVITIES: {
      const newState = { ...state };
      newState.goalsActivities = action.payload;
      return newState;
    }

    case UPDATE_GOAL_DRAWER_ACTVITIES: {
      const newState = { ...state };
      const latestActivity = action.payload;
      latestActivity.latest = true;

      if (newState.goalsActivities) {
        if (newState.goalsActivities.length) {
          newState.goalsActivities = newState.goalsActivities.map(
            (activityObject) => {
              if (activityObject?.latest) {
                delete activityObject.latest;
              }
              return activityObject;
            }
          );
        }
        newState.goalsActivities.push(latestActivity);
      } else {
        newState.goalsActivities = [latestActivity];
      }
      return newState;
    }

    case RESET_GOAL_ACTVITIES_DRAWER: {
      return initialState;
    }

    case UPDATE_GOAL_ACTIVITIES_COMMENTS_DRAFT: {
      const { goalId, comment } = action.payload;
      const newState = { ...state };
      newState.goalsCommentDraft[goalId] = comment;
      return newState;
    }

    case UPDATE_GOAL_ACTIVITIES_CHECKIN_DRAFT: {
      const { goalId, description } = action.payload;
      const newState = { ...state };
      newState.goalsCheckinDraft[goalId] = description;
      return newState;
    }

    case UPDATE_EDIT_GOAL_ACTIVITY_DATA: {
      const { newActivityResponse, activityId } = action.payload;
      const updatedActivitiesArray = state.goalsActivities
        ? [...state.goalsActivities]
        : [];
      const newState = { ...state };
      for (let i = 0; i < updatedActivitiesArray?.length; i++) {
        if (updatedActivitiesArray[i]?.id === activityId) {
          updatedActivitiesArray[i] = { ...newActivityResponse?.goal_activity };
          break;
        }
      }
      newState.goalsActivities = updatedActivitiesArray;
      return newState;
    }

    case DELETE_DRAWER_ACTIVITY_MESSAGE_DATA: {
      const { activityId } = action.payload;
      const updatedActivitiesArray = [...state?.goalsActivities];
      const newState = { ...state };
      for (var i = 0; i < updatedActivitiesArray.length; i++) {
        var obj = updatedActivitiesArray[i];
        if (obj.id === activityId) {
          updatedActivitiesArray.splice(i, 1);
          break;
        }
      }
      newState.goalsActivities = updatedActivitiesArray;
      return newState;
    }

    default:
      return state;
  }
};

export default goalActivitiesDrawer;
