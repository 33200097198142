import { GET_PULSE_DETAILS , UPDATE_PULSE_STATUS} from '../../actions/constants'; 

const initialState = {
  pulse: null
}

const pulseDetails = (state = initialState , action) => {
  switch (action.type) {
    case GET_PULSE_DETAILS : {
      const newState = Object.assign({},state,{
        pulse : action.payload.pulse,
      })
      return newState;
    }
    case UPDATE_PULSE_STATUS : {
      const newState = Object.assign({}, state, {
        pulse: {...state.pulse, status: action.payload.pulse.status}
      })
      return newState;
    }
    default:
      return state;
  }
};
  
export default pulseDetails;
  