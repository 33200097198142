import axios from "axios";
import { API_ROOT } from "config/api-config";

export const WebService = axios.create({
  baseURL: API_ROOT,
  headers: {
    "Content-Type": "application/json",
    "X-Impersonator-Auth-token": localStorage.getItem(
      "impersonator_auth_token"
    ),
  },
});
