import {
  GET_DEPARTMENTS_LIST_SUCCESS,
  ADD_NEW_DEPARTMENT,
  DELETE_DEPARTMENT,
  SAVE_DEPARTMENT,
  OPEN_DEPARTMENT_MODAL,
} from "actions/constants";

const initialState = {
  departmentListObject: null,
  departmentListIdsArray: null,
  isOpenModal: false,
};

const departmentsList = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPARTMENTS_LIST_SUCCESS: {
      const newState = Object.assign({}, state, {
        ...action.payload,
      });
      return newState;
    }
    case ADD_NEW_DEPARTMENT: {
      const newState = Object.assign({}, state, {
        departmentListObject: {
          ...action.payload.objectList,
          ...state.departmentListObject,
        },
        departmentListIdsArray: [
          ...action.payload.arrayOfKeys,
          ...state.departmentListIdsArray,
        ],
      });
      return newState;
    }
    case DELETE_DEPARTMENT: {
      const { [action.payload.id]: omit, ...existingObjects } =
        state.departmentListObject;
      const newState = {
        ...state,
        departmentListObject: existingObjects,
        departmentListIdsArray: state.departmentListIdsArray.filter(
          (id) => id !== action.payload.id
        ),
      };
      return newState;
    }
    case SAVE_DEPARTMENT: {
      const newState = Object.assign({}, state, {
        departmentListObject: {
          ...state.departmentListObject,
          ...action.payload.department,
        },
      });
      return newState;
    }
    case OPEN_DEPARTMENT_MODAL: {
      return {
        ...state,
        isOpenModal: action.payload,
      };
    }
    default:
      return state;
  }
};

export default departmentsList;
