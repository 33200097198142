import {
  GET_FRONTEND_BRANCH,
  GET_BACKEND_BRANCH,
  GET_DEPLOYMENT_VISIBILITY
} from "actions/constants";

import { uniq } from "lodash";

const initialState = {
  frontendBranch: "",
  backendBranch: "",
  environment: "",
  visible: true
};

const deploymentInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_FRONTEND_BRANCH:
    case GET_BACKEND_BRANCH:
    case GET_DEPLOYMENT_VISIBILITY: {
      const newState = Object.assign({}, state, action.payload);
      return newState;
    }
    
    default:
      return state;
  }
};

export default deploymentInfo;
