const initialState = {
  isFetchingCompetencies: false,
  isFetchingCompetencyMatrix: false,
  competencies: [],
  competencyDetails: [],
  competencyMatrix: [],
  idpTeams: [],
  idpTracks: {},
};

const competency = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_IS_FETCHING_COMPETENCIES": {
      return {
        ...state,
        isFetchingCompetencies: !state.isFetchingCompetencies,
      };
    }
    case "TOGGLE_IS_FETCHING_COMPETENCY_MATRIX": {
      return {
        ...state,
        isFetchingCompetencyMatrix: !state.isFetchingCompetencyMatrix,
      };
    }
    case "SET_COMPETENCIES": {
      return {
        ...state,
        competencies: action.payload,
      };
    }
    case "SET_COMPETENCY_DETAILS": {
      return {
        ...state,
        competencyDetails: action.payload,
      };
    }
    case "SET_IDP_TEAMS": {
      return {
        ...state,
        idpTeams: action.payload,
      };
    }
    case "SET_IDP_TRACKS": {
      return {
        ...state,
        idpTracks: {
          ...state.idpTracks,
          [action.payload.id]: action.payload.data,
        },
      };
    }
    case "SET_COMPETENCY_MATRIX": {
      return {
        ...state,
        competencyMatrix: action.payload,
      };
    }
    default:
      return state;
  }
};

export default competency;
