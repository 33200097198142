import React, { Component } from "react";
import { Router, Route } from "react-router-dom";
import history from "../history";
import { connect } from "react-redux";
import {
  LoadableLogin,
  LoadableDashboard,
  LoadableEmployeeMessaging,
  LoadableSetPassword,
  LoadableAppSumo,
  LoadableDesignSystem,
  LoadableSSOLogin,
  LoadableWidget,
} from "../Loadable";
import LoadableErrorBoundary from "../Loadable/components/LoadableErrorBoundary/LoadableErrorBoundary";
import { Loader } from "components/Loader";

import PageNavigate from "./pageNavigate";
import { PageView, initGA, initAhoy, initPostHog } from "../Tracking";
import { APP_ENV } from "../config/api-config";
import Chatwoot from "containers/Chatwoot";
import Wootric from "components/Wootric";
import SlackIntegrationCalendar from "components/SlackIntegrationCalendar";

import {
  initOpenReplayTracker,
  startOpenReplayTracker,
  setOpenReplayUserId,
  setOpenReplayMetadata,
} from "actions/tracker";

class Root extends Component {
  getMetaData = () => {
    const currentTime = new Date();
    return {
      url: window.location.href,
      role: this?.props?.user?.employee_details?.org_role,
      accountId: this.props.user.accountId,
      email: this.props.user.email,
      employeeId: this.props.user.employeeId,
      name: this?.props?.user?.employee_details?.full_name,
      time: currentTime.toTimeString(),
    };
  };

  componentDidMount() {
    initGA();
    PageView();
    if (APP_ENV === "PRODUCTION" || APP_ENV === "DEMO") {
      this.props.dispatchInitOpenReplayTracker();
      this.props.startOpenReplayTracker();
    }
  }

  componentDidUpdate(prevProps, nextProps) {
    const { employeeName, userEmail, companyName } = this.props.user;
    const { setOpenReplayUserId, setOpenReplayMetadata } = this.props;
    if (
      employeeName &&
      userEmail &&
      companyName &&
      setOpenReplayUserId &&
      setOpenReplayMetadata
    ) {
      this.props.setOpenReplayUserId(userEmail);
      this.props.setOpenReplayMetadata({
        metaDataType: "name",
        metaDataValue: employeeName,
      });
      this.props.setOpenReplayMetadata({
        metaDataType: "company",
        metaDataValue: companyName,
      });
      this.props.setOpenReplayMetadata({
        metaDataType: "environment",
        metaDataValue: APP_ENV,
      });
    }
  }

  renderAhoyEvents = () => {
    const ahoy = initAhoy();
    ahoy.trackView();
  };

  renderPostHog = () => {
    initPostHog(this.props.user);
  };

  renderChatwoot = () => {
    return <Chatwoot {...this.props.user} />;
  };

  ScrollToTop = () => {
    window.scrollTo(0, 0);
    return null;
  };

  render() {
    return (
      <>
        {APP_ENV === "PRODUCTION" &&
          this.props.user.employee_details?.user.email &&
          this.props.user.employee_details?.created_at &&
          this.props.user.loginCount > 14 && (
            <Wootric
              email={this.props.user.employee_details.user.email}
              created_at={this.props.user.employee_details.created_at}
            />
          )}

        {this.renderChatwoot()}
        {this.renderAhoyEvents()}
        {this.renderPostHog()}
        <LoadableErrorBoundary getMetaData={this.getMetaData}>
          <React.Suspense fallback={<Loader />}>
            <Router
              onUpdate={() => window.scrollTo(0, 0)}
              component={this.ScrollToTop}
              history={history}
            >
              <PageNavigate>
                <Route exact path="/" component={LoadableLogin} />
                <Route exact path="/signup" component={LoadableLogin} />
                <Route
                  exact
                  path="/analytics/signup"
                  component={LoadableLogin}
                />
                <Route exact path="/appsumo" component={LoadableAppSumo} />
                <Route exact path="/widget" component={LoadableWidget} />
                <Route
                  exact
                  path="/sso/7/assert"
                  component={LoadableSSOLogin}
                />
                <Route
                  exact
                  path="/appsumo/:couponCode"
                  component={LoadableAppSumo}
                />
                <Route path="/dashboard" component={LoadableDashboard} />
                <Route
                  path="/anonymous/:accountId/:responseId"
                  component={LoadableEmployeeMessaging}
                />
                <Route path="/set_password" component={LoadableSetPassword} />
                <Route
                  path="/peoplebox-design-system"
                  component={LoadableDesignSystem}
                />
                <Route
                  path="/coffee_connect_integration/auth"
                  component={SlackIntegrationCalendar}
                />
              </PageNavigate>
            </Router>
          </React.Suspense>
        </LoadableErrorBoundary>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  openReplay: state.trackers.openReplay,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchInitOpenReplayTracker: (tracker) =>
      dispatch(initOpenReplayTracker(tracker)),
    startOpenReplayTracker: () => dispatch(startOpenReplayTracker()),
    setOpenReplayUserId: (UserEmailOrId) =>
      dispatch(setOpenReplayUserId(UserEmailOrId)),
    setOpenReplayMetadata: (payload) =>
      dispatch(setOpenReplayMetadata(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
