// loading constants

export const SET_ENPS_INSIGHT_LOADING = "SET_ENPS_INSIGHT_LOADING";
export const SET_ENGAGEMENT_INSIGHT_LOADING = "SET_ENGAGEMENT_INSIGHT_LOADING";
export const SET_MANAGER_INSIGHT_LOADING = "SET_MANAGER_INSIGHT_LOADING";
export const SET_PARTICIPATION_INSIGHT_LOADING =
  "SET_PARTICIPATION_INSIGHT_LOADING";

// data constants

export const SET_ENPS_INSIGHT_DETAILS = "SET_ENPS_INSIGHT_DETAILS";
export const SET_ENGAGEMENT_INSIGHT_DETAILS = "SET_ENGAGEMENT_INSIGHT_DETAILS";
export const SET_MANAGER_INSIGHT_DETAILS = "SET_MANAGER_INSIGHT_DETAILS";
export const SET_PARTICIPATION_INSIGHT_DETAILS =
  "SET_PARTICIPATION_INSIGHT_DETAILS";
