import {
  GET_KEY_RESULTS_IDS_LIST,
  ADD_KEY_RESULT_ID,
  DELETE_KEY_RESULT_ARRAY,
  UPDATE_GOAL_PARENT_NODE,
  DELETE_KEY_RESULT_ID,
  UPDATE_KEY_RESULT_ID_LIST,
  UPDATE_GOALS_CHILDREN_LIST,
} from "actions/constants";

const initialState = {};

const GoalskeyResultIdsList = (state = initialState, action) => {
  switch (action.type) {
    case GET_KEY_RESULTS_IDS_LIST: {
      const newState = { ...state, ...action.payload };
      Object.keys(newState).forEach((key) => {
        const uniqueIds = [
          ...new Set(newState[key].map((id) => id.toString())),
        ];
        newState[key] = uniqueIds;
      });
      return newState;
    }

    case ADD_KEY_RESULT_ID: {
      const { parentId, id } = action.payload;
      let updatedState = { [parentId]: [id] };
      if (state[parentId]) {
        updatedState = { [parentId]: [...state[parentId], id] };
      }
      const newState = {
        ...state,
        ...updatedState,
      };
      return newState;
    }

    case UPDATE_KEY_RESULT_ID_LIST: {
      const { parentId, keyResultIdList } = action.payload;
      return {
        ...state,
        [parentId]: keyResultIdList,
      };
    }

    case DELETE_KEY_RESULT_ID: {
      const { parentId, id } = action.payload;
      return {
        ...state,
        [parentId]: state[parentId].filter((goalId) => goalId !== id),
      };
    }

    case DELETE_KEY_RESULT_ARRAY: {
      const { deleteKey } = action.payload;
      const newState = { ...state };
      delete newState[deleteKey];
      return newState;
    }

    case UPDATE_GOAL_PARENT_NODE: {
      const { oldParentGoalIds, newParentGoalIds, childGoalId } =
        action.payload;
      let oldParentObj = {};
      let newParentObj = {};
      oldParentGoalIds.forEach((oldParentGoalId) => {
        if (state[oldParentGoalId]) {
          const oldParentArray = state[oldParentGoalId].filter(
            (ids) => ids !== childGoalId
          );
          oldParentObj = { ...oldParentObj, [oldParentGoalId]: oldParentArray };
        }
      });
      newParentGoalIds.forEach((newParentGoalId) => {
        if (state[newParentGoalId]) {
          newParentObj = {
            ...newParentObj,
            [newParentGoalId]: [...state[newParentGoalId], childGoalId],
          };
        }
      });
      const newState = {
        ...state,
        ...newParentObj,
        ...oldParentObj,
      };
      return newState;
    }

    case UPDATE_GOALS_CHILDREN_LIST: {
      const { goalId, goalChildrenList } = action.payload;

      return {
        ...state,
        [goalId]: goalChildrenList,
      };
    }

    default:
      return state;
  }
};

export default GoalskeyResultIdsList;
