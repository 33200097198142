import {
  GET_GOALS_FILTER_LIST,
  ADD_GOAL_FILTER,
  REMOVE_GOAL_FILTER,
  RE_ORDER_GOALS_FILTER,
  SET_GOAL_FILTER_LOADING,
  ADD_GOAL_FILTER_TO_TOP,
} from "actions/constants";

const initialState = {
  goalFilters: {},
  goalFiltersLoading: false,
};

const goalsFilterList = (state = initialState, action) => {
  switch (action.type) {
    case GET_GOALS_FILTER_LIST: {
      const newState = Object.assign({}, state, {
        goalFilters: action.payload,
      });
      return newState;
    }

    case SET_GOAL_FILTER_LOADING: {
      return {
        ...state,
        goalFiltersLoading: action.payload,
      };
    }
    case ADD_GOAL_FILTER: {
      const { goalType, data } = action.payload;
      let newGoalTypeData = { [goalType]: data };
      if (state.goalFilters[goalType]) {
        const newGoalType = [...data, ...state.goalFilters[goalType]];
        newGoalTypeData = {
          [goalType]: newGoalType,
        };
      }
      const newState = Object.assign({}, state, {
        goalFilters: {
          ...state.goalFilters,
          ...newGoalTypeData,
        },
      });
      return newState;
    }
    case ADD_GOAL_FILTER_TO_TOP: {
      const { goalType, goalFilterId } = action.payload;
      let filteredItem;
      const filteredData = state.goalFilters[goalType].filter((item) => {
        if (+item.filter_id === +goalFilterId) {
          filteredItem = item;
        }
        return item.filter_id !== goalFilterId;
      });
      const newState = Object.assign({}, state, {
        goalFilters: {
          ...state.goalFilters,
          [goalType]: [filteredItem, ...filteredData],
        },
      });
      return newState;
    }
    case REMOVE_GOAL_FILTER: {
      const { goalType, goalFilterId } = action.payload;
      const newState = Object.assign({}, state, {
        goalFilters: {
          ...state.goalFilters,
          [goalType]: state.goalFilters[goalType].filter(
            (item) => item.filter_id !== goalFilterId
          ),
        },
      });
      return newState;
    }
    case RE_ORDER_GOALS_FILTER: {
      const newFilterState = action.payload;
      const newState = Object.assign({}, state, {
        goalFilters: newFilterState,
      });
      return newState;
    }
    default:
      return state;
  }
};

export default goalsFilterList;
