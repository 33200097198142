import { WebService } from "./webService";

export const setBearerTokenToHeader = (token) => {
  if (token) {
    localStorage.setItem("dashboard_auth_token", token);
    WebService.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete WebService.defaults.headers.common.Authorization;
  }
};

export const setXImpersonatorTokenToHeader = (token) => {
  if (token) {
    localStorage.setItem("impersonator_auth_token", token);
    WebService.defaults.headers.common["X-Impersonator-Auth-Token"] = token;
  } else {
    localStorage.removeItem("impersonator_auth_token");
    delete WebService.defaults.headers.common["X-Impersonator-Auth-Token"];
  }
};
