import { GET_REPORTEES , DELETE_REPORTEES , UPDATE_REPORTEES_DETAILS_SUCCESS } from '../../actions/constants'; 

const initialState = {
  reportees : null
}

const reporteesDetail = (state = initialState , action) => {
  switch (action.type) {
    case GET_REPORTEES : {
      const newState = Object.assign({},state,{
        reportees : action.payload.reportees
      })
      return newState;
    }

    case DELETE_REPORTEES : {
      const newState = Object.assign({},state,{
        reportees : action.payload
      })
      return newState;
    }

    case UPDATE_REPORTEES_DETAILS_SUCCESS : {
      const newState = Object.assign({},state,{
        reportees : action.payload
      })
      return newState;
    }

    default:
      return state;
    }
};
  
export default reporteesDetail;
  