import {
  GET_GOALS_CYCLE_UPDATED_LIST,
  GET_USER_SPECIFIC_CYCLE_LIST,
  GET_USER_SPECIFIC_CYCLE_IDS_OBJECT_LIST,
  SET_ALL_CYCLES,
  SET_GOAL_CYCLES_LOADING,
} from "actions/constants";

const initialState = {
  initialCycleList: {},
  employees: {},
  departments: {},
  account: {},
  loading: true,
};

const goalsUserSpecificCycleList = (state = initialState, action) => {
  switch (action.type) {
    case SET_GOAL_CYCLES_LOADING: {
      const { loading } = action.payload;
      return {
        ...state,
        loading: loading,
      };
    }
    case GET_USER_SPECIFIC_CYCLE_LIST: {
      const { data, goalTypeId } = action.payload;

      return {
        ...state,
        initialCycleList: {
          ...state.initialCycleList,
          [goalTypeId]: data,
        },
      };
    }
    case GET_GOALS_CYCLE_UPDATED_LIST: {
      const { activeGoaltype, activeGoaltypeId, data } = action.payload;

      let stateObject;

      if (action.payload.data?.allGoal) {
        stateObject = Object.assign({}, state, {
          [activeGoaltype]: {
            ...state[activeGoaltype],
            [activeGoaltypeId]: {
              checkedActiveBoxes: [
                ...new Set([
                  ...(state[activeGoaltype][activeGoaltypeId]
                    ?.checkedActiveBoxes
                    ? state[activeGoaltype][activeGoaltypeId].checkedActiveBoxes
                    : []),
                ]),
              ],
              checkedUpcomingBoxes: [
                ...new Set([
                  ...(state[activeGoaltype][activeGoaltypeId]
                    ?.checkedUpcomingBoxes
                    ? state[activeGoaltype][activeGoaltypeId]
                        .checkedUpcomingBoxes
                    : []),
                ]),
              ],
              checkedPreviousBoxes: [
                ...new Set([
                  ...(state[activeGoaltype][activeGoaltypeId]
                    ?.checkedPreviousBoxes
                    ? state[activeGoaltype][activeGoaltypeId]
                        .checkedPreviousBoxes
                    : []),
                ]),
              ],
              allGoal: action.payload.data.allGoal,
            },
          },
        });
      } else {
        stateObject = Object.assign({}, state, {
          [activeGoaltype]: {
            ...state[activeGoaltype],
            [activeGoaltypeId]: {
              checkedActiveBoxes: [
                ...new Set([
                  ...(data?.checkedActiveBoxes ? data?.checkedActiveBoxes : []),
                ]),
              ],
              checkedUpcomingBoxes: [
                ...new Set([
                  ...(data?.checkedUpcomingBoxes
                    ? data?.checkedUpcomingBoxes
                    : []),
                ]),
              ],
              checkedPreviousBoxes: [
                ...new Set([
                  ...(data?.checkedPreviousBoxes
                    ? data.checkedPreviousBoxes
                    : []),
                ]),
              ],
            },
          },
        });
      }
      const newState = { ...stateObject };
      return newState;
    }
    case SET_ALL_CYCLES: {
      const { cycleName, value } = action.payload;
      window.sessionStorage.setItem(
        `${cycleName}CycleAll`,
        JSON.stringify(value)
      );
      return {
        ...state,
        [cycleName]: value,
      };
    }
    default:
      return state;
  }
};

const goalsSpecificIdsObjectList = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_SPECIFIC_CYCLE_IDS_OBJECT_LIST: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export { goalsUserSpecificCycleList, goalsSpecificIdsObjectList };
