import { SCORE_REQUEST } from '../../actions/constants'; 

const initialState = {
  keyMetrics : null 
}

const keyMetrics = (state = initialState , action) => {
  switch (action.type) {
    case SCORE_REQUEST: {
      const newState = Object.assign({},state,{
        keyMetrics : action.payload
      })
      return newState;
    }
    default:
      return state;
  }
};
  
export default keyMetrics;
  