import { GET_MANAGERS_LIST_SUCCESS } from 'actions/constants';

const initialState = {
  managersListObject: null,
  managersListIdsArray: null
}  

const managersList = (state = initialState , action) => {
  switch (action.type) {
    case GET_MANAGERS_LIST_SUCCESS: {
      const newState = Object.assign({},state,{
        ...action.payload
      })
      return newState;
    }
    default:
      return state;
  }
};

export default managersList;
