import { ADD_NEW_TODO , GET_TODO_ITEMS , FLUSH_COMPLETED_ITEMS ,  SHOW_COMPLETED_ITEMS , GET_UPCOMING_ACTIVITIES ,HIDE_COMPLETED_ITEMS , COMPLETED_ITEMS_ID} from '../../actions/constants'; 

const initialState = {
  super_list_items : null,
  page : 1,
  show_more_content : 'View More',
  upcoming_activities : null,
  completed_items_id : [],
  super_list_with_hidden_items :[]
}

const superList = (state = initialState , action) => {
  switch (action.type) {
    case GET_TODO_ITEMS : {
      const newState = Object.assign({},state,{
        super_list_items : action.payload.super_list_items,
        page : action.payload.page,
        show_more_content : action.payload.show_more_content
      })
      return newState;
    }

    case GET_UPCOMING_ACTIVITIES : {
      const newState = Object.assign({},state,{
        upcoming_activities : action.payload
      })
      return newState;
    }

    case ADD_NEW_TODO : {
      const newState = Object.assign({},state,{
        super_list_items : [action.payload, ...state.super_list_items],
      })
      return newState;
    }

    case HIDE_COMPLETED_ITEMS : {
      const newState = Object.assign({},state,{
        super_list_with_hidden_items : action.payload.super_list_with_hidden_items,
        super_list_items : action.payload.new_super_list_items
      })
      return newState;
    }

    case SHOW_COMPLETED_ITEMS : {
      const newState = Object.assign({},state,{
        super_list_items : action.payload.super_list_items
      })
      return newState;
    }

    case COMPLETED_ITEMS_ID : {
      const newState = Object.assign({},state,{
        completed_items_id : action.payload.completed_items_id
      })
      return newState;
    }

    case FLUSH_COMPLETED_ITEMS : {
      const newState = Object.assign({},state,{
        completed_items_id : action.payload.completed_items_id
      })
      return newState;
    }

    default:
      return state;
  }
};
  
export default superList;
  