import {
  GET_TALKING_POINTS,
  ADD_TALKING_POINT,
  UPDATE_TALKING_POINT,
  DELETE_TALKING_POINT,
} from "../../actions/constants";

const initialState = {
  talking_point_items: null,
};

const talkingPoints = (state = initialState, action) => {
  switch (action.type) {
    case GET_TALKING_POINTS: {
      const newState = Object.assign({}, state, {
        talking_point_items: action.payload,
      });
      return newState;
    }

    case ADD_TALKING_POINT: {
      const newState = Object.assign({}, state, {
        talking_point_items: [...state.talking_point_items, action.payload],
      });
      return newState;
    }

    case UPDATE_TALKING_POINT: {
      const newState = Object.assign({}, state, {
        talking_point_items: action.payload,
      });
      return newState;
    }

    case DELETE_TALKING_POINT: {
      const newState = Object.assign({}, state, {
        talking_point_items: action.payload,
      });
      return newState;
    }

    default:
      return state;
  }
};

export default talkingPoints;
