import { PARTICIPANTS_REQUEST } from '../../actions/constants'; 

const initialState = {
  total_no_of_employees : null ,
  no_of_employees_participated : null 
}

const participation = (state = initialState , action) => {
  switch (action.type) {
    case PARTICIPANTS_REQUEST : {
      const newState = Object.assign({},state,{
        total_no_of_employees : action.payload.employee_participation.total_no_of_employees,
        no_of_employees_participated : action.payload.employee_participation.no_of_employees_participated
      })
      return newState;
    }
    
    default:
      return state;
  }
};
  
export default participation;
  