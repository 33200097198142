import { GET_OTHER_PARTY_TALKING_POINTS } from '../../actions/constants'; 

const initialState = {
  other_party_talking_items : null
}

const otherPartytalkingPoints = (state = initialState , action) => {
  switch (action.type) {
    case GET_OTHER_PARTY_TALKING_POINTS : {
      const newState = Object.assign({},state,{
        other_party_talking_items : action.payload
      })
      return newState;
    }

    default:
      return state;
  }
};
  
export default otherPartytalkingPoints;
  