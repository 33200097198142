import { SET_PAGE_NUMBER, SET_PAGE_NAME, SET_PAGE_QUERY } from "./action";

const INIT_STATE = {
  pageNumber: null,
  pageName: null,
  query: null,
};

const reviewListReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: payload,
      };
    }

    case SET_PAGE_NAME: {
      return {
        ...state,
        pageName: payload,
      };
    }

    case SET_PAGE_QUERY: {
      return {
        ...state,
        query: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reviewListReducer;
