import { GET_COLLECT_PERFORMANCE_RATING , UPDATE_COLLECT_PERFORMANCE_RATING , COLLECT_PERFORMANCE_DISPLAY_STATE} from '../../../actions/constants'; 

const initialState = {
  collect_performance_rating : null,
  show_collect_performance_popup : false
}

const collectPerformanceRatingDetails = (state = initialState , action) => {
  switch (action.type) {
    case GET_COLLECT_PERFORMANCE_RATING : {
      const newState = Object.assign({},state,{
        collect_performance_rating : action.payload.collect_performance_rating,
        show_collect_performance_popup: action.payload.show_collect_performance_popup
      })
      return newState;
    }

    case UPDATE_COLLECT_PERFORMANCE_RATING : {
      const newState = Object.assign({},state,{
        collect_performance_rating : action.payload.collect_performance_rating,
        show_collect_performance_popup: action.payload.show_collect_performance_popup
      })
      return newState;
    }

    case COLLECT_PERFORMANCE_DISPLAY_STATE: {
      const newState = Object.assign({},state, { 
        ...action.payload 
      })
      return newState;
    }

    default:
      return state;
    }
};
  
export default collectPerformanceRatingDetails;
  