import { SURVEY_SCHEDULE } from '../../actions/constants';


const initialState = {
  scheduler : null ,
  schedule_status : null ,
  list_by : "upcoming"
}  

const surveyScheduler = (state = initialState , action) => {
  switch (action.type) {
    case SURVEY_SCHEDULE: {
      const newState = Object.assign({},state,{
        scheduler : action.payload.scheduler,
        schedule_status : action.payload.status,
        list_by : action.payload.list_by
      })
      return newState;
    }
    default:
      return state;
  }
};

export default surveyScheduler;
