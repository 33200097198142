import { DEPARTMENT_SCORE_REQUEST } from '../../actions/constants'; 

const initialState = {
  keyMetrics : null 
}  

const departmentKeyMetrics = (state = initialState , action) => {
  switch (action.type) {
    case DEPARTMENT_SCORE_REQUEST: {
      const newState = Object.assign({},state,{
        keyMetrics : action.payload
      })
      return newState;
    }
    default:
      return state;
  }
};
  
export default departmentKeyMetrics;
  