import { MANAGER_PERFORMANCE_REQUEST } from '../../actions/constants'; 

const initialState = {
  managers_performance : null
}

const managerPerformance = (state = initialState , action) => {
  switch (action.type) {
    case MANAGER_PERFORMANCE_REQUEST: {
      const newState = Object.assign({},state,{
        managers_performance : action.payload.managers_performance,
      })
      return newState;
    }
    default:
      return state;
  }
};
  
export default managerPerformance;
  