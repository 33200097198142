import { GET_DEPARTMENT_MESSAGE_LIST_REQUEST } from '../../actions/constants';

const initialState = {
  totalFeaturedResponseCount : null , 
  totalResponseDueCount : null ,
  totalResponseAwaitingCount : null ,
  totalPagesCount : null ,
  content : null ,
  list_by : "featured",
  sort_by : 'default',
  departments : '',
  drivers : '',
  locations : '' ,
  managers : '',
  currentPage : 1,
}

const departmentMessageList = (state = initialState , action) => {
  switch (action.type) {
    case GET_DEPARTMENT_MESSAGE_LIST_REQUEST : {
      let newState ;
      newState = Object.assign({},state,{
        totalFeaturedResponseCount : action.payload.messageList.totalFeaturedResponseCount,
        totalResponseDueCount : action.payload.messageList.totalResponseDueCount,
        totalResponseAwaitingCount : action.payload.messageList.totalResponseAwaitingCount,
        totalPagesCount : action.payload.messageList.totalPagesCount,
        content : action.payload.messageList.content,
        list_by : action.payload.list_by,
        sort_by :  action.payload.sort_by,
        departments : action.payload.departments,
        drivers : action.payload.drivers,
        locations : action.payload.locations,
        managers : action.payload.managers,
        currentPage : action.payload.currentPage,
      })

      return newState;
    }
    
    default:
      return state;
  }
};

export default departmentMessageList;
