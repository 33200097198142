import { GOALTYPE_SEARCH_VALUE } from "actions/constants";

const initialState = {
  department: null,
  employee: null,
};

const goalTypeSearchValue = (state = initialState, action) => {
  switch (action.type) {
    case GOALTYPE_SEARCH_VALUE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default goalTypeSearchValue;
