import {
  GET_OOO_TODAY_LIST,
  GET_OOO_TOMORROW_LIST,
  GET_OOO_THIS_WEEK_UPCOMING_LIST,
  GET_OOO_NEXT_WEEK_UPCOMING_LIST,
  GET_OOO_PAST_LIST,
  GET_OOO_UPCOMING_LIST,
} from "../../../actions/constants";

const initialState = {
  ooo_upcoming_list: null,
  ooo_today_list: null,
  ooo_tomorrow_list: null,
  ooo_this_week_upcoming_list: null,
  ooo_next_week_upcoming_list: null,
  ooo_past_list: null,
};

const oneOnOneList = (state = initialState, action) => {
  switch (action.type) {
    case GET_OOO_PAST_LIST: {
      const newState = Object.assign({}, state, {
        loading: action.payload.loading,
        ooo_past_list: action.payload.ooo_past_list,
      });
      return newState;
    }

    case GET_OOO_UPCOMING_LIST: {
      const newState = Object.assign({}, state, {
        ooo_today_list: action.payload.ooo_today_list,
        ooo_tomorrow_list: action.payload.ooo_tomorrow_list,
        ooo_this_week_upcoming_list: action.payload.ooo_this_week_upcoming_list,
        ooo_next_week_upcoming_list: action.payload.ooo_next_week_upcoming_list,
      });
      return newState;
    }

    case GET_OOO_TODAY_LIST: {
      const newState = Object.assign({}, state, {
        ooo_today_list: action.payload.ooo_today_list,
      });
      return newState;
    }

    case GET_OOO_TOMORROW_LIST: {
      const newState = Object.assign({}, state, {
        ooo_tomorrow_list: action.payload.ooo_tomorrow_list,
      });
      return newState;
    }

    case GET_OOO_THIS_WEEK_UPCOMING_LIST: {
      const newState = Object.assign({}, state, {
        ooo_this_week_upcoming_list: action.payload.ooo_this_week_upcoming_list,
      });
      return newState;
    }

    case GET_OOO_NEXT_WEEK_UPCOMING_LIST: {
      const newState = Object.assign({}, state, {
        ooo_next_week_upcoming_list: action.payload.ooo_next_week_upcoming_list,
      });
      return newState;
    }

    default:
      return state;
  }
};

export default oneOnOneList;
