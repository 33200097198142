import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { APP_ENV } from "./api-config";

export const initSentry = () => {
  if (APP_ENV === "PRODUCTION") {
    Sentry.init({
      dsn: "https://efce020936964a8dacc645fe2896e3dc@o4504274739593216.ingest.sentry.io/4504509105176576",
      ignoreErrors: [
        /^(.*?(\bResizeObserver\b)[^$]*)$/gm,
        /^(.*?(\Socket connection failed: Token verification failed\b)[^$]*)$/gm,
      ],
      integrations: [
        new BrowserTracing(),
        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      tracesSampleRate: 1.0, // Performance Sample Rate
      replaysSessionSampleRate: 0, // Replay Sample Rate
      replaysOnErrorSampleRate: 1.0, // Replay on Error Sample Rate
    });
  }
};

export default Sentry;
