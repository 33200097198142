import {
  GET_GOAL_ACTIVITIES_LIST,
  ADD_GOALS_ACTIVITY,
} from "actions/constants";

const initialState = {
  activitiesAsObject: {},
  activitiesList: {},
};

const goalActivitiesList = (state = initialState, action) => {
  switch (action.type) {
    case GET_GOAL_ACTIVITIES_LIST: {
      const newState = Object.assign({}, state, {
        activitiesAsObject: {
          ...state.activitiesAsObject,
          ...action.payload.activitiesAsObject,
        },
        activitiesList: {
          ...state.activitiesList,
          ...action.payload.activitiesList,
        },
      });
      return newState;
    }
    case ADD_GOALS_ACTIVITY: {
      const { goalId, activityAsObject, activityId } = action.payload;
      let newActivitiesGoalIds = [];
      if (state.activitiesList[goalId]) {
        // if there is any activity present for the goal id  key then this will be true
        newActivitiesGoalIds = [activityId, ...state.activitiesList[goalId]];
      } else {
        // if no activity present for that goal id then create new array for that goal id as key
        newActivitiesGoalIds = [activityId];
      }
      const newState = Object.assign({}, state, {
        activitiesAsObject: {
          ...state.activitiesAsObject,
          ...activityAsObject,
        },
        activitiesList: {
          ...state.activitiesList,
          [goalId]: newActivitiesGoalIds,
        },
      });
      return newState;
    }
    default:
      return state;
  }
};

export default goalActivitiesList;
